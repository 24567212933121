export const MEMBERS_DATA_CALL = 'MEMBERS_DATA_CALL';
export const MEMBERS_CALL_SUCCESS = 'MEMBERS_CALL_SUCCESS';
export const MEMBERS_CALL_FAIL = 'MEMBERS_CALL_FAIL';

export const GET_MEMBER_ROLES = 'GET_MEMBER_ROLES';
export const GET_MEMBER_ROLES_SUCCESS = 'GET_MEMBER_ROLES_SUCCESS';

export const CHANGE_MEMBER_ROLE_CALL = 'CHANGE_MEMBER_ROLE_CALL';
export const CHANGE_MEMBER_ROLE_SUCCESS = 'CHANGE_MEMBER_ROLE_SUCCESS';
export const UPDATE_MEMBER_DATA = 'UPDATE_MEMBER_DATA';

export const INVITE_MEMBER_CALL = 'INVITE_MEMBER_CALL';

// export const USER_ADD = 'USER_ADD';
export const MEMBER_DELETE_CALL = 'MEMBER_DELETE_CALL';
export const INVITATION_DELETE_CALL = 'INVITATION_DELETE_CALL';
export const MEMBER_EDIT = 'MEMBER_EDIT';

export const MEMBER_LOADING_OFF = 'MEMBER_LOADING_OFF';
export const RESET_AFFILIATION_MEMBERS_STORE = 'RESET_AFFILIATION_MEMBERS_STORE';

export const SET_PARENT_CUSTOMERS = 'SET_PARENT_CUSTOMERS';
export const SET_ACTIVE_PARENT_CUSTOMER_CALL = 'SET_ACTIVE_PARENT_CUSTOMER_CALL';
export const SET_ACTIVE_PARENT_CUSTOMER_ID = 'SET_ACTIVE_PARENT_CUSTOMER_ID';
export const SET_ACTIVE_PARENT_CUSTOMER = 'SET_ACTIVE_PARENT_CUSTOMER';
export const PARENT_CUSTOMERS_CALL_FAIL = 'PARENT_CUSTOMERS_CALL_FAIL';


export function membersDataCall() {
    return { type: MEMBERS_DATA_CALL, payload: {} };
}

export function membersCallSuccess(membersData, invitationsData) {
    return { type: MEMBERS_CALL_SUCCESS, payload: { members: membersData, invitations: invitationsData } };
}

export function inviteMemberCall(invitationData) {
    return { type: INVITE_MEMBER_CALL, payload: { invitationData } };
}

export function changeMemberRoleCall(memberId, roleId) {
    return { type: CHANGE_MEMBER_ROLE_CALL, payload: { memberId, roleId } };
}

export function updateMember(member) {
    return { type: UPDATE_MEMBER_DATA, payload: { member } };
}

export function getMemberRoles() {
    return { type: GET_MEMBER_ROLES, payload: {} };
}

export function addMemberRoles(memberRoles) {
    return { type: GET_MEMBER_ROLES_SUCCESS, payload: { memberRoles } };
}

export function memberEdit(id, memberNewValues) {
    return { type: MEMBER_EDIT, payload: { id, memberNewValues } };
}

export function removeMemberLoading() {
    return { type: MEMBER_LOADING_OFF };
}

export function resetAffiliationMemberStore(isReset) {
    return { type: RESET_AFFILIATION_MEMBERS_STORE, payload: { isReset } };
}

export function deleteMemberCall(memberId) {
    return { type: MEMBER_DELETE_CALL, payload: { memberId } };
}

export function deleteInvitationCall(email) {
    return { type: INVITATION_DELETE_CALL, payload: { email } };
}

export function setParentCustomers(parentCustomers) {
    return { type: SET_PARENT_CUSTOMERS, payload: { parentCustomers } };
}

export function setActiveParentCustomerCall(customerId) {
    return { type: SET_ACTIVE_PARENT_CUSTOMER_CALL, payload: { customerId } };
}

export function setActiveParentCustomer(activeParentCustomer) {
    return { type: SET_ACTIVE_PARENT_CUSTOMER, payload: { activeParentCustomer } };
}

export function setActiveParentCustomerId(customerId) {
    return { type: SET_ACTIVE_PARENT_CUSTOMER_ID, payload: { customerId } };
}