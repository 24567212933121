import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import validate from 'validate.js';
import DropDown from '../dropdown';
import { colors, device } from '../../UI/constants';
import {
    Col,
    SvgInfoContainer,
    StyledInfoSvg,
    ErrorMessage,
    Input,
    Select,
    Label,
    SvgTooltip,
    Row,
    DropdownOptionsUl, CustomSelect, CustomSelectTrigger, DropdownOptionLi, Tooltip, TooltipBody
} from '../../UI';


function Field({ label, labelNote, input, validation, onError, onChange, children, withoutMargin, textTransform }) {
    validate.validators.presence.options = {
        message: `${label ? `^${label}` : ''} is required`,
        allowEmpty: false
    };

    // notValid
    // wrongLength
    validate.validators.length.tooShort = 'can not be shorter than %{count} characters';
    validate.validators.length.tooLong = 'can not be longer than %{count} characters';
    validate.validators.numericality.notValid = 'is required';
    validate.validators.numericality.notGreaterThanOrEqualTo = 'must be greater than or equal to %{count}';
    validate.validators.numericality.lessThanOrEqualTo = 'Weight must be less than or equal to %{count}';

    const inputName = input.name.includes('.') ? input.name.split('.').pop() : input.name;

    const [initialRender, setInitialRender] = useState(true);
    const rules = { [inputName]: validation };
    const errorMessages = validate({ [inputName]: input.value }, rules); // TODO -> check it validation by type
    const isInvalid = errorMessages && errorMessages[inputName].length > 0;

    useEffect(() => {
        if (onError) {
            if (isInvalid) {
                onError({
                    inputName,
                    message: errorMessages[inputName][0]
                });
            } else {
                onError(inputName);
            }
        }
    }, [isInvalid]);

    const LabelNoteBlock = useMemo(() => (
        labelNote && (
            <SvgInfoContainer>
                <StyledInfoSvg/>
                <Tooltip>
                    <TooltipBody>
                        {/* TODO -> fix it */}
                        {/* {typeof labelNote === 'string' ? (*/}
                        {/*    <SvgTooltip>{labelNote}</SvgTooltip>*/}
                        {/* ) : (*/}
                        <SvgTooltip dangerouslySetInnerHTML={{ __html: labelNote }}/>
                        {/* )}*/}
                    </TooltipBody>
                </Tooltip>
            </SvgInfoContainer>
        )
    ), []);

    const ErrorMessageBlock = useMemo(() => (
        <ErrorMessage>{!initialRender && isInvalid && errorMessages[inputName][0]}</ErrorMessage>
    ), [initialRender, isInvalid, errorMessages]);

    function inputReturnTypes(val, type = 'text') {

        switch (type) {
            case 'number': {
                return parseFloat(val);
            }
            case 'checkbox':
            case 'toggle': {
                return Boolean(val);
            }
            default: {
                return val.toString();
            }
        }

    }

    function onChangeFunction(e) {
        initialRender && setInitialRender(false);

        let newValue;
        switch (input.type) {
            case 'checkbox':
            case 'toggle': {
                newValue = e.target.checked;
                break;
            }
            // TODO -> fix it
            // case 'radio': {
            //     newValue = e.target.checked;
            //     break;
            // }
            case 'select-custom': {
                newValue = e;
                break;
            }
            default: {
                // TODO -> test it
                newValue = inputReturnTypes(e.target.value.replace(/^\s+|$/g, ''), input.type);
            }
        }

        onChange && onChange(newValue);
    }

    switch (input.type) {
        case 'checkbox': {
            return (
                <Row marginBottom={withoutMargin ? '' : '5px'} align="center" textTransform={textTransform}>
                    <CheckboxLabel withoutMargin>
                        <Input {...input}
                               onChange={onChangeFunction}
                               isError={isInvalid}
                               checked={input.value}
                        />
                        <span className="field">{label}</span>
                        <span className="check-mark"/>
                    </CheckboxLabel>
                    {LabelNoteBlock}
                    {ErrorMessageBlock}
                </Row>
            );
        }
        case 'toggle': {
            return (
                <Row marginBottom={withoutMargin ? '' : '5px'} align="center" textTransform={textTransform}>
                    <ToggleWrapper withoutMargin>
                        <Input {...input}
                               type="checkbox"
                               onChange={onChangeFunction}
                               isError={isInvalid}
                               checked={input.value}
                        />
                        <span className="toggle-mark"/>
                        <span className="field">{label}</span>
                    </ToggleWrapper>
                    {LabelNoteBlock}
                    {ErrorMessageBlock}
                </Row>
            );
        }
        case 'radio': {
            return (
                <Row marginBottom="5px" align="center" textTransform={textTransform}>
                    <RadioWrapper>
                        <label>
                            <Input {...input}
                                   type="radio"
                                   onChange={onChangeFunction}
                                   isError={isInvalid}
                                   checked={input.value}
                            />
                            <span className="fake-box"/>
                            <span className="radio-text">{label}</span>
                        </label>
                    </RadioWrapper>
                    {LabelNoteBlock}
                    {ErrorMessageBlock}
                </Row>
            );
        }
        case 'select': {
            return (
                <Col marginBottom="5px" textTransform={textTransform}>
                    <NameWrapper>
                        <Label>{label}</Label>
                        {LabelNoteBlock}
                    </NameWrapper>
                    <Col>
                        <Select onChange={onChangeFunction}
                                isError={!initialRender && isInvalid}
                                minWidth="320px"
                                className={input.value === '' ? 'default-selection' : ''}
                                {...input}
                        >
                            <option value="" disabled>{input.placeholder || 'Select'}</option>
                            {children}
                        </Select>
                        {ErrorMessageBlock}
                    </Col>
                </Col>
            );
        }
        case 'select-custom': {
            return (
                <Col marginBottom="5px" textTransform={textTransform}>
                    <NameWrapper>
                        <Label>{label}</Label>
                        {LabelNoteBlock}
                    </NameWrapper>

                    <CustomSelect>
                        <DropDown withIcon noOverflowBody trigger={(
                            <CustomSelectTrigger
                                textTransform={textTransform}
                                isError={!initialRender && isInvalid}
                                minWidth="320px"
                                className={input.value === '' ? 'default-selection' : ''}
                            >
                                {input.value || input.placeholder || 'Select'}
                            </CustomSelectTrigger>
                        )}
                        >
                            <DropdownOptionsUl isCoutryList={label === 'Country'}>
                                {input.options && input.options.map((option, index) => (
                                    <DropdownOptionLi
                                        key={`${index}-${option}`}
                                        onClick={() => onChangeFunction(option)}
                                    >
                                        {option}
                                    </DropdownOptionLi>
                                ))}
                            </DropdownOptionsUl>
                        </DropDown>
                    </CustomSelect>
                    {ErrorMessageBlock}
                </Col>
            );
        }
        default: {
            return (
                <Col marginBottom="5px" textTransform={textTransform}>
                    <NameWrapper>
                        <Label>{label}</Label>
                        {LabelNoteBlock}
                    </NameWrapper>
                    <Col>
                        {input.type === 'textarea' ? (
                            <Textarea
                                onChange={onChangeFunction}
                                isError={!initialRender && isInvalid}
                                minWidth="320px"
                                autoComplete="off"
                                {...input}
                            />
                        ) : (
                            <Input
                                onChange={onChangeFunction}
                                isError={!initialRender && isInvalid}
                                minWidth="320px"
                                autoComplete="off"
                                {...input}
                            />
                        )}
                        {ErrorMessageBlock}
                    </Col>
                </Col>
            );
        }
    }
}

Field.propTypes = {
    label: PropTypes.string,
    labelNote: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        placeholder: PropTypes.string
    }).isRequired,
    children: PropTypes.arrayOf(PropTypes.node),
    validation: PropTypes.shape({}),
    onChange: PropTypes.func,
    onError: PropTypes.func,
    withoutMargin: PropTypes.bool,
    textTransform: PropTypes.string
};

Field.defaultProps = {
    label: '',
    labelNote: '',
    textTransform: '',
    validation: {},
    children: [],
    onError: () => {},
    onChange: () => {},
    withoutMargin: false
};

export default Field;

const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

const CheckboxLabel = styled.label`
 
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-left: 30px;
    margin-bottom: ${props => (props.withoutMargin ? '0' : '15px')};
    font-size: 0.875rem;
    //text-transform: capitalize;
    
    input {
      position: absolute;
      cursor: pointer;
      visibility: hidden;
      opacity: 0;
      height: 0;
      width: 0;
    }
    
    .field-name {
        font-size: 0.812rem;
        line-height: 0.937rem;
        font-weight: 500;
        margin: 0;
    }
    
    .check-mark {
      position: absolute;
      top: 50%;
      left: 0;
      height: 12px;
      width: 12px;
      background-color: white; 
      border: 2px solid ${colors.gray3}; 
      border-radius: 3px;
      transform: translateY(-50%);
      
      &::after {
        display: none;
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: translate(-50%, -60%) rotate(45deg);
        border-radius: 2px;
      }
    }
    
    &:hover input ~ .check-mark {
      background-color: #ccc;
    }
    
    & input:checked ~ .check-mark {
      border: 2px solid ${colors.blue1}; 
      background-color: ${colors.blue1};
    }
    
    & input:checked ~ .check-mark::after {
      display: block;
    }

`;

const ToggleWrapper = styled.label`

  display: flex;
  font-size: 0.875rem;

   input {
      position: absolute;
      cursor: pointer;
      visibility: hidden;
      opacity: 0;
      height: 0;
      width: 0;
    }

    .toggle-mark {
        position: relative;
        cursor: pointer;
        width: 35px;
        height: 20px;
        background: ${colors.gray3};
        display: block;
        border-radius: 20px;
        margin-right: 12px;
    }
    
    .toggle-mark::after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 14px;
        height: 14px;
        background: white;
        border-radius: 100%;
        transition: 0.2s;
    }
    
    input:checked + .toggle-mark {
        background: ${colors.blue1};
    }
    
    input:checked + .toggle-mark::after {
        left: calc(100% - 3px);
        transform: translateX(-100%);
    }
    
    .toggle-mark:active::after {
        width: 18px;
    }

`;

const Textarea = styled.textarea`
    resize: none;
    height: 77px;
    overflow: hidden auto;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 14px;
    //margin-top: 4px;
    
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: ${colors.blue7};
    border: ${props => (props.isError ? `1px solid ${colors.red1}` : `1px solid ${colors.gray2}`)};
    
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        border-left: 1px solid ${colors.gray2};
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        background: ${colors.gray2};
        border-radius: 10px;
    }
    
    @media ${device.tablet} { 
        width: 100%; 
        min-width: calc(100% - 30px);
    }
`;

const RadioWrapper = styled.div`
      position: relative;
      width: 100%;
      //margin-bottom: 15px;
    
    & .radio-text {
        line-height: 1.25rem;
    }
      
    & label {
      position: relative;
      cursor: pointer;
      display: inline-block;
      padding-left: 30px;
      box-sizing: border-box;
    }
    & input[type="radio"] {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    & input[type="radio"] ~ .fake-box {
      position: absolute;
      background: #fff;
      border: 2px solid ${colors.gray3};
      border-radius: 50%;
      left: 0;
      top: 1px;
      height: 14px;
      width: 14px;
    }
    & input[type="radio"] ~ .fake-box:after {
      background: ${colors.blue1};
      border-radius: 50%;
      content: "";
      left: 50%;
      margin-left: -5px;
      margin-top: -5px;
      position: absolute;
      top: 50%;
      visibility: hidden;
      height: 10px;
      width: 10px;
    }
     & input[type="radio"]:checked ~ .fake-box {
       border: 2px solid ${colors.blue1};
    }
    & input[type="radio"]:checked ~ .fake-box:after {
      visibility: visible;
    }
    & input[type="radio"]:focus ~ .fake-box {
      box-shadow: 0 0 8px ${colors.gray3};
    }
    & input[type="radio"]:disabled ~ .radio-text,
    & input[type="radio"]:disabled ~ .fake-box {
      opacity: 0.5;
      cursor: default;
    }

`;