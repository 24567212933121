import { combineReducers } from 'redux';
import customerData from '../customer/reducers';
import appsData from '../apps/reducers';
import rolesData from '../roles/reducers';
import permissionsData from '../permissions/reducers';
import regionsData from '../regions/reducers';
import usersData from '../users/reducers';
import membersData from '../members/reducers';
import settingsData from '../api-keys/reducer';
import errorData from '../error/reducers';
import channelsData from '../channels/reducers';
import paginationData from '../pagination/reducers';
import channelMembersData from '../channel-members/reducers';
import webhooksData from '../webhooks/reducers';
import webhookEventsData from '../webhook-events/reducers';
import notificationsData from '../notifications/reducers';
import usageData from '../usage/reducers';
import expiredData from '../expired/reducers';
import plansData from '../plans/reducers';
import moderationData from '../moderation/reducers';
import markersData from '../markers/reducers';

// function lastAction(state = null, action) {
//     return action;
// }

export default combineReducers({
    // lastAction,
    customerData,
    errorData,
    appsData,
    rolesData,
    usersData,
    permissionsData,
    regionsData,
    settingsData,
    channelsData,
    paginationData,
    channelMembersData,
    webhooksData,
    webhookEventsData,
    notificationsData,
    usageData,
    expiredData,
    plansData,
    membersData,
    moderationData,
    markersData
});