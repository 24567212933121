const rolesInputsStructure = {
    name: {
        label: 'Name',
        type: 'text',
        validation: {
            presence: true,
            format: {
                pattern: /^[a-z0-9]*$/,
                message: '^Allowed characters are a-z, 0-9'
            },
            length: {
                maximum: 128
            }
        }
    },
    description: {
        label: 'Description',
        type: 'text',
        validation: {
            length: {
                maximum: 250
            }
        }
    },
    weight: {
        label: 'Weight',
        type: 'number',
        validation: {
            presence: true,
            numericality: {
                // strict: true,
                onlyInteger: true,
                greaterThanOrEqualTo: 1,
                lessThanOrEqualTo: 99
            }
            // format: {
            //     pattern: /^([1-9][0-9]{0,1})$/,
            //     message: '^Weight value must be 1-99.'
            // }
        },
        min: 1,
        max: 99
    }
    // access: {
    //     label: 'Available for users',
    //     type: 'checkbox'
    // }
};

export default rolesInputsStructure;