import React, { useCallback, useEffect, useRef, useState } from 'react';
import SceytChatClient from 'sceyt-chat';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import { Chat, MessageList, MessagesScrollToBottomButton, SceytChat, SendMessage } from 'sceyt-chat-react-uikit';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDidUpdate } from '../../hooks';
import { ReactComponent as TileChatTriggerIcon } from '../../assets/svg/liveChatTrigger.svg';
import { ReactComponent as CloseChatIcon } from '../../assets/svg/closeChat.svg';
import { ReactComponent as WhiteLogoIcon } from '../../assets/svg/whiteLogo.svg';
import { ReactComponent as LoadingIcon } from '../../assets/svg/loading_white.svg';
import { ReactComponent as MenuIcon } from '../../assets/svg/more_vertical.svg';
import { ReactComponent as EmailIcon } from '../../assets/svg/mail_outline.svg';
import { ReactComponent as UnmuteIcon } from '../../assets/svg/volume-up.svg';
import { ReactComponent as MuteIcon } from '../../assets/svg/volume-off.svg';
import { ReactComponent as SuccessIcon } from '../../assets/svg/check_circle_on.svg';
import { ReactComponent as CancelIcon } from '../../assets/svg/cancel_close.svg';
import { getCookie, setCookie } from '../../helpers/cookie';
import { colors } from '../UI/constants';
import { SENDING_STATE } from '../../helpers/constants';
import Field from '../common/field';
import { errorHandler, updateInput } from '../../helpers/utils';
import livechatInputsStructure from '../../helpers/fields/livechat';

const messagesCustomColor = {
    ownMessageBackground: '#E3E7FF', incomingMessageBackground: '#F1F2F6'
};

function CustomTyping({ from }) {
    return (
        <TypingContainer>
            <TypingAvatar>
                <img
                    src={from.avatarUrl || '../assets/support.png'}
                    width={32}
                    height={32}
                    alt="chat icon"
                />
            </TypingAvatar>
            {/* {from.firstName || from.id}*/}
            <TypingAnimation>
                <span className="dot_one"/>
                <span className="dot_two"/>
                <span className="dot_three"/>
            </TypingAnimation>
        </TypingContainer>
    );
}

const TypingContainer = styled.div` {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
}`;

const TypingAvatar = styled.div` {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;

  & > img {

    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}`;

const TypingAnimation = styled.div` {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F1F2F6;
  width: 58px;
  height: 32px;
  border-radius: 16px;
  margin-left: 4px;

  & > span {
    position: relative;
    width: 6px;
    height: 6px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > span:last-child {
    margin-right: 0;
  }

  & > span::after {
    content: '';
    position: absolute;

    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #707388;
    animation-name: shapeAnimation;
    animation-duration: .7s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform: translate(0, 1px);
  }

  & > span.dot_one:after {
    animation-delay: 0s;
  }

  & > span.dot_two:after {
    animation-delay: 0.2s;
  }

  & > span.dot_three:after {
    animation-delay: 0.4s;
  }


  @keyframes shapeAnimation {
    0% {
      transform: translate(0, 1px);
    }
    50% {
      transform: translate(0, -1px);
    }
    100% {
      transform: translate(0, 1px);
    }
  }
}`;


CustomTyping.propTypes = {
    from: PropTypes.objectOf(PropTypes.any.isRequired)
};

CustomTyping.defaultProps = {
    from: null
};

let audioContext;
let audioBuffer;
const startAudioContext = () => {
    try {
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
        fetch('/assets/audio/message.mp3')
            .then(response => response.arrayBuffer())
            .then(data => audioContext.decodeAudioData(data))
            .then(buffer => {
                audioBuffer = buffer;
            });
    } catch (e) {
        console.log('error on start audio context ... ', e);
    }
};

const playSound = buffer => {
    const source = audioContext.createBufferSource();
    source.buffer = buffer;
    source.connect(audioContext.destination);
    source.start();
};

function LiveChat() {
    const [client, setClient] = useState(null);
    const [clientState, setClientState] = useState('');
    const [unreadCount, setUnreadCount] = useState();
    const [chatToken, setChatToken] = useState(null);
    const [isMobile, setIsMobile] = useState(null);
    const [liveChatOpen, setLiveChatOpen] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [sendTranscriptPopupOpen, setSendTranscriptPopupOpen] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [sendTranscriptionState, setSendTranscriptionState] = useState(SENDING_STATE.NONE);
    const [typedEmail, setTypedEmail] = useState('');
    const [emailIsValid, setEmailIsValid] = useState([]);
    const [activeChannel, setActiveChannel] = useState();
    const [sendTranscriptData, setSendTranscriptData] = useState(null);

    const menuRef = useRef();
    const connectInterval = useRef();
    const connectIntervalTimeout = useRef();

    const handleUpdateUnreadCount = channel => {
        setActiveChannel(channel);
        if (!isMuted && channel.newMessageCount > unreadCount) {
            playSound(audioBuffer);
        }
        setUnreadCount(channel.newMessageCount);
    };

    const handleOpenLiveChat = state => {
        if (!audioContext) {
            startAudioContext();
        }
        setLiveChatOpen(state);

        /*     if (state) {
                 dataLayer.push({ 'event': 'click_button_livechat_open' });
             } else {
                 dataLayer.push({ 'event': 'click_button_livechat_close' });
             }*/
    };
    const getToken = () => {
        let userId;
        const clientId = getCookie('liveChatUserId');
        if (clientId) {
            userId = clientId;
        } else {
            userId = localStorage.getItem('user_id');
            if (!userId) {
                userId = `guest_${uuidv4()}`;
                setCookie('liveChatUserId', userId);
            }
        }

        fetch(`https://h6erghqk02.execute-api.us-east-2.amazonaws.com/api/token?user=${userId}`)
        .then(async response => response.json()).then(data => {
            setChatToken(data.chat_token);
        }).catch(e => {
            console.log('error on gen token. .. ', e);
        });
    };

    const connectClient = token => {
        const sceytClient = new SceytChatClient('https://us-ohio-api.sceyt.com', 'yvln2q8679', uuidv4());
        // const sceytClient = new SceytChatClient('ws://192.168.178.213:8085/bmp', '3jrjkydywp', uuidv4());

        sceytClient.setLogLevel('trace');

        const listener = new sceytClient.ConnectionListener();
        listener.onConnectionStateChanged = async status => {
            setClientState(status);
            if (status === 'Failed') {
                await getToken();
            } else if (status === 'Connected') {
                try {
                    sceytClient.setPresence('online');
                } catch (e) {
                    console.log('error on set presence ... ', e);
                }
            }
        };
        listener.onTokenWillExpire = async () => {
            getToken();
        };
        listener.onTokenExpired = async () => {
            if (clientState === 'Connected') {
                getToken();
            } else {
                await getToken();
            }
        };
        sceytClient.addConnectionListener('listener_id', listener);
        sceytClient.connect(token)
            .then(() => {
                setClient(sceytClient);
                if (
                    typeof window !== 'undefined'
                    && window != null
                    && window.addEventListener != null
                ) {
                    window.addEventListener('offline', e => onlineStatusChanged(e, sceytClient));
                    window.addEventListener('online', e => onlineStatusChanged(e, sceytClient));
                }
            })
            .catch(e => {
                const date = new Date();
                console.error(`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Error on connect ... `, e);
                getToken();
            });
    };

    const onlineStatusChanged = (event, client) => {
        const date = new Date();
        console.info(`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Online status changed : `, event.type);
        if (event.type === 'online') {
            setTimeout(() => {
                if (client && (!client.accessToken || client.connectionState === 'Disconnected')) {
                    getToken();
                }
            }, 1000);
        }
    };
    const updateFavicon = newIconUrl => {
        const link = document.querySelector("link[rel*='icon']")
            || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = `${newIconUrl}`; // Cache busting

        // Remove any existing favicons
        Array.from(document.querySelectorAll("link[rel*='icon']")).forEach(el => el.parentNode.removeChild(el));

        // Append the new favicon
        document.getElementsByTagName('head')[0].appendChild(link);
    };

    const handleOpenCloseMenu = () => {
        setMenuIsOpen(!menuIsOpen);
    };

    const handleCloseMenu = e => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setMenuIsOpen(false);
        }
    };

    const handleOpenSendTranscript = () => {
        if (!sendTranscriptPopupOpen) {
            setMenuIsOpen(false);
        }
        setSendTranscriptPopupOpen(!sendTranscriptPopupOpen);
    };

    const handleMuteUnmute = () => {
        const muted = localStorage.getItem('liveChatSoundMuted');
        if (muted && muted === 'true') {
            localStorage.setItem('liveChatSoundMuted', 'false');
            setIsMuted(false);
        } else {
            localStorage.setItem('liveChatSoundMuted', 'true');
            setIsMuted(true);
        }
    };

    const handleCloseSendTranscript = () => {
        setSendTranscriptPopupOpen(false);
    };
    const handleSendTranscript = () => {
        if (!(emailIsValid && emailIsValid.length > 0)) {
            setSendTranscriptData({
                email: typedEmail,
                channel: activeChannel
            });
        }
    };
    const FiledBlock = useCallback(inputName => {
        const input = livechatInputsStructure[inputName];
        if (input) {
            return (
                <Field
                    label={input.label}
                    labelNote={input.labelNote}
                    validation={input.validation}
                    onChange={updateInput(setTypedEmail)}
                    onError={errorHandler(setEmailIsValid)}
                    input={{
                        name: inputName,
                        value: typedEmail,
                        type: input.type,
                        placeholder: input.placeholder,
                        ...input.spellCheck !== undefined && { spellCheck: input.spellCheck }
                    }}
                />
            );
        }
        return null;
    }, [typedEmail]);

    useDidUpdate(() => {
        if (sendTranscriptData && sendTranscriptionState !== SENDING_STATE.SENDING) {
            setSendTranscriptionState(SENDING_STATE.SENDING);
            fetch(`https://h6erghqk02.execute-api.us-east-2.amazonaws.com/api/transcript?channel=${sendTranscriptData.channel.id}&email=${sendTranscriptData.email}`)
                .then(res => {
                    if (res.status === 200) {
                        setSendTranscriptionState(SENDING_STATE.SENT);
                        setSendTranscriptPopupOpen(false);
                        setSendTranscriptData(null);
                        setTypedEmail('');
                    }
                })
                .catch(e => {
                    console.log('error on send transcript ... ', e);
                });
        }
    }, [sendTranscriptData]);

    useDidUpdate(() => {
        if (sendTranscriptionState === SENDING_STATE.SENT) {
            setTimeout(() => {
                setSendTranscriptionState(SENDING_STATE.NONE);
            }, 3000);
        }
    }, [sendTranscriptionState]);

    useEffect(() => {
        try {
            const canvas = document.createElement('canvas');
            canvas.width = 64; // assuming favicon size
            canvas.height = 64;

            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.src = '/favicon/apple-touch-icon.png'; // your existing favicon
            img.onload = () => {
                // Draw existing favicon as background
                ctx.drawImage(img, 0, 0, 64, 64);
                if (unreadCount > 0) {
                    // Draw the badge
                    ctx.fillStyle = 'red'; // badge color
                    ctx.beginPath();
                    ctx.arc(46, 18, 18, 0, 2 * Math.PI); // position of the badge
                    ctx.fill();

                    // Add count text
                    ctx.fillStyle = 'white';
                    ctx.font = '30px Arial';
                    ctx.textAlign = 'center';
                    ctx.fillText(unreadCount, 46, 30); // position of the text
                }

                // Get data URL
                const updatedFavicon = canvas.toDataURL('image/png');
                updateFavicon(updatedFavicon);
            };
        } catch (e) {
            console.log('error setting favicon', e);
        }
    }, [unreadCount]);

    useEffect(() => {
        const muted = localStorage.getItem('liveChatSoundMuted');
        if (muted && muted === 'true') {
            setIsMuted(true);
        }
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        if (liveChatOpen && !chatToken) {
            getToken();
        }
        if (liveChatOpen && isMobile) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
        setIsMobile(isMobile);
    }, [liveChatOpen]);

    useDidUpdate(() => {
        const date = new Date();
        console.info(`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Client state changed: `, clientState);
        if (clientState === 'Disconnected') {
            console.log('Client is disconnected: call refresh token in interval');
            clearInterval(connectInterval.current);
            clearTimeout(connectIntervalTimeout.current);
            connectIntervalTimeout.current = setTimeout(() => {
                connectInterval.current = setInterval(() => {
                    const date = new Date();
                    console.info(`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Client still not connected: call refresh token in interval `);
                    getToken();
                }, 7000);
            }, 7000);
        } else if (clientState === 'Connected') {
            const date = new Date();
            console.info(`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Client is connected: stop reconnect interval `);
            clearInterval(connectInterval.current);
            clearTimeout(connectIntervalTimeout.current);
        }
    }, [clientState]);

    useDidUpdate(() => {
        if (clientState === 'Connected') {
            client.updateToken(chatToken);
        } else if (client) {
            client.connect(chatToken)
                .then(() => {
                    setClientState('Connected');
                })
                .catch(e => {
                    const date = new Date();
                    console.error(`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Error on connect after updating the token ... `, e);
                    if (e.code === 10005 && client && client && client.connectionState === 'Connected') {
                        setClientState('Connected');
                    } else {
                        getToken();
                    }
                });
        } else {
            connectClient(chatToken);
        }
    }, [chatToken]);

    return (
        <LiveChatWrapper onClick={handleCloseMenu}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <OpenLivechatButton onClick={() => handleOpenLiveChat(true)}>
                {!!(unreadCount && unreadCount > 0 && !liveChatOpen) && (<UnreadCount>{unreadCount}</UnreadCount>)}
                <TileChatTriggerIcon/>
            </OpenLivechatButton>
            <LiveChatContainer className={`${liveChatOpen && 'live_chat_container_opened'} ${isMobile ? 'mobile_livechat' : ''}`}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                {sendTranscriptionState === SENDING_STATE.SENT && (
                    <SentSuccessContainer>
                        <SuccessIcon/> <span>Email transcript has been sent!</span>
                    </SentSuccessContainer>
                )}
                {sendTranscriptPopupOpen && (
                    <SendTranscriptPopup onClick={handleCloseSendTranscript}>
                        <SendTranscriptContainer onClick={e => e.stopPropagation()}>
                            <CloseSendTranscript onClick={handleCloseSendTranscript}>
                                <CancelIcon/>
                            </CloseSendTranscript>
                            <p>Request a transcript of your conversation</p>
                            {/*    <label htmlFor="transcript_email" className="label">Email</label>
                            <input id="transcript_email" value={typedEmail} className={!emailIsValid ? 'error' : ''}
                                   type="email" placeholder="Enter your email"
                                   onChange={handleTypeEmail}
                            />*/}
                            {FiledBlock('email')}
                            <SendTranscriptButton onClick={handleSendTranscript}>
                                {sendTranscriptionState === SENDING_STATE.SENDING ? (
                                    <LoadinWrapper><LoadingIcon/></LoadinWrapper>
                                ) : 'Send'}
                            </SendTranscriptButton>
                        </SendTranscriptContainer>
                    </SendTranscriptPopup>
                )}
                <LiveChatHeader>
                    <WhiteLogoIcon/>
                    <LiveChatTitle>Sceyt Support</LiveChatTitle>
                    <LiveChanActionsMenu ref={menuRef}>
                        <OpenLivechatMenu className="open_close_menu_button" onClick={handleOpenCloseMenu}>
                            <MenuIcon/>
                        </OpenLivechatMenu>
                        <MenuDropDown className={`dropDown_ul ${menuIsOpen ? 'open' : ''}`}>
                            <MenuItem onClick={handleOpenSendTranscript}>
                                <EmailIcon/>
                                <span>Email transcript </span>
                            </MenuItem>
                            <MenuItem onClick={handleMuteUnmute}>
                                {isMuted ? (
                                    <MuteIcon/>
                                ) : (
                                    <UnmuteIcon/>
                                )}

                                <span>{isMuted ? 'Turn on sound' : 'Turn off sound'}</span>
                            </MenuItem>
                        </MenuDropDown>
                    </LiveChanActionsMenu>
                    <CloseLivechatButton onClick={() => handleOpenLiveChat(false)}>
                        <CloseChatIcon/>
                    </CloseLivechatButton>
                </LiveChatHeader>

                <SceytLivechat>

                    {client ? (
                        <SceytChat showNotifications={false} customColors={{ primaryColor: '#5159F6' }} client={client}>
                            <Chat onActiveChannelUpdated={handleUpdateUnreadCount} hideChannelList>
                                {/*   <ChatHeader showMemberInfo={false} backgroundColor={'#111539'} titleColor={'#fff'}
                                        memberInfoTextColor={'rgba(255, 255, 255, 0.5)'}/>*/}
                                <MessageList
                                    fontFamily="Inter, sans-serif"
                                    messageWidthPercent={70}
                                    messageStatusAndTimePosition="bottomOfMessage"
                                    messageStatusDisplayingType="text"
                                    showMessageStatusForEachMessage={false}
                                    showSenderNameOnGroupChannel={false}
                                    showMessageTimeForEachMessage={false}
                                    ownMessageBackground={messagesCustomColor.ownMessageBackground}
                                    incomingMessageBackground={messagesCustomColor.incomingMessageBackground}
                                    dateDividerTextColor="#707388"
                                    dateDividerBorder="none"
                                    dateDividerFontSize="13px"
                                    dateDividerBackgroundColor="#fff"
                                    dateDividerBorderRadius="14px"
                                    newMessagesSeparatorWidth="calc(100% - 170px)"
                                    newMessagesSeparatorBackground="rgb(162 163 164)"
                                    newMessagesSeparatorTextLeftRightSpacesWidth="12px"
                                    newMessagesSeparatorFontSize="12px"
                                    newMessagesSeparatorTextColor="#fff"
                                    newMessagesSeparatorText="New Messages"
                                    fileAttachmentsBoxBackground="#F3F5F7"
                                    fileAttachmentsBoxBorder="none"
                                    fileAttachmentsTitleColor="#17191C"
                                    fileAttachmentsSizeColor="#757D8B"
                                    fileAttachmentsBoxWidth={220}
                                    imageAttachmentMaxWidth={220}
                                    imageAttachmentMaxHeight={200}
                                    videoAttachmentMaxWidth={220}
                                    videoAttachmentMaxHeight={200}
                                    attachmentsPreview={false}
                                    sameUserMessageSpacing="6px"
                                    differentUserMessageSpacing="12px"
                                    editMessage={false}
                                    deleteMessage={false}
                                    forwardMessage={false}
                                    copyMessage={false}
                                    replyMessage={false}
                                    replyMessageInThread={false}
                                    messageReaction={false}
                                    reportMessage={false}
                                    selectMessage={false}
                                />
                                <MessagesScrollToBottomButton/>
                                <SendMessage
                                    voiceMessage={false}
                                    allowMentionUser={false}
                                    allowTextEdit={false}
                                    CustomTypingIndicator={CustomTyping}
                                    sendAttachmentSeparately
                                />
                            </Chat>
                        </SceytChat>
                    ) : (
                        <LivechatLoading>
                            <img
                                // src={createImageUrl('/home/sceyt_rounded', 'png')}
                                src="/assets/sceyt_rounded.png"
                                width={120}
                                height={120}
                                alt="chat icon"
                            />
                        </LivechatLoading>
                    )}
                </SceytLivechat>
            </LiveChatContainer>

        </LiveChatWrapper>
    );
}


const LiveChatWrapper = styled.div` {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
}`;

const LiveChatHeader = styled.div` {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background-color: #111539;
  border-radius: 23px 23px 0 0;
  box-shadow: 0px -4px 0px 3px #111539;
}`;

const LiveChatTitle = styled.h3` {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 0 16px;
  color: #fff;
}`;

const LiveChatContainer = styled.div` {
  overflow: hidden;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 5px 40px rgba(17, 21, 57, 0.12);
  width: 0;
  height: 0;
  transition: height .2s, width .2s, overflow 0s ease-in-out .3s;
  pointer-events: auto;

  &.live_chat_container_opened {
    overflow: auto;
    width: 375px;
    height: 630px;
  }

  @media screen and (max-width: 480px), screen and (max-height: 700px) {
    &.live_chat_container_opened.mobile_livechat {
      height: 100%;
      width: 100%;
      bottom: 0;
      right: 0;
      border-radius: inherit;
    }
  }
}`;

const SceytLivechat = styled.div` {
  position: relative;
  height: calc(100% - 65px);
  border-radius: 23px;
  overflow: hidden;
}`;

const LivechatLoading = styled.div` {
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  animation: zoomAnimation 2s linear infinite;

  @keyframes zoomAnimation {
    0% {
      transform: scale(100%);
    }
    50% {
      transform: scale(130%);
    }
    100% {
      transform: scale(100%);
    }
  }
}`;

const OpenLivechatButton = styled.div` {
  display: flex;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 50px rgba(189, 184, 255, 0.3);
  border-radius: 50%;

  & > svg {
    width: 50px;
    height: 50px;
  }
}`;

const UnreadCount = styled.span` {
  position: absolute;
  top: -4px;
  right: -5px;
  background-color: #FA4C56;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  border-radius: 12px;
  min-width: 24px;
  height: 24px;
  padding: 0 4px;
  text-align: center;

  animation: scaleAnimation .1s linear;

  @keyframes scaleAnimation {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(1);
    }
  }
}`;

const SentSuccessContainer = styled.div` {
  position: absolute;
  display: flex;
  align-items: center;
  background-color: #fff;
  width: calc(100% - 32px);
  top: 94px;
  left: 16px;
  padding: 12px;
  box-shadow: 0 5px 40px 0 rgba(17, 21, 57, 0.14);
  z-index: 99;
  border-radius: 12px;
  animation: showAnimation .3s linear;

  & > span {
    margin-left: 12px;
  }


  @keyframes showAnimation {
    0% {
      opacity: 0;
      transform: translate(0, -30px);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}`;

const LiveChanActionsMenu = styled.span` {
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: 12px;
  cursor: pointer;
  border-radius: 8px;
  transition: all .2s;

  &:hover {
    background-color: #242849;
  }
}`;


const OpenLivechatMenu = styled.button` {
  width: 28px;
  height: 28px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}`;

const CloseLivechatButton = styled.span` {
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s;

  &:hover {
    background-color: #242849;
  }
}`;

const MenuDropDown = styled.ul` {
  position: absolute;
  top: calc(100% + 8px);
  background-color: ${colors.white};
  box-shadow: 0 4px 24px 0 rgba(17, 21, 57, 0.08), 0 0 2px 0 rgba(17, 21, 57, 0.08);
  right: 0;
  width: 220px;
  list-style: none;
  padding: 0;
  margin: 0;
  height: 0;
  border-radius: 16px;
  overflow: hidden;
  transition: all .2s;
  z-index: 99;

  &.open {
    padding: 4px 0;
    height: 88px;
  }

  .dropDown_ul li {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 40px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    transition: all .2s;
  }

  .dropDown_ul li:hover {
    background-color: #F3F5F7;
  }

  .dropDown_ul li span {
    margin-left: 16px;
  }

}`;


const MenuItem = styled.li` {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 40px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  transition: all .2s;

  &:hover {
    background-color: #F3F5F7;
  }

  & span {
    margin-left: 16px;
  }

}`;

const SendTranscriptPopup = styled.div` {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.30);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;

}`;
const SendTranscriptContainer = styled.div` {
  position: relative;
  height: 224px;
  width: 300px;
  border-radius: 16px;
  padding: 24px;
  background-color: ${colors.white};

  & p {
    font-family: Inter, sans-serif;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin: 0 0 20px;
    color: ${colors.blue6};
  }

  & input {
    min-width: inherit;
  }

}`;
const CloseSendTranscript = styled.span` {
  position: absolute;
  top: 18px;
  right: 14px;
  cursor: pointer;
}`;

const SendTranscriptButton = styled.button` {
  -webkit-font-smoothing: antialiased;
  background-color: ${colors.blue1};
  color: white;
  font-style: normal;
  border: 1px solid ${colors.blue1};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  border-radius: 8px;
  padding: 6px 12px;
  width: 100%;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.2px;


  @keyframes circleAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes showAnimation {
    0% {
      opacity: 0;
      transform: translate(0, -30px);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}`;


const LoadinWrapper = styled.span` {
  display: inline-flex;
  animation: circleAnimation 2s linear infinite;

  @keyframes circleAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}`;


export default LiveChat;