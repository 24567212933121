import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';


function Link({ to, exact, tag, className, activeClassName, ...rest }) {

    const history = useHistory();
    const { pathname } = useLocation();
    const toObject = typeof to === 'string' ? { pathname: to } : to;
    const checkActive = () => (exact ? pathname === toObject.pathname : pathname.includes(toObject.pathname));
    const [active, setActive] = useState(checkActive);

    useEffect(() => {
        const timer = setTimeout(() => {
            setActive(checkActive);
        });

        return () => timer && clearTimeout(timer);
    }, [pathname]);

    function routeTo(e) {
        e.preventDefault();
        pathname !== toObject.pathname && history.push(toObject);
    }

    return React.createElement(tag, {
        className: `${className} ${active ? activeClassName : ''}`,
        onClick: e => routeTo(e),
        ...tag === 'a' && { href: toObject.pathname },
        ...rest
    });
}

Link.propTypes = {
    to: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.shape({
            pathname: PropTypes.string.isRequired
        })
    ]).isRequired,
    tag: PropTypes.string,
    exact: PropTypes.bool,
    className: PropTypes.string,
    activeClassName: PropTypes.string
};

Link.defaultProps = {
    tag: 'a',
    exact: false,
    className: '',
    activeClassName: ''
};

export default Link;