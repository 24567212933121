const userInputsStructure = {
    id: {
        label: 'ID',
        type: 'text',
        placeholder: 'Enter ID',
        validation: {
            presence: true,
            format: {
                pattern: '^[-_.@a-zA-Z0-9]+$',
                message: '^Allowed characters are a-z, 0-9, _@.-'
            },
            length: {
                maximum: 128,
                tooLong: '^ID can not be longer than 128 characters'
            }
        }
    },
    username: {
        label: 'Username',
        type: 'text',
        placeholder: 'Enter Username',
        validation: {
            length: {
                maximum: 250,
                tooLong: '^Username can not be longer than 250 characters'
            }
        }
    },
    first_name: {
        label: 'First name',
        type: 'text',
        placeholder: 'Enter First name',
        validation: {
            length: {
                maximum: 250,
                tooLong: '^First name can not be longer than 250 characters'
            }
        }
    },
    last_name: {
        label: 'Last name',
        type: 'text',
        placeholder: 'Enter Last name',
        validation: {
            length: {
                maximum: 250,
                tooLong: '^Last name can not be longer than 250 characters'
            }
        }
    }
};

export default userInputsStructure;