import {
    PLANS_CALL,
    PLANS_CALL_SUCCESS,
    PLANS_CALL_FAIL
} from './actions';

const initialState = {
    plans: {},
    isFetched: false
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };

    switch (type) {

        case PLANS_CALL: {
            newState.isFetched = false;
            return newState;
        }

        case PLANS_CALL_SUCCESS: {
            if (payload.plans) {
                newState.plans = { ...payload.plans };
                newState.isFetched = true;
                return newState;
            }
            return state;
        }

        case PLANS_CALL_FAIL: {
            newState.isFetched = true;
            return newState;
        }

        default:
            return state;
    }
};