import React, { useCallback, useLayoutEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { appsDataSelector } from '../../store/apps/selectors';
import { useScreenMedia } from '../../hooks';
import DropDown from '../common/dropdown';
import Link from '../common/link';
import { DropdownOptionsUl, ItemNote } from '../UI';
import { menuArray, routeNames, subRoutNames } from '../../helpers/constants';
import { colors, device } from '../UI/constants';
import { ReactComponent as SettingsSvg } from '../../assets/svg/settings.svg';
import { ReactComponent as ToggleSvg } from '../../assets/svg/sidebar.svg';
import { ReactComponent as SettingsOpen } from '../../assets/svg/settingopen.svg';
import { ReactComponent as LogoSvg } from '../../assets/svg/logoWhite.svg';

export default function Sidebar({ setSidebarShow, showSidebar }) {

    const { pathname } = useLocation();
    const { isFetched, apps } = useSelector(appsDataSelector, isEqual);
    const wideSidebar = useScreenMedia('(min-width: 1200px)');

    const isActiveSettingLink = useCallback(path => pathname.includes(`/${routeNames.SETTINGS}/${path}`), [pathname]);

    const menuLink = useCallback((item, state = '') => {
        switch (state) {
            case 'loading':
            case 'disabled': {
                return (
                    <span key={item.text}>
                        <MenuItemLoading isActiveItem={`/${item.to}` === pathname}>
                            <ItemContainer noCursor>
                                {item.svg}
                                {showSidebar ? (
                                    <ItemText disabled>{item.text}</ItemText>
                                ) : (
                                    <ItemNote direction="right">{item.text}</ItemNote>
                                )}
                            </ItemContainer>
                        </MenuItemLoading>
                    </span>
                );
            }
            default: {
                return (
                    <Link key={item.text} to={`/${item.to}`}>
                        <MenuItem isActiveItem={`/${item.to}` === pathname}>
                            <ItemContainer>
                                {item.svg}
                                {showSidebar ? (
                                    <ItemText>{item.text}</ItemText>
                                ) : (
                                    <ItemNote direction="right">{item.text}</ItemNote>
                                )}
                            </ItemContainer>
                        </MenuItem>
                    </Link>
                );
            }
        }
    }, [pathname, showSidebar]);

    const settings = useCallback((state = '') => {

        const paths = [
            subRoutNames.GENERAL,
            subRoutNames.APPLICATION,
            subRoutNames.ROLES,
            subRoutNames.MARKERS,
            subRoutNames.NOTIFICATIONS,
            subRoutNames.API_KEYS,
            subRoutNames.WEB_HOOKS
        ];
        const isActive = paths.some(p => pathname.includes(`/${routeNames.SETTINGS}/${p}`));

        if (state === 'disabled' || state === 'loading') {
            return (
                <MenuItemLoading isActiveItem={isActive}>
                    <ItemContainer noCursor>
                        <SettingsSvg/>
                        {showSidebar ? (
                            <>
                                <ItemText disabled>Settings</ItemText>
                                <ArrowIcon/>
                            </>
                        ) : (
                            <ItemNote direction="right">Settings</ItemNote>
                        )}
                    </ItemContainer>
                </MenuItemLoading>
            );
        }

        return (
            <MenuItem isActiveItem={isActive}>

                <SubMenuDropdown showSidebar={showSidebar}>
                    <DropDown
                        trigger={(
                            <ItemContainer isOutOfList>
                                <SettingsSvg/>
                                {showSidebar ? (
                                    <>
                                        <ItemText>Settings</ItemText>
                                        <ArrowIcon/>
                                    </>
                                ) : (
                                    <ItemNote direction="right">Settings</ItemNote>
                                )}
                            </ItemContainer>
                        )}
                        isStatic={showSidebar}
                        forceClose={!showSidebar}
                        {...isActive && { dropDownState: showSidebar }}
                        bodyBackground="#060A26"
                    >
                        <DropUl showSidebar={showSidebar}>
                            <Link to={`/${routeNames.SETTINGS}/${subRoutNames.APPLICATION}`}>
                                <li>
                                    <SubSetting active={isActiveSettingLink(subRoutNames.APPLICATION)}>
                                        Application
                                    </SubSetting>
                                </li>
                            </Link>
                            <Link to={`/${routeNames.SETTINGS}/${subRoutNames.ROLES}`}>
                                <li>
                                    <SubSetting active={isActiveSettingLink(subRoutNames.ROLES)}>
                                        Channel Roles
                                    </SubSetting>
                                </li>
                            </Link>
                            <Link to={`/${routeNames.SETTINGS}/${subRoutNames.MARKERS}`}>
                                <li>
                                    <SubSetting active={isActiveSettingLink(subRoutNames.MARKERS)}>
                                        Message Markers
                                    </SubSetting>
                                </li>
                            </Link>
                            <Link to={`/${routeNames.SETTINGS}/${subRoutNames.NOTIFICATIONS}`}>
                                <li>
                                    <SubSetting active={isActiveSettingLink(subRoutNames.NOTIFICATIONS)}>
                                        Notifications
                                    </SubSetting>
                                </li>
                            </Link>
                            <Link to={`/${routeNames.SETTINGS}/${subRoutNames.API_KEYS}`}>
                                <li>
                                    <SubSetting active={isActiveSettingLink(subRoutNames.API_KEYS)}>
                                        API Keys
                                    </SubSetting>
                                </li>
                            </Link>
                            <Link to={`/${routeNames.SETTINGS}/${subRoutNames.WEB_HOOKS}`}>
                                <li>
                                    <SubSetting active={isActiveSettingLink(subRoutNames.WEB_HOOKS)}>
                                        Webhooks
                                    </SubSetting>
                                </li>
                            </Link>

                        </DropUl>
                    </DropDown>
                </SubMenuDropdown>
            </MenuItem>
        );
    }, [pathname, showSidebar]);

    useLayoutEffect(() => {
        setSidebarShow(wideSidebar);
    }, [wideSidebar]);

    return (
        <SidebarContainer showSidebar={showSidebar}>
            <MenuContainer>
                <LogoWrapper>
                    <LogoSvg/>
                </LogoWrapper>
                <Menu isBorder={apps.length > 0}>
                    {!isFetched ? (
                        <>
                            {menuArray.map(menuItem => menuLink(menuItem, 'loading'))}
                            {settings('loading')}
                        </>
                    ) : (
                         apps.length > 0 ? (
                            <>
                                {menuArray.map(menuItem => menuLink(menuItem))}
                                {settings()}
                            </>
                        ) : (
                            <>
                                {menuArray.map(menuItem => {
                                    if (menuItem.to === routeNames.APPS || menuItem.to === routeNames.USAGE) {
                                       return menuLink(menuItem);
                                    }
                                    return menuLink(menuItem, 'disabled');
                                })}
                                {settings('disabled')}
                            </>
                        )
                    )}
                </Menu>
            </MenuContainer>
            {wideSidebar && (
                <Footer>
                    <ItemContainer onClick={() => setSidebarShow(prev => !prev)} isOutOfList>
                        <ToggleSvg/>
                        {showSidebar && <ItemText>Toggle Sidebar</ItemText>}
                    </ItemContainer>
                </Footer>
            )}
        </SidebarContainer>
    );
}

Sidebar.propTypes = {
    setSidebarShow: PropTypes.func.isRequired,
    showSidebar: PropTypes.bool.isRequired
};

const LogoWrapper = styled.div`{
  margin: 17px 0 10px 22px;  
}`;

const SubSetting = styled.span`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: ${props => (props.active ? 500 : 'normal')};;
    font-size: 14px;
    line-height: 16px;
    color: ${props => (props.active ? colors.blue1 : '#D7D8DC')};
`;

const MenuContainer = styled.div`{
    display: flex;
    flex-direction: column;
}`;

const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 8px 12px;
`;

const ItemText = styled.span`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    margin-left: 16px;
    //color: ${colors.blue6};
    color: #D7D8DC;
   
    ${props => props.disabled && `
        color: ${colors.gray4};
    `}    
`;

const ItemContainer = styled.div`
    position: relative;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px 0;
  
    border-radius: 4px;
    cursor: pointer;
    
    &:hover {
        ${ItemNote} {
            display: block;
            
            @media ${device.laptop} { 
               display: none!important;
            }
        } 
    }
    
    ${props => (props.noCursor && 'cursor: default;')}
    
/*    ${props => (props.isOutOfList && `
        &:hover {
            svg path {
                fill: ${colors.blue1};
            }
            
            ${ItemText} {
                color: ${colors.blue1};
            }
     }`)}*/

`;

const SidebarContainer = styled.div`{
    ${props => (props.showSidebar ? `
       width: 250px;
       min-width: 250px;
       overflow-y: auto;
    ` : `
       width: 68px;
       min-width: 68px;
          ${ItemContainer} {
            width: 44px;
            justify-content: center;
        }
          ${LogoWrapper} {
            width: 30px;
            overflow: hidden;
        }
    `)};

    box-shadow: -2px 0 6px 0 rgba(0,0,0,0.17);
    //z-index: 1;
    height: 100vh;
    //overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    //background-color: white;
    background-color: #060A26;
}`;

const MenuItem = styled.li`
    position: relative;
    
    svg path {
      fill: ${colors.gray4};
    }
    
    &:hover {
        svg path {
          //fill: ${colors.blue1};
        }
        
        ${ItemText} {
          //color: ${colors.blue1};
        }
        
        ${ItemContainer} {
          background-color: #1A1E37;
        }
    }
    
    ${props => props.isActiveItem && `
        svg path {
            fill: #fff;
        }
        
        ${ItemContainer} {
          background-color: #2E3149 !important;
        }
        
        ${ItemText} {
            color: #fff;
            font-weight: 500;
        }
        
        /*&::before {
            content: "";
            position: absolute;
            top: 3px;
            right: 0;
            width: 3px;
            height: 36px;
            background-color: ${colors.blue1};
            border-radius: 6px 0px 0px 6px;
        }*/
    `}
`;

const MenuItemLoading = styled.li`
    position: relative;
    
    svg path {
      fill: #9193A0;
    }
    
    ${props => props.isActiveItem && `
        
         ${ItemText} {
          // font-weight: 500;
          // stroke-width: 0.4px;
          // -webkit-text-stroke-width: 0.4px;
        }
        
        &::before {
            content: "";
            position: absolute;
            top: 3px;
            right: 0;
            width: 3px;
            height: 36px;
            background-color: ${colors.gray2};
            border-radius: 6px 0px 0px 6px;
        }
    `}
`;


const Footer = styled.div`
    border-top: 1px solid #43465B;
`;

const ArrowIcon = styled(SettingsOpen)`
  padding: 10px;
  margin-left: auto;
  transform: rotate(-180deg);
`;

const SubMenuDropdown = styled.div`

    .dropdown-trigger.open {
        ${ItemNote} {
            display: none!important;
        } 
        
        ${ArrowIcon} {
            transform: rotate(-90deg);
        }
    }

    .dropdown-body {
        top: -45px;
        left: calc(100% + 10px);
    }
 
    ${props => (props.showSidebar && `
        .dropdown-body {
            position: relative;
            top: 0;
            left: 0;
            transform: translateY(0);
            box-shadow: none;
            margin-left: 31px;
            z-index: unset;
            
             &::before {
              content: "";
              position: absolute;
              height: 100%;
              width: 1px;
              background-color: #43465B;
              left: 0;
              top: 0;
            }
            
            ul {
               margin-top: 5px;
            }
        }
    `)}
`;

const DropUl = styled(DropdownOptionsUl)`
    li {
        padding: 6px 28px;
    
        &:hover {
            ${SubSetting} {
                //color: ${colors.blue2};
            }
        }
    }
    
    ${props => (props.showSidebar && `
        margin-bottom: 5px!important;
        
        li {
            padding: 6px 28px 6px 35px;
        }
    `)}
`;