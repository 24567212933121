const webhookInputsStructure = {
    name: {
        label: 'Name',
        type: 'text',
        placeholder: 'Enter wehbook name',
        validation: {
            presence: true,
            length: {
                maximum: 250
            }
        }
    },
    payload_url: {
        label: 'Payload URL',
        type: 'text',
        placeholder: 'https://example.com/postreceive',
        validation: {
            presence: true,
            length: {
                maximum: 2000
            },
            format: {
                pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
                message: '^Use correct url address'
            }
        }
    },
    secret: {
        label: 'Secret',
        type: 'text',
        placeholder: 'Set a secret',
        validation: {
            length: {
                maximum: 250
            }
        }
    },
    active: {
        label: 'Enable webhook',
        type: 'checkbox'
    },
    ssl_verification: {
        label: 'Enable SSL verification',
        type: 'checkbox'
    }
};

export default webhookInputsStructure;