export const APPS_CALL = 'APPS_CALL';
export const APPS_CALL_SUCCESS = 'APPS_CALL_SUCCESS';
export const APPS_CALL_FETCHED = 'APPS_CALL_FETCHED';

export const APP_CREATE_CALL = 'APP_CREATE_CALL';
export const APP_DELETE_CALL = 'APP_DELETE_CALL';
export const APP_EDIT_CALL = 'APP_EDIT_CALL';

export const APP_ADD = 'APP_ADD';
export const APP_DELETE = 'APP_DELETE';
export const APP_EDIT = 'APP_EDIT';

export const SET_SELECTED_APP = 'SET_SELECTED_APP';
export const SET_SELECTED_APP_PREVIOUS = 'SET_SELECTED_APP_PREVIOUS';
export const APP_LOADING_TOGGLE = 'APP_LOADING_TOGGLE';


export function appsCall() {
    return { type: APPS_CALL };
}

export function appsFetched() {
    return { type: APPS_CALL_FETCHED };
}

export function appCreateCall(app, region) {
    return { type: APP_CREATE_CALL, payload: { app, region } };
}

export function appEditCall(appId, app) {
    return { type: APP_EDIT_CALL, payload: { appId, app } };
}

export function appDeleteCall(appId) {
    return { type: APP_DELETE_CALL, payload: { appId } };
}


export function appAdd(app) {
    return { type: APP_ADD, payload: { app } };
}

export function appEdit(appId, appNewValues) {
    return { type: APP_EDIT, payload: { appId, appNewValues } };
}

export function appDelete(appId) {
    return { type: APP_DELETE, payload: { appId } };
}

export function setSelectedApp(appId) {
    return { type: SET_SELECTED_APP, payload: { appId } };
}

export function setSelectedAppPrevious(appId) {
    return { type: SET_SELECTED_APP_PREVIOUS, payload: { appId } };
}

export function toggleAppLoading(loadingType, loadingState) {
    return { type: APP_LOADING_TOGGLE, payload: { loadingType, loadingState } };
}