import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../UI/constants';
import { useDidUpdate, useEventListener } from '../../../hooks';

function DropDown({
                      trigger,
                      minWith,
                      withIcon,
                      iconColor,
                      isStatic,
                      forceClose,
                      dropDownState,
                      bodyPosition,
                      bodyBackground,
                      isUserActions,
                      tableContainer,
                      noOverflowBody,
                      ...rest
                  }) {
    const [isOpen, setIsOpen] = useState(false);
    const [bodyPositionTop, setBodyPositionTop] = useState(bodyPosition);
    const dropDownRef = useRef(null);
    const dropDownBodyRef = useRef(null);

    function toggleDropdown(e) {
        e.preventDefault();
        setIsOpen(!isOpen);
    }

    // function handleDropdownOutsideClick(e) {
    //     if (isOpen && dropDownRef.current && !dropDownRef.current.contains(e.target)) {
    //         setIsOpen(!isOpen);
    //     }
    // }

    function handleDropdownClicks(e) {
        if (isOpen) {
            if (!isStatic) {
                const dropDownElem = dropDownRef.current;
                const dropDownBodyElem = dropDownBodyRef.current;
                if (isUserActions) {
                    setBodyPositionTop(tableContainer?.offsetHeight > dropDownElem.getBoundingClientRect().top - 50 ? 'bottom' : 'top');
                }
                // Click outside dropdown
                if (dropDownElem && !dropDownElem.contains(e.target)) {
                    setIsOpen(false);
                }

                // Click on dropdown body
                if (dropDownBodyElem && dropDownBodyElem.contains(e.target)) {

                    const listElement = dropDownBodyElem.getElementsByTagName('ul')[0];
                    if (listElement && listElement.contains(e.target)) {
                        setIsOpen(false);
                    }

                    // if (e.target.tagName === 'LI' && e.target.tagName === 'A') {
                    //     setIsOpen(false);
                    // }

                    // let elm = {};
                    // const elms = dropDownBodyElem.getElementsByTagName('li');
                    // console.log(elms);
                    // for (let i = 0; i < elms.length; i++) {
                    //     if (elms[i].id === 'childID') {
                    //         elm = elms[i];
                    //         break;
                    //     }
                    // }
                    // return elm;
                }
            }
        }
    }

    const handleScrolling = event => {
        event.stopPropagation();
    };

    useEventListener('click', handleDropdownClicks);

    useDidUpdate(() => {
        if (forceClose) {
            setIsOpen(false);
        }
    }, [forceClose]);

    useEffect(() => {
        if (dropDownState !== undefined) {
            setIsOpen(dropDownState);
        }
    }, [dropDownState]);

    return (
        <DropDownContainer className="dropdown-wrapper" ref={dropDownRef}>
            <DropDownTriggerContainer
                onClick={toggleDropdown}
                withIcon={React.isValidElement(trigger) ? withIcon : true}
                isOpen={isOpen}
                className={`dropdown-trigger ${isOpen ? 'open' : ''}`}
                iconColor={iconColor}
            >
                {React.isValidElement(trigger) ? (
                    trigger
                ) : (
                    <span>{trigger}</span>
                )}
                {/* {React.cloneElement(trigger, { onClick: toggleDropdown })} */}
            </DropDownTriggerContainer>
            {isOpen && (
                <DropDownBody
                    backgrountColor={bodyBackground}
                    className="dropdown-body"
                    ref={dropDownBodyRef}
                    minWith={minWith}
                    bodyPosition={bodyPositionTop}
                    onScroll={handleScrolling}
                    noOverflow={noOverflowBody}
                >
                    {rest.children}
                </DropDownBody>
            )}
        </DropDownContainer>
    );
}

DropDown.propTypes = {
    trigger: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    iconColor: PropTypes.string,
    withIcon: PropTypes.bool,
    isStatic: PropTypes.bool,
    forceClose: PropTypes.bool,
    dropDownState: PropTypes.bool,
    isUserActions: PropTypes.bool,
    bodyPosition: PropTypes.string,
    bodyBackground: PropTypes.string,
    noOverflowBody: PropTypes.bool,
    minWith: PropTypes.string,
    tableContainer: PropTypes.objectOf(PropTypes.any.isRequired)
};

DropDown.defaultProps = {
    trigger: 'Dropdown',
    bodyPosition: 'bottom',
    minWith: '',
    iconColor: '',
    withIcon: false,
    isUserActions: false,
    noOverflowBody: false,
    isStatic: false,
    forceClose: false,
    dropDownState: undefined,
    tableContainer: undefined,
    bodyBackground: '#fff'
};

export default DropDown;

const DropDownContainer = styled.div`
    position: relative;
    height: 100%;
`;

const DropDownTriggerContainer = styled.button`
    position: relative;
    cursor: pointer;
    height: 100%;
    width: 100%;
    user-select: none;
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 0;
    
    ${props => props.withIcon && `
        padding-right: 24px;
        
        &::after {
            content: "";
            position: absolute;
            width: 5px;
            height: 5px;
            border-width: 2px 2px 0 0;
            //border-color: ${colors.gray2};
            border-color: ${props.iconColor || 'white'};
            border-style: solid;
            //border-radius: 2px;
            top: calc(50% - 1px);
            right: 8px;
            transform: translateY(-50%) rotate(135deg);
            transition: all 0.1s;
        }
    `}
 
    ${props => (props.isOpen && `
        &::after {
            transform: translateY(-50%) rotate(-45deg);
            top: calc(50% + 2px);
        }
        `)};

`;

const DropDownBody = styled.div`
    position: absolute;
    z-index: 300;
    min-width: ${props => (props.minWith || '200px')};
    ${props => (props.bodyPosition === 'top' ? 'bottom: 100%;' : 'top: 100%;')}
    right: 0;
    display: flex;
    flex-direction: column;
    background: ${props => props.backgrountColor};
    border-radius: 4px;
    max-height: 220px;
    ${props => (props.noOverflow ? 'overflow-y: auto;' : '')}
    box-shadow: 0.8px 0.8px 0 rgba(31, 35, 60, 0.06), 0 0 2px rgba(31, 35, 60, 0.08), 0 2px 6px rgba(31, 35, 60, 0.16);
    
    & > * {
        &:first-child {
          margin-top: 15px;
        }
        &:first-child {
          margin-bottom: 15px;
        }
    }
`;