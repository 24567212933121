import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { setError } from '../error/actions';
import { rootApi } from '../../api';
import {
    MARKERS_CALL,
    MARKERS_CALL_SUCCESS,
    MARKERS_CALL_FAIL,
    MARKER_CREATE_CALL,
    MARKER_DELETE_CALL,
    markerAdd,
    markerDelete,
    removeMarkersLoading
} from './actions';

import { setRequestsLimit } from '../customer/actions';

function* markersCallGen(action) {
    try {
        const res = yield call(rootApi.get, '/message-markers/{APPID}');
        const markers = [...res.data];
        yield put({ type: MARKERS_CALL_SUCCESS, payload: { markers } });
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put({ type: MARKERS_CALL_FAIL });
    }
}

function* markerCreateCallGen(action) {
    try {
        const { payload: { marker } } = action;
        const res = yield call(rootApi.post, '/message-markers/{APPID}', { marker });
        yield put(markerAdd(res.data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeMarkersLoading());
    }
}

function* markerDeleteCallGen(action) {
    try {
        const { payload: { markerName } } = action;
        yield call(rootApi.delete, `/message-markers/{APPID}/${markerName}`);
        yield put(markerDelete(markerName));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeMarkersLoading());
    }
}


export default function* markersWatcherSaga() {
    yield takeLatest(MARKERS_CALL, markersCallGen);
    yield takeEvery(MARKER_CREATE_CALL, markerCreateCallGen);
    yield takeEvery(MARKER_DELETE_CALL, markerDeleteCallGen);
}