export const NOTIFICATIONS_CALL = 'NOTIFICATIONS_CALL';
export const NOTIFICATIONS_CALL_SUCCESS = 'NOTIFICATIONS_CALL_SUCCESS';
export const NOTIFICATION_DELIVERY_SETTINGS_CALL_SUCCESS = 'NOTIFICATION_DELIVERY_SETTINGS_CALL_SUCCESS';
export const NOTIFICATIONS_CALL_FAIL = 'NOTIFICATIONS_CALL_FAIL';

export const NOTIFICATION_CREATE_CALL = 'NOTIFICATION_CREATE_CALL';
export const NOTIFICATION_DELETE_CALL = 'NOTIFICATION_DELETE_CALL';
export const NOTIFICATION_DELIVERY_CALL = 'NOTIFICATION_DELIVERY_CALL';
export const NOTIFICATION_EDIT_CALL = 'NOTIFICATION_EDIT_CALL';
export const NOTIFICATION_DELIVERY_SETTINGS_CALL = 'NOTIFICATION_DELIVERY_SETTINGS_CALL';

export const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
export const NOTIFICATION_DELETE = 'NOTIFICATION_DELETE';
export const NOTIFICATION_EDIT = 'NOTIFICATION_EDIT';
export const NOTIFICATION_DELIVERY_SETTING = 'NOTIFICATION_DELIVERY_SETTING';

export const NOTIFICATION_LOADING_OFF = 'NOTIFICATION_LOADING_OFF';

export const COMPILE_MUSTACHE = 'COMPILE_MUSTACHE';
export const SET_PREVIEWS_DATA = 'SET_PREVIEWS_DATA';


export function notificationsCall() {
    return { type: NOTIFICATIONS_CALL };
}

export function notificationCreateCall(notificationFormData) {
    return { type: NOTIFICATION_CREATE_CALL, payload: { notificationFormData } };
}

export function notificationEditCall(id, notificationFormData) {
    return { type: NOTIFICATION_EDIT_CALL, payload: { id, notificationFormData } };
}

export function notificationDeleteCall(id) {
    return { type: NOTIFICATION_DELETE_CALL, payload: { id } };
}


export function notificationAdd(notification) {
    return { type: NOTIFICATION_ADD, payload: { notification } };
}

export function notificationEdit(id, notificationNewValues) {
    return { type: NOTIFICATION_EDIT, payload: { id, notificationNewValues } };
}

export function notificationDeliverySetting(notificationSettingsData) {
    return { type: NOTIFICATION_DELIVERY_SETTING, payload: { notificationSettingsData } };
}

export function notificationDeliverySettingCall() {
    return { type: NOTIFICATION_DELIVERY_SETTINGS_CALL };
}

export function notificationDeliverySettingSuccess(settingsData) {
    return { type: NOTIFICATION_DELIVERY_SETTINGS_CALL_SUCCESS, payload: { settingsData } };
}

export function notificationDelete(id) {
    return { type: NOTIFICATION_DELETE, payload: { id } };
}

export function removeWebhooksLoading() {
    return { type: NOTIFICATION_LOADING_OFF };
}

export function handleCompileMustache(mustache, channelType) {
    return { type: COMPILE_MUSTACHE, payload: { mustache, channelType } };
}

export function setPreviewsData(previewsData) {
    return { type: SET_PREVIEWS_DATA, payload: { previewsData } };
}