import { call, put, takeLatest } from 'redux-saga/effects';
import { setError } from '../error/actions';
import { rootApi } from '../../api';
import {
    PLANS_CALL,
    PLANS_CALL_SUCCESS,
    PLANS_CALL_FAIL
} from './actions';
import { setRequestsLimit } from '../customer/actions';

function* plansCallGen(action) {
    try {
        const request = yield call(rootApi.get, '/plans');
        yield put({ type: PLANS_CALL_SUCCESS, payload: { plans: request.data } });
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put({ type: PLANS_CALL_FAIL });
    }
}

export default function* plansWatcherSaga() {
    yield takeLatest(PLANS_CALL, plansCallGen);
}