/** String **/
// eslint-disable-next-line no-extend-native
String.prototype.normalizeString = () => this.replace(/_/g, ' ');

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'hashCode', {
    value() {
        let hash = 0; let i; let chr;
        for (i = 0; i < this.length; i++) {
            chr = this.charCodeAt(i);
            // eslint-disable-next-line no-bitwise
            hash = ((hash << 5) - hash) + chr;
            // eslint-disable-next-line no-bitwise
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
});

/** Number **/