import { all } from 'redux-saga/effects';
import customerWatcherSaga from '../customer/sagas';
import appsWatcherSaga from '../apps/sagas';
import rolesWatcherSaga from '../roles/sagas';
import permissionsWatcherSaga from '../permissions/sagas';
import regionsWatcherSaga from '../regions/sagas';
import usersWatcherSaga from '../users/sagas';
import membersWatcherSaga from '../members/sagas';
import channelsWatcherSaga from '../channels/sagas';
import channelMembersWatcherSaga from '../channel-members/sagas';
import webhooksWatcherSaga from '../webhooks/sagas';
import webhookEventsWatcherSaga from '../webhook-events/sagas';
import settingsSaga from '../api-keys/sagas';
import notificationsWatcherSaga from '../notifications/sagas';
import usageWatcherSaga from '../usage/sagas';
import plansWatcherSaga from '../plans/sagas';
import moderationWatcherSaga from '../moderation/sagas';
import markersWatcherSaga from '../markers/sagas';

export default function* rootSaga() {
    yield all([
        customerWatcherSaga(),
        appsWatcherSaga(),
        rolesWatcherSaga(),
        permissionsWatcherSaga(),
        regionsWatcherSaga(),
        usersWatcherSaga(),
        channelsWatcherSaga(),
        channelMembersWatcherSaga(),
        webhooksWatcherSaga(),
        webhookEventsWatcherSaga(),
        settingsSaga(),
        notificationsWatcherSaga(),
        usageWatcherSaga(),
        plansWatcherSaga(),
        membersWatcherSaga(),
        moderationWatcherSaga(),
        markersWatcherSaga()
    ]);
}