import React, { useLayoutEffect, useState } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { errorSelectorByIds } from '../../../store/error/selector';
import { removeError } from '../../../store/error/actions';
import NoDataPage from '../no-data-page';
import NetworkError from '../../../helpers/network-error';
import { ButtonBlock, Col } from '../../UI';
import { ReactComponent as ErrorScreenSvg } from '../../../assets/svg/error-screen-icon.svg';
import { ReactComponent as LockSvg } from '../../../assets/svg/lock.svg';


export default function ErrorWrapper({ listenToErrors, errorScreen, errorScreenContainerStyles, children }) {

    const dispatch = useDispatch();
    const error = useSelector(store => errorSelectorByIds(store, listenToErrors), isEqual);
    const [popupData, setPopupData] = useState({});
    useLayoutEffect(() => {
        if (error) {
            const processedError = new NetworkError(error);
            setPopupData(processedError);
        }
    }, [error]);

    function handleButtonAction() {
        dispatch(removeError(error.failedAction.type));

        if (error.failedAction) {
            if (typeof error.failedAction === 'function') {
                dispatch(error.failedAction());
            } else {
                dispatch(error.failedAction);
            }
        }
    }

    // const withErrorHandling = Component => {
    //     if (!error) {
    //         return (
    //             <Component/>
    //         );
    //     }
    //     return null;
    // };

    return (
        error ? (
            errorScreen !== undefined ? (error.error && error.error.response && error.error.response.status === 403) ? null : (
                <ErrorBlockContainerCustom customStyles={errorScreenContainerStyles}>
                    {errorScreen}
                    <ButtonBlock marginTop="0px">
                        {error.failedAction && (
                            <button type="button" className="button blue filled" onClick={handleButtonAction}>
                                {popupData.buttonName}
                            </button>
                        )}
                    </ButtonBlock>
                </ErrorBlockContainerCustom>
            ) : (
                (error.error && error.error.response && error.error.response.status === 403)
                    ? (
                        <ErrorBlockContainer align="center" justify="center">
                            <ErrorBlock>
                                <NoDataPage
                                    icon={<LockSvg/>}
                                    title="Access Restricted"
                                    description="You don’t have permissions to access this page."
                                />
                            </ErrorBlock>
                        </ErrorBlockContainer>
                    )
                    : (
                        <ErrorBlockContainer align="center" justify="center">
                            <ErrorBlock>
                                <NoDataPage
                                    icon={<ErrorScreenSvg/>}
                                    title="Connection problem!"
                                    description={'Slow or no internet connection.\n Refresh the page or check your connection.'}
                                >
                                    <ButtonBlock justify="center">
                                        {error.failedAction && (
                                            <button
                                                type="button"
                                                className="button blue filled big"
                                                onClick={handleButtonAction}
                                            >
                                                {popupData.buttonName}
                                            </button>
                                        )}
                                    </ButtonBlock>
                                </NoDataPage>
                            </ErrorBlock>
                        </ErrorBlockContainer>
                    ))) : (
            children
        )
    );
}

ErrorWrapper.propTypes = {
    listenToErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.node,
    errorScreen: PropTypes.node,
    errorScreenContainerStyles: PropTypes.string
};

ErrorWrapper.defaultProps = {
    errorScreen: undefined,
    errorScreenContainerStyles: '',
    children: PropTypes.node
};

const ErrorBlockContainer = styled(Col)`
  width: 100%;
  height: 100%;
`;

const ErrorBlockContainerCustom = styled.div`
  ${props => props.customStyles || `
        display: flex;
        flex-direction: column; 
        align-items: center;
        padding: 25px;
    `}
`;

const ErrorBlock = styled(Col)`
  width: 400px;
  padding: 10px;
`;