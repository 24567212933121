import {
    REGIONS_CALL,
    REGIONS_CALL_SUCCESS,
    REGIONS_CALL_FAIL
} from './actions';


const initialState = {
    regions: [],
    isFetched: false
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };

    switch (type) {

        case REGIONS_CALL: {
            newState.isFetched = false;
            return newState;
        }

        case REGIONS_CALL_SUCCESS: {
            if (payload.regions) {
                newState.regions = [...payload.regions];
                newState.isFetched = true;
                return newState;
            }
            return state;
        }

        case REGIONS_CALL_FAIL: {
            newState.isFetched = true;
            return newState;
        }

        default:
            return state;
    }
};