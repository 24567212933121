export const regionsDataSelector = store => {
    if (store.regionsData) {
        return store.regionsData;
    }
    return null;
};

export const regionsSelector = store => {
    if (store.regionsData.regions) {
        return store.regionsData.regions;
    }
    return null;
};