export const RESET_MEMBERS = 'RESET_MEMBERS';
export const RESET_MEMBERS_STORE = 'RESET_MEMBERS_STORE';

export const SET_BLOCKED_MEMBERS = 'SET_BLOCKED_MEMBERS';
export const GET_BLOCKED_MEMBERS = 'GET_BLOCKED_MEMBERS';
export const GET_ACTIVE_MEMBERS = 'GET_ACTIVE_MEMBERS';
export const SET_ACTIVE_MEMBERS = 'SET_ACTIVE_MEMBERS';

export const BLOCK_MEMBER = 'BLOCK_MEMBER';
export const UNBLOCK_MEMBER = 'UNBLOCK_MEMBER';

export const CHANGE_ROLE = 'CHANGE_ROLE';
export const CHANGE_ROLE_STORE = 'CHANGE_ROLE_STORE';

export const DELETE_MEMBERS = 'DELETE_MEMBERS';

export const ADD_MEMBERS = 'ADD_MEMBERS';

export const TOGGLE_ACTIVE_MEMBERS_LOADING = 'TOGGLE_ACTIVE_MEMBERS_LOADING';
export const TOGGLE_BLOCKED_MEMBERS_LOADING = 'TOGGLE_BLOCKED_MEMBERS_LOADING';
export const MEMBERS_ACTION_LOADING_CHANGE = 'MEMBERS_ACTION_LOADING_CHANGE';
export const UNBLOCK_MEMBER_LOADING = 'UNBLOCK_MEMBER_LOADING';

export function blockMember(memberId, channelId) {
    return { type: BLOCK_MEMBER, payload: { memberId, channelId } };
}

export function unblockMember(memberId, channelId) {
    return { type: UNBLOCK_MEMBER, payload: { memberId, channelId } };
}

export function changeRole(channelId, member) {
    return { type: CHANGE_ROLE, payload: { channelId, member } };
}

export function changeRoleStore(channelId, member) {
    return { type: CHANGE_ROLE_STORE, payload: { channelId, member } };
}

export function deleteMember(member, channelId) {
    return { type: DELETE_MEMBERS, payload: { member, channelId } };
}

export function getActiveMembers(channelId, queries) {
    return { type: GET_ACTIVE_MEMBERS, payload: { queries, channelId } };
}

export function getBlockedMembers(channelId, queries) {
    return { type: GET_BLOCKED_MEMBERS, payload: { queries, channelId } };
}

export function setActiveMembers(channelId, members) {
    return { type: SET_ACTIVE_MEMBERS, payload: { members, channelId } };
}

export function setBlockedMembers(channelId, members) {
    return { type: SET_BLOCKED_MEMBERS, payload: { members, channelId } };
}

export function addMembers(channelId, memberIds) {
    return { type: ADD_MEMBERS, payload: { channelId, memberIds } };
}

export function toggleActiveMembersLoading() {
    return { type: TOGGLE_ACTIVE_MEMBERS_LOADING, payload: {} };
}

export function toggleBlockedMembersLoading() {
    return { type: TOGGLE_BLOCKED_MEMBERS_LOADING, payload: {} };
}

export function memberActionLoadingChange(status) {
    return { type: MEMBERS_ACTION_LOADING_CHANGE, payload: { status } };
}

export function unBlockMemberLoading(data) {
    return { type: UNBLOCK_MEMBER_LOADING, payload: { data } };
}

export function resetMembers(isReset) {
    return { type: RESET_MEMBERS, payload: { isReset } };
}

export function resetMembersStore(isReset) {
    return { type: RESET_MEMBERS_STORE, payload: { isReset } };
}