import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Col, TitleDescription } from '../../UI';
import { colors } from '../../UI/constants';


function NoDataPage({ icon, title, description, children, padding }) {
  return useMemo(() => (
    <NoDataWrapper padding={padding}>
        {icon && icon}

        <TitleContainer className="title-container">
            {title && (
                <Title>{title}</Title>
            )}
            {description && (
                <TitleDescription>{description}</TitleDescription>
            )}
        </TitleContainer>

        {children}
    </NoDataWrapper>
), [icon, title, description, children]);
}

NoDataPage.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.element,
    padding: PropTypes.string
};

export default NoDataPage;


export const NoDataWrapper = styled(Col)`
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: ${props => props.padding || '60px 15px'};
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
    margin: 25px 0 15px 0;
`;

export const Title = styled.h2`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-top: 0;
    margin-bottom: 15px;
    color: ${colors.blue6};
`;

export const Description = styled.p`
    font-size: 15px;
    line-height: 1.25rem;
    font-weight: 400;
`;