import {
    SET_CHANNELS,
    RESET_CHANNELS_STORE,
    CHANNELS_CHANGE_LOADING,
    CHANNELS_CHANGE_ACTION_LOADING,
    SET_CHANNEL_TYPES
} from './actions';
// import { channelTypes } from '../../helpers/constants';

const initialState = {
    isChangeLoading: false,
    isFetched: false,
    actionLoading: false,
    channels: [],
    channelTypes: []
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };

    switch (type) {
        case RESET_CHANNELS_STORE:
            newState.isFetched = false;
            newState.channels = [];
            return newState;

        case SET_CHANNELS:
            newState.isFetched = true;
            newState.channels = payload.channels || [];
            newState.actionLoading = false;
            return newState;

        case SET_CHANNEL_TYPES:
            newState.channelTypes = payload.channelTypes || [];
            return newState;

        case CHANNELS_CHANGE_LOADING:
            newState.isChangeLoading = payload.status;
            return newState;

        case CHANNELS_CHANGE_ACTION_LOADING:
            newState.actionLoading = payload.status;
            return newState;

        default:
            return state;
    }
};