import {
    USERS_CALL,
    USERS_CALL_SUCCESS,
    USERS_CALL_FAIL,
    USER_CREATE_CALL,
    USER_DELETE_CALL,
    USER_EDIT_CALL,
    USER_LOADING_OFF,
    USERS_CALL_FILTER,
    USER_EDIT,
    USER_DEACTIVATE_CALL,
    USER_ACTIVATE_CALL
} from './actions';

const initialState = {
    total: 0,
    count: 0,
    users: [],
    pagination: {},
    loading: false,
    filterLoading: false,
    isFetched: false,
    queryParams: {}
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };
    const newUsers = [...newState.users];

    switch (type) {
        case USERS_CALL: {
            newState.users = [];
            newState.isFetched = false;
            if (payload.queryParams) {
                newState.queryParams = { ...payload.queryParams };
            }
            return newState;
        }

        case USERS_CALL_FILTER: {
            if (payload.queryParams) {
                newState.queryParams = { ...payload.queryParams };
                newState.filterLoading = true;
            }
            return newState;
        }

        case USERS_CALL_SUCCESS: {
            if (payload.users) {
                newState.isFetched = true;
                newState.total = payload.total;
                newState.count = payload.count;
                newState.users = [...payload.users];
                newState.pagination = payload.pagination;
                newState.filterLoading = false;
                newState.loading = false;
                return newState;
            }
            return state;
        }

        case USERS_CALL_FAIL: {
            newState.isFetched = true;
            return newState;
        }

        case USER_CREATE_CALL:
        case USER_EDIT_CALL:
        case USER_DEACTIVATE_CALL:
        case USER_ACTIVATE_CALL:
        case USER_DELETE_CALL: {
            newState.loading = true;
            return newState;
        }

        case USER_LOADING_OFF: {
            newState.loading = false;
            newState.filterLoading = false;
            return newState;
        }

        // case USER_ADD: {
        //     if (payload.user) {
        //         newState.users = [payload.user, ...newUsers];
        //         newState.loading = false;
        //         return newState;
        //     }
        //     return state;
        // }
        //
        // case USER_DELETE: {
        //     if (payload.id !== undefined) {
        //         newState.users = newUsers.filter(user => user.id !== payload.id);
        //         newState.loading = false;
        //         return newState;
        //     }
        //     return state;
        // }

        case USER_EDIT: {
            if (payload.id !== undefined && payload.userNewValues) {
                newState.users = newUsers.map(user => (user.id === payload.id ? { ...user, ...payload.userNewValues } : user));
                newState.loading = false;
                return newState;
            }
            return state;
        }

        default:
            return state;
    }
};