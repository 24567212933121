import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers';
import rootSaga from './root-saga';


const sagaMiddleware = createSagaMiddleware();

const defaultState = {
    // notificationsData: {
    //     isFetched: true,
    //     notifications: [
    //         {
    //             id: 0,
    //             name: 'ios-sandbox-push',
    //             certificate_id: 'CR77f5e5851efe91c2435a81e0e31',
    //             type: 1
    //         },
    //         {
    //             id: 1,
    //             name: 'android-dev-new test',
    //             certificate_id: 'BFFf5e5851efe91c2435a81e0e31',
    //             type: 0
    //         }
    //     ]
    // }
};

const store = createStore(reducers, defaultState, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export default store;