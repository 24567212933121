import { call, put, takeLatest } from 'redux-saga/effects';
import { setError } from '../error/actions';
import { rootApi } from '../../api';
import {
    WEBHOOK_EVENTS_CALL,
    WEBHOOK_EVENTS_CALL_SUCCESS,
    WEBHOOK_EVENTS_CALL_FAIL
} from './actions';

import { setRequestsLimit } from '../customer/actions';

function* webhookEventsCallGen(action) {

    try {
        const request = yield call(rootApi.get, '/webhooks/{APPID}/events');
        const webhookEvents = [...request.data];
        yield put({ type: WEBHOOK_EVENTS_CALL_SUCCESS, payload: { webhookEvents } });
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put({ type: WEBHOOK_EVENTS_CALL_FAIL });
    }
}

export default function* webhookEventsWatcherSaga() {
    yield takeLatest(WEBHOOK_EVENTS_CALL, webhookEventsCallGen);
}