const channelInputStructure = {
    uri: {
        label: 'URI',
        type: 'text',
        placeholder: 'Enter channel URI',
        validation: {
            length: {
                maximum: 500
            }
        }
    },
    metadata: {
        label: 'Metadata',
        type: 'text',
        placeholder: 'Enter a metadata',
        validation: {
            length: {
                maximum: 2000
            }
        }
    },
    subject: {
        label: 'Channel subject',
        type: 'text',
        placeholder: 'Enter channel subject',
        validation: {
            length: {
                maximum: 250
            }
        }
    }
};

export default channelInputStructure;