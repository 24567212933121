import {
    QUERY_PARAMS_UPDATE, QUERY_CHANNEL_PARAMS_UPDATE
} from './actions';

const initialState = {
    channelsPagination: {
        nextPageParams: null,
        currentPageParams: null,
        prevPagesParams: []
    },
    nextPageParams: null,
    currentPageParams: null,
    prevPagesParams: []
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };

    switch (type) {
        case QUERY_PARAMS_UPDATE:
            switch (payload.direction) {
                case 'prev':
                    newState.currentPageParams = newState.prevPagesParams.pop();
                    break;
                case 'next':
                    newState.prevPagesParams.push(newState.currentPageParams);
                    newState.currentPageParams = newState.nextPageParams;
                    break;
                default:
                    newState.prevPagesParams = [];
                    newState.currentPageParams = payload.params.currentParams;
            }
            newState.nextPageParams = payload.params.nextParams;
            return newState;
        case QUERY_CHANNEL_PARAMS_UPDATE:
            switch (payload.direction) {
                case 'prev':
                    newState.channelsPagination.currentPageParams = newState.channelsPagination.prevPagesParams.pop();
                    break;
                case 'next':
                    newState.channelsPagination.prevPagesParams.push(newState.channelsPagination.currentPageParams);
                    newState.channelsPagination.currentPageParams = newState.channelsPagination.nextPageParams;
                    break;
                default:
                    newState.channelsPagination.prevPagesParams = [];
                    newState.channelsPagination.currentPageParams = payload.params.currentParams;
            }
            newState.channelsPagination.nextPageParams = payload.params.nextParams;
            return newState;
        default:
            return state;
    }
};