import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { setError } from '../error/actions';
import { rootApi } from '../../api';
import {
    ROLES_CALL,
    ROLES_CALL_SUCCESS,
    ROLES_CALL_FAIL,
    ROLE_CREATE_CALL,
    ROLE_EDIT_CALL,
    ROLE_DELETE_CALL,
    roleAdd,
    roleEdit,
    roleDelete,
    removeRolesLoading
} from './actions';

import { setRequestsLimit } from '../customer/actions';

function* rolesCallGen(action) {
    try {
        const res = yield call(rootApi.get, '/roles/{APPID}');
        const roles = [...res.data];
        yield put({ type: ROLES_CALL_SUCCESS, payload: { roles } });
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put({ type: ROLES_CALL_FAIL });
    }
}

function* roleCreateCallGen(action) {
    try {
        const { payload: { role } } = action;
        const res = yield call(rootApi.post, '/roles/{APPID}', { role });
        yield put(roleAdd(res.data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeRolesLoading());
    }
}

function* roleEditCallGen(action) {
    try {
        const { payload: { roleName, role } } = action;
        const res = yield call(rootApi.put, `/roles/{APPID}/${roleName}`, { role });
        yield put(roleEdit(roleName, res.data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeRolesLoading());
    }
}

function* roleDeleteCallGen(action) {
    try {
        const { payload: { roleName } } = action;
        yield call(rootApi.delete, `/roles/{APPID}/${roleName}`);
        yield put(roleDelete(roleName));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeRolesLoading());
    }
}


export default function* rolesWatcherSaga() {
    yield takeLatest(ROLES_CALL, rolesCallGen);
    yield takeEvery(ROLE_CREATE_CALL, roleCreateCallGen);
    yield takeEvery(ROLE_EDIT_CALL, roleEditCallGen);
    yield takeEvery(ROLE_DELETE_CALL, roleDeleteCallGen);
}