import { call, put, select, takeEvery } from 'redux-saga/effects';
import { rootApi } from '../../api';
import {
    CHANGE_ROLE,
    DELETE_MEMBERS,
    GET_ACTIVE_MEMBERS,
    GET_BLOCKED_MEMBERS,
    setActiveMembers,
    setBlockedMembers,
    ADD_MEMBERS,
    BLOCK_MEMBER,
    UNBLOCK_MEMBER,
    RESET_MEMBERS,
    getActiveMembers,
    resetMembersStore,
    toggleActiveMembersLoading,
    toggleBlockedMembersLoading,
    memberActionLoadingChange,
    changeRoleStore,
    getBlockedMembers, unBlockMemberLoading
} from './actions';
import { queryParamsUpdate } from '../pagination/actions';
import { setError } from '../error/actions';
import { queryHandler } from '../../helpers/sagas';
import { paginationDataSelector } from '../pagination/selectors';
import { setRequestsLimit } from '../customer/actions';

function* getActiveMembersSaga(action) {
    try {
        const { payload: { queries, channelId } } = action;
        const { params } = queries;
        yield put(toggleActiveMembersLoading());
        const current = !(queries.direction === 'current');
        const usersCallParams = current ? yield queryHandler(queries.direction, params || queries) : params || queries;
        const { data } = yield call(rootApi.get, `/channels/{APPID}/${channelId}/users`, { params: { ...usersCallParams } });
        if (current) {
            yield put(queryParamsUpdate(queries.direction, { nextParams: { ...usersCallParams, next: data.next }, currentParams: { ...usersCallParams } }));
        }
        if (data.users) {
            yield put(setActiveMembers(channelId, data.users));
        }
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(memberActionLoadingChange(false));
    yield put(toggleActiveMembersLoading());
}

function* getBlockedMembersSaga(action) {
    try {
        const { payload: { queries, channelId } } = action;
        const { params } = queries;
        yield put(toggleBlockedMembersLoading());
        const current = !(queries.direction === 'current');
        const usersCallParams = current ? yield queryHandler(queries.direction, params) : params;
        const { data } = yield call(rootApi.get, `/channels/{APPID}/${channelId}/blocked-users`, { params: { ...usersCallParams } });
        if (current) {
            yield put(queryParamsUpdate(queries.direction, { nextParams: { ...usersCallParams, next: data.next }, currentParams: { ...usersCallParams } }));
        }
        if (data.users) {
            yield put(setBlockedMembers(channelId, data.users));
        }
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(toggleBlockedMembersLoading(false));
}

function* deleteMembersSaga(action) {
    try {
        yield put(memberActionLoadingChange(true));
        const { payload: { member, channelId } } = action;
        const memberToDelete = {
            id: member.id,
            role: member.role
        };
        yield call(rootApi.put, `/channels/{APPID}/${channelId}/users`, { members: [memberToDelete] });
        yield put(memberActionLoadingChange(false));
        const { currentPageParams } = yield select(paginationDataSelector);
        yield put(getActiveMembers(channelId, currentPageParams));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(memberActionLoadingChange(false));
    }
}
// eslint-disable-next-line require-yield
function* blockMemberSaga({ payload: { channelId, memberId } }) {
    try {
        yield put(memberActionLoadingChange(true));
        yield call(rootApi.put, `/channels/{APPID}/${channelId}/userBlock`, { memberIds: [memberId] });

        const { currentPageParams } = yield select(paginationDataSelector);
        yield put(getActiveMembers(channelId, currentPageParams));
        // const res = yield call(rootApi.get, '/edit-channel/{APPID}', { params: queries });

        // yield put((res.data.total, res.data.count, res.data.links));
        yield put(memberActionLoadingChange(false));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(memberActionLoadingChange(false));
    }
}

// eslint-disable-next-line require-yield
function* unblockMemberSaga({ payload: { channelId, memberId } }) {
    try {
        yield put(unBlockMemberLoading({ status: true, memberId }));
        yield call(rootApi.post, `/channels/{APPID}/${channelId}/userBlock`, { memberIds: [memberId] });
        const { currentPageParams } = yield select(paginationDataSelector);
        yield put(getBlockedMembers(channelId, currentPageParams));
        yield put(unBlockMemberLoading({ status: false, memberId }));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(unBlockMemberLoading({ status: false, memberId }));
    }
}

function* resetMembersSaga(action) {
    try {
        const { payload: { isReset } } = action;
        yield put(resetMembersStore(isReset));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        console.log(error);
    }
}

function* addMembersSaga(action) {
    try {
        yield put(memberActionLoadingChange(true));
        const { payload: { channelId, memberIds } } = action;
        yield call(rootApi.post, `/channels/{APPID}/${channelId}/users`, { members: [...memberIds] });
        const { currentPageParams } = yield select(paginationDataSelector);
        yield put(getActiveMembers(channelId, currentPageParams));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(memberActionLoadingChange(false));
    }
}

function* changeRoleSaga(action) {
    try {
        yield put(memberActionLoadingChange(true));
        const { payload: { channelId, member } } = action;
        yield call(rootApi.put, `/channels/{APPID}/${channelId}/member-roles`, { members: [member] });
        // yield put(getActiveMembers(channelId, { limit: pageRequestLimit, page: 1 }));
        yield put(changeRoleStore(channelId, member));
        yield put(memberActionLoadingChange(false));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(memberActionLoadingChange(false));
    }
}


export default function* channelMembersWatcherSaga() {
    yield takeEvery(GET_ACTIVE_MEMBERS, getActiveMembersSaga);
    yield takeEvery(GET_BLOCKED_MEMBERS, getBlockedMembersSaga);
    yield takeEvery(ADD_MEMBERS, addMembersSaga);
    yield takeEvery(DELETE_MEMBERS, deleteMembersSaga);
    yield takeEvery(CHANGE_ROLE, changeRoleSaga);
    yield takeEvery(BLOCK_MEMBER, blockMemberSaga);
    yield takeEvery(UNBLOCK_MEMBER, unblockMemberSaga);
    yield takeEvery(RESET_MEMBERS, resetMembersSaga);
}