import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';


function ConditionRoute({ component: Component, redirectLink, condition, ...rest }) {

    // return (
    //     expired ? (
    //         <Route {...rest} render={props => (!condition ? (
    //             <Component {...props}/>
    //         ) : (
    //             <Redirect to={redirectLink}/>
    //         ))}
    //         />
    //     ) : (
    //         <Route {...rest} render={props => (condition ? (
    //             <Component {...props}/>
    //         ) : (
    //             <Redirect to={redirectLink}/>
    //         ))}
    //         />
    //     )
    // );
    return (
        <Route {...rest} render={props => (condition ? (
            <Component {...props}/>
        ) : (
            <Redirect to={redirectLink}/>
        ))}
        />
    );
}

ConditionRoute.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.element,
        PropTypes.func,
        PropTypes.object
    ]).isRequired,
    condition: PropTypes.bool.isRequired,
    redirectLink: PropTypes.string
};


ConditionRoute.defaultProps = {
    redirectLink: '/not-found'
};

export default ConditionRoute;