import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { rootApi } from '../../api';
import { setError } from '../error/actions';
import {
    APPS_CALL,
    APPS_CALL_SUCCESS,
    APP_CREATE_CALL,
    APP_EDIT_CALL,
    APP_DELETE_CALL,
    appAdd,
    appEdit,
    appDelete,
    setSelectedApp,
    toggleAppLoading,
    setSelectedAppPrevious,
    appsFetched
} from './actions';
import { setRequestsLimit } from '../customer/actions';

function* appsCallGen(action) {
    try {
        const { data: apps } = yield call(rootApi.get, '/applications');
        yield put({ type: APPS_CALL_SUCCESS, payload: { apps } });
        // Gets selected app from storage or Set first app as selected app by default
        if (apps.length > 0) {
            let savedAppId = localStorage.getItem('selectedAppId');
            if (!savedAppId) {
                savedAppId = apps[0].app_id;
            }
            yield put(setSelectedApp(savedAppId));
        }
    } catch (error) {
        yield put({ type: APPS_CALL_SUCCESS, payload: { apps: [] } });
        yield put(setError(error, action));
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
    }
    yield put(appsFetched());
}

function* appCreateCallGen(action) {
    try {
        const { payload: { app, region } } = action;
        const res = yield call(rootApi.post, '/applications', { app, region });
        yield put(appAdd(res.data));
        yield put(setSelectedApp(res.data.app_id));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(toggleAppLoading('create', false));
}

function* appEditCallGen(action) {
    try {
        const { payload: { appId, app } } = action;
        yield call(rootApi.put, `/applications/${appId}`, app);
        yield put(appEdit(appId, app));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(toggleAppLoading('edit', false));
}

function* appDeleteCallGen(action) {
    try {
        const { payload: { appId } } = action;
        yield call(rootApi.delete, `/applications/${appId}`);
        yield put(setSelectedAppPrevious(appId));
        yield put(appDelete(appId));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(toggleAppLoading('delete', false));
}

export default function* appsWatcherSaga() {
    yield takeLatest(APPS_CALL, appsCallGen);
    yield takeEvery(APP_CREATE_CALL, appCreateCallGen);
    yield takeEvery(APP_EDIT_CALL, appEditCallGen);
    yield takeEvery(APP_DELETE_CALL, appDeleteCallGen);
}