export const errorDataSelector = store => {
    if (store.errorData && store.errorData.errorList) {
        return store.errorData.errorList;
    }
    return null;
};

export const errorFirstSelector = store => {
    if (store.errorData && store.errorData.errorList) {
        return store.errorData.errorList[Object.keys(store.errorData.errorList)[0]];
    }
    return null;
};

export const errorSelectorById = (store, errorId) => {
    if (store.errorData && store.errorData.errorList) {
        return store.errorData.errorList[errorId];
    }
    return null;
};

export const errorSelectorByIds = (store, idList) => {
    if (store.errorData && store.errorData.errorList) {
        const { errorList } = store.errorData;
        const errorId = Object.keys(errorList).find(err => idList.includes(err));
        return errorId && errorList[errorId];
    }
    return null;
};

// export const errorFirstCodeSelector = store => {
//     const errorList = Object.keys(store.errorData.errorList);
//     if (errorList.length > 0) {
//         return store.errorData.errorList[errorList[0]].code;
//     }
//     return null;
// };


// export const errorFirstCodeSelector = store => {
//     if (store.errorData && Object.keys(store.errorData.errorList).length > 0) {
//         const errorId = Object.keys(store.errorData.errorList)[0];
//         const err = store.errorData.errorList[errorId];
//         return {
//             id: errorId,
//             code: err.code
//         };
//     }
//     return null;
// };