import {
    MARKERS_CALL,
    MARKERS_CALL_SUCCESS,
    MARKERS_CALL_FAIL,
    MARKER_CREATE_CALL,
    MARKER_DELETE_CALL,
    MARKER_ADD,
    MARKER_DELETE,
    MARKER_LOADING_OFF
} from './actions';

const initialState = {
    markers: [],
    loading: false,
    isFetched: false
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };
    const newMarkers = [...newState.markers];

    switch (type) {

        case MARKERS_CALL: {
            newState.markers = [];
            newState.isFetched = false;
            return newState;
        }

        case MARKERS_CALL_SUCCESS: {
            if (payload.markers) {
                newState.markers = [...payload.markers];
                newState.isFetched = true;
                return newState;
            }
            return state;
        }

        case MARKERS_CALL_FAIL: {
            newState.isFetched = true;
            return newState;
        }

        case MARKER_CREATE_CALL:
        case MARKER_DELETE_CALL: {
            newState.loading = true;
            return newState;
        }

        case MARKER_LOADING_OFF: {
            newState.loading = false;
            return newState;
        }

        case MARKER_ADD: {
            if (payload.marker) {
                newState.markers = [...newMarkers, payload.marker];
                newState.loading = false;
                return newState;
            }
            return state;
        }

        case MARKER_DELETE: {
            if (payload.markerName !== undefined) {
                newState.markers = newMarkers.filter(marker => marker.name !== payload.markerName);
                newState.loading = false;
                return newState;
            }
            return state;
        }

        default:
            return state;
    }
};