import {
    API_KEY_ACTION_LOADING_ON,
    API_KEY_ACTION_LOADING_OFF,
    DELETE_AUTH_KEY,
    SET_API_KEYS,
    SET_AUTH_KEY,
    GET_API_KEYS
} from './actions';


const initialState = {
    isFetched: false,
    isLoading: false,
    actionLoading: false,
    authKey: null,
    list: []
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };

    switch (type) {

        case SET_API_KEYS:
            newState.list = [...payload.apiKeys];
            newState.isFetched = true;
            newState.isLoading = false;
            newState.actionLoading = false;
            return newState;

        case GET_API_KEYS:
            newState.isFetched = payload.isSecondCall || false;
            newState.isLoading = true;

            if (!payload.isSecondCall) {
                newState.list = [];
            }
            return newState;

        case SET_AUTH_KEY:
            newState.authKey = payload.authKey;
            return newState;

        case DELETE_AUTH_KEY:
            newState.authKey = null;
            return newState;

        case API_KEY_ACTION_LOADING_ON:
            newState.actionLoading = true;
            return newState;

        case API_KEY_ACTION_LOADING_OFF:
            newState.actionLoading = false;
            newState.isFetched = true;
            return newState;

        default:
            return state;
    }
};