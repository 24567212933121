import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { setError } from '../error/actions';
import { rootApi } from '../../api';
import {
    NOTIFICATIONS_CALL,
    NOTIFICATIONS_CALL_SUCCESS,
    NOTIFICATIONS_CALL_FAIL,
    NOTIFICATION_CREATE_CALL,
    NOTIFICATION_EDIT_CALL,
    NOTIFICATION_DELETE_CALL,
    notificationAdd,
    notificationEdit,
    notificationDelete,
    removeWebhooksLoading,
    NOTIFICATION_DELIVERY_SETTING,
    NOTIFICATION_DELIVERY_SETTINGS_CALL,
    notificationDeliverySettingSuccess, COMPILE_MUSTACHE, setPreviewsData
} from './actions';

import { setRequestsLimit } from '../customer/actions';

function* notificationsCallGen(action) {
    try {
        const res = yield call(rootApi.get, '/push-credentials/{APPID}');
        const notifications = [...res.data];
        yield put({ type: NOTIFICATIONS_CALL_SUCCESS, payload: { notifications } });
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put({ type: NOTIFICATIONS_CALL_FAIL });
    }
}

function* notificationCreateCallGen(action) {
    try {
        const { payload: { notificationFormData } } = action;
        const res = yield call(rootApi.post, '/push-credentials/{APPID}', notificationFormData);
        yield put(notificationAdd(res.data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeWebhooksLoading());
    }
}

function* notificationEditCallGen(action) {
    try {
        const { payload: { id, notificationFormData } } = action;
        const res = yield call(rootApi.put, `/push-credentials/{APPID}/${id}`, notificationFormData);
        yield put(notificationEdit(id, res.data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeWebhooksLoading());
    }
}

function* notificationDeleteCallGen(action) {
    try {
        const { payload: { id } } = action;
        yield call(rootApi.delete, `/push-credentials/{APPID}/${id}`);
        yield put(notificationDelete(id));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeWebhooksLoading());
    }
}

function* notificationDeliverySettingsGen(action) {
    try {
        const { payload: { notificationSettingsData } } = action;
        const res = yield call(rootApi.put, '/push-settings/{APPID}/', { notificationSettingsData });

        yield put(notificationDeliverySettingSuccess(res.data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
}

function* notificationDeliverySettingsCallGen(action) {
    try {
        const res = yield call(rootApi.get, '/push-settings/{APPID}/');

        yield put(notificationDeliverySettingSuccess(res.data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
}

function* compileMustache(action) {
    try {
        const { payload: { mustache, channelType } } = action;

        const res = yield call(rootApi.post, '/push-credentials/{APPID}/render-template', { mustache, channelType });
        if (!mustache.subtitle && mustache.subtitle !== '') {
            delete res.data.subtitle;
        }
        yield put(setPreviewsData(res.data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        console.log('error in compile mustache - ', error.response.data.m);
    }
}


export default function* notificationsWatcherSaga() {
    yield takeLatest(NOTIFICATIONS_CALL, notificationsCallGen);
    yield takeEvery(NOTIFICATION_CREATE_CALL, notificationCreateCallGen);
    yield takeEvery(NOTIFICATION_EDIT_CALL, notificationEditCallGen);
    yield takeEvery(NOTIFICATION_DELETE_CALL, notificationDeleteCallGen);
    yield takeEvery(NOTIFICATION_DELIVERY_SETTING, notificationDeliverySettingsGen);
    yield takeEvery(NOTIFICATION_DELIVERY_SETTINGS_CALL, notificationDeliverySettingsCallGen);
    yield takeEvery(COMPILE_MUSTACHE, compileMustache);
}