import { useState, useLayoutEffect } from 'react';
import useEventListener from '../basic/useEventListener';


const useScreenMedia = mediaQuery => {

    const [inRange, setInRange] = useState(true);

    function handleResize() {
        setInRange(window && window.matchMedia(mediaQuery).matches);
    }

    useEventListener('resize', handleResize);

    useLayoutEffect(() => {
        handleResize();
    }, []);

    return inRange;
};

export default useScreenMedia;