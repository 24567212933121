import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { rootApi } from '../../api';
import { setError } from '../error/actions';
import {
    USAGE_CALL,
    STATISTICS_USERS_CALL,
    STATISTICS_MESSAGES_CALL,
    usageCallSuccess,
    usageFetched,
    statisticsUsersCallSuccess,
    statisticsUsersLoadingToggle,
    statisticsMessagesLoadingToggle,
    statisticsMessagesCallSuccess
} from './actions';

import { setRequestsLimit } from '../customer/actions';

function* usageCallGen(action) {
    try {
        const { data } = yield call(rootApi.get, '/applications/usage');
        yield put(usageCallSuccess(data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(usageFetched());
}

function* usersStatisticCallGen(action) {
    try {
        yield put(statisticsUsersLoadingToggle(true));
        const { payload: { period, startDate, endDate } } = action;
        const options = {
            daily: 'connect-daily',
            monthly: 'connect-monthly'
        };
        const path = options[period];

        if (path) {
            const { data: { data, last_update_date } } = yield call(rootApi.get, `/statistics/{APPID}/users/${path}`, {
                params: {
                    filter: `last_login_at:between:${startDate},${endDate}`
                }
            });
            const newData = data.map(value => {
                const newValue = { ...value };
                if (period === 'daily') {
                    const currentDate = moment(value.day);
                    newValue.day = `${currentDate.format('MMM')}  ${currentDate.format('D')} ${currentDate.format('YYYY')}`;
                }
                if (period === 'monthly') {
                    const currentDate = moment(value.month);
                    newValue.month = `${currentDate.format('MMM')} ${currentDate.format('YYYY')}`;
                }

                return value;
            });
            yield put(statisticsUsersCallSuccess(newData, last_update_date));
        }
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(statisticsUsersLoadingToggle(false));
}

function* messagesStatisticCallGen(action) {
    try {
        yield put(statisticsMessagesLoadingToggle(true));
        const { payload: { period, startDate, endDate } } = action;
        const options = {
            daily: 'send-daily?',
            monthly: 'send-monthly'
        };
        const path = options[period];

        if (path) {
            const { data: { data, last_update_date } } = yield call(rootApi.get, `/statistics/{APPID}/messages/${path}`, {
                params: {
                    filter: `date:between:${startDate},${endDate}`
                }
            });

            const newData = data.map(value => {
                const newValue = { ...value };
                if (period === 'daily') {
                    const currentDate = moment(value.day);
                    newValue.day = `${currentDate.format('MMM')}  ${currentDate.format('D')} ${currentDate.format('YYYY')}`;
                }
                if (period === 'monthly') {
                    const currentDate = moment(value.month);
                    newValue.month = `${currentDate.format('MMM')} ${currentDate.format('YYYY')}`;
                }
                return newValue;
            });
            yield put(statisticsMessagesCallSuccess(newData, last_update_date));
        }
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(statisticsMessagesLoadingToggle(false));
}

export default function* appsWatcherSaga() {
    yield takeLatest(USAGE_CALL, usageCallGen);
    yield takeLatest(STATISTICS_USERS_CALL, usersStatisticCallGen);
    yield takeLatest(STATISTICS_MESSAGES_CALL, messagesStatisticCallGen);
}