export const appsDataSelector = store => {
    if (store.appsData) {
        return store.appsData;
    }
    return null;
};

export const appsSelector = store => {
    if (store.appsData.apps) {
        return store.appsData.apps;
    }
    return null;
};

export const appSelector = (store, appId) => {
    if (store.appsData.apps) {
        return store.appsData.apps.find(app => app.app_id === appId);
    }
    return null;
};

export const appNamesSelector = store => {
    if (store.appsData.apps.length > 0) {
        return store.appsData.apps.map(app => ({
                id: app.app_id,
                name: app.name,
                region: app.region_name
        }));
    }
    return [];
};

export const selectedAppIdSelector = store => store.appsData.selectedAppId;

export const selectedAppNameSelector = store => {
    if (store.appsData.selectedAppId) {
        const selectedApp = store.appsData.apps.find(app => app.app_id === store.appsData.selectedAppId);
        return selectedApp && selectedApp.name;
    }
    return '';
};

export const selectedAppSelector = store => {
    if (store.appsData.selectedAppId) {
        return store.appsData.apps.find(app => app.app_id === store.appsData.selectedAppId);
    }
    return null;
};