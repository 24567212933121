import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export default function RequestsLimit({ limitExpires }) {

    return (
        <RequestLimitContainer>
                <img src="/assets/requestLimit.png" alt="request_limit" className="limit_image"/>
                <RequestsLimitHeader>Too Many Requests</RequestsLimitHeader>
                <RequestsLimitDesc>Slow down! You have reached the limit to request this resource. Please try again in </RequestsLimitDesc>
                <RequestsLimitDesc red>{limitExpires} seconds.</RequestsLimitDesc>
        </RequestLimitContainer>
    );
}

RequestsLimit.propTypes = {
    limitExpires: PropTypes.number
};

RequestsLimit.defaultProps = {
    limitExpires: 0
};

const RequestLimitContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999;
`;

const RequestsLimitHeader = styled.h1`
  font-size: 36px;
  line-height: 46px;
  letter-spacing: -0.005em;
  color: #060A26;
  margin: 32px 0 20px;
`;

const RequestsLimitDesc = styled.p`
  font-family: Inter, sans-serif;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.002em;
  color:  ${props => (props.red ? '#f23f51' : '#242740')};
  margin: 0;
`;