import { ROLE_CREATE_CALL, ROLE_DELETE_CALL, ROLE_EDIT_CALL, ROLES_CALL } from '../store/roles/actions';
import { APP_CREATE_CALL, APP_DELETE_CALL, APP_EDIT_CALL, APPS_CALL } from '../store/apps/actions';
import { PERMISSIONS_CALL } from '../store/permissions/actions';
import { REGIONS_CALL } from '../store/regions/actions';
import {
    CUSTOMER_CALL,
    CUSTOMER_CHANGE_PASSWORD_CALL,
    CUSTOMER_CHANGE_SUBSCRIPTION_CALL,
    CUSTOMER_EDIT_CALL,
    CUSTOMER_DELETE,
    CUSTOMER_PAYMENT_INFO_CALL,
    CUSTOMER_PAYMENT_INVOICES_CALL,
    CUSTOMER_CANCEL_SUBSCRIPTION,
    CUSTOMER_ADD_SUBSCRIPTION,
    CUSTOMER_CHANGE_TRIAL_SUBSCRIPTION_CALL,
    CUSTOMER_SUBSCRIPTION_HISTORY_CALL,
    CUSTOMER_USAGES_IN_PERIOD_CALL,
    COMPANY_NOT_DEFINED
} from '../store/customer/actions';
import {
    USER_CREATE_CALL,
    USER_EDIT_CALL,
    USERS_CALL,
    USER_DELETE_CALL,
    USERS_CALL_FILTER,
    USER_DEACTIVATE_CALL,
    USER_ACTIVATE_CALL
} from '../store/users/actions';
import {
    CREATE_CHANNEL,
    DELETE_CHANNEL,
    EDIT_CHANNEL,
    GET_CHANNEL_TYPES,
    GET_CHANNELS
} from '../store/channels/actions';
import { WEBHOOK_CREATE_CALL, WEBHOOK_DELETE_CALL, WEBHOOK_EDIT_CALL, WEBHOOKS_CALL } from '../store/webhooks/actions';
import { WEBHOOK_EVENTS_CALL } from '../store/webhook-events/actions';
import {
    NOTIFICATION_CREATE_CALL,
    NOTIFICATION_DELETE_CALL,
    NOTIFICATION_DELIVERY_SETTING, NOTIFICATION_DELIVERY_SETTINGS_CALL,
    NOTIFICATION_EDIT_CALL,
    NOTIFICATIONS_CALL
} from '../store/notifications/actions';
import { ADD_MEMBERS, DELETE_MEMBERS, GET_ACTIVE_MEMBERS, GET_BLOCKED_MEMBERS, CHANGE_ROLE } from '../store/channel-members/actions';
import { CREATE_API_KEY, UPDATE_API_KEY, DELETE_API_KEY, GET_API_KEYS } from '../store/api-keys/actions';
import {
    STATISTICS_MESSAGES_CALL,
    STATISTICS_USERS_CALL,
    USAGE_CALL
} from '../store/usage/actions';
import { PLANS_CALL } from '../store/plans/actions';
import {
    CHANGE_MEMBER_ROLE_CALL,
    GET_MEMBER_ROLES, INVITATION_DELETE_CALL,
    INVITE_MEMBER_CALL, MEMBER_DELETE_CALL,
    MEMBERS_DATA_CALL, SET_ACTIVE_PARENT_CUSTOMER_CALL
} from '../store/members/actions';
import {
    REPORTED_CHANNELS_CALL,
    CHANNEL_REPORTS_CALL,
    CHANNEL_REPORTS_MESSAGES_CALL,
    CHANNEL_REPORT_RESOLVE,
    REPORTED_MESSAGES_CALL,
    MESSAGE_REPORT_RESOLVE,
    USER_REPORT_RESOLVE,
    MESSAGE_REPORTS_CALL,
    USER_REPORTS_CALL,
    USER_REPORTS_MESSAGES_CALL
} from '../store/moderation/actions';
import { MARKER_CREATE_CALL, MARKER_DELETE_CALL, MARKERS_CALL } from '../store/markers/actions';


export const errorsConstant = {
    // CUSTOMER
    [CUSTOMER_CALL]: {
        message: 'Failed to load customer data.',
        btnText: 'Refresh',
        isFatal: true
    },
    [CUSTOMER_EDIT_CALL]: {
        message: 'Failed to edit customer data.'
    },
    [CUSTOMER_DELETE]: {
        message: 'Failed to delete customer.'
    },
    [CUSTOMER_SUBSCRIPTION_HISTORY_CALL]: {
        message: 'Failed to get subscription history.',
        isFatal: true
    },
    [CUSTOMER_USAGES_IN_PERIOD_CALL]: {
        message: 'Failed to get customer usages for period.'
    },
    [CUSTOMER_CHANGE_PASSWORD_CALL]: {
        3: {
            message: 'Current password is wrong.',
            btnText: 'Close'
        },
        message: 'Failed to change password.'
    },

    // PAYMENT
    [CUSTOMER_PAYMENT_INFO_CALL]: {
        message: 'Failed to load payment info data.',
        btnText: 'Refresh',
        isFatal: true
    },
    [CUSTOMER_PAYMENT_INVOICES_CALL]: {
        message: 'Failed to load invoices.',
        btnText: 'Refresh',
        isFatal: true
    },
    [CUSTOMER_ADD_SUBSCRIPTION]: {
        message: 'Failed to add subscription.'
    },
    [COMPANY_NOT_DEFINED]: {
        message: 'Customer company is not defined.',
        btnText: 'Close'
    },
    [CUSTOMER_CHANGE_SUBSCRIPTION_CALL]: {
        message: 'Failed to change subscription plan.'
    },
    [CUSTOMER_CHANGE_TRIAL_SUBSCRIPTION_CALL]: {
        message: 'Failed to change trial subscription plan.'
    },
    [CUSTOMER_CANCEL_SUBSCRIPTION]: {
        message: 'Failed to cancel subscription.'
    },

    // APPS
    [APPS_CALL]: {
        message: 'Failed to load applications. Please try again.',
        btnText: 'Refresh',
        isFatal: true
    },
    [APP_CREATE_CALL]: {
        13: {
            message: 'Provided public key doesn’t seem to be in a valid PEM format.',
            btnText: 'Close'
        },
        message: 'Failed to create application.'
    },
    [APP_EDIT_CALL]: {
        13: {
            message: 'Provided public key doesn’t seem to be in a valid PEM format.',
            btnText: 'Close'
        },
        message: 'Failed to edit application.'
    },
    [APP_DELETE_CALL]: {
        message: 'Failed to delete application.'
    },

    // USERS
    [USERS_CALL]: {
        message: 'Failed to load users.',
        btnText: 'Refresh',
        isFatal: true
    },
    [USERS_CALL_FILTER]: {
        message: 'Failed to filter users.'
    },
    [USER_CREATE_CALL]: {
        4: {
            message: 'The id already exists',
            btnText: 'Close'
        },
        message: 'Failed to add user.'
    },
    [USER_EDIT_CALL]: {
        message: 'Failed to edit user.'
    },
    [USER_DELETE_CALL]: {
        message: 'Failed to delete user.'
    },

    [USER_DEACTIVATE_CALL]: {
        message: 'Failed to deactivate user.'
    },

    [USER_ACTIVATE_CALL]: {
        message: 'Failed to activate user.'
    },


    // MEMBERS
    [MEMBERS_DATA_CALL]: {
        message: 'Failed to load members.',
        btnText: 'Refresh',
        isFatal: true
    },
    [GET_MEMBER_ROLES]: {
        message: 'Failed to get member roles.',
        isFatal: true
    },
    [INVITE_MEMBER_CALL]: {
        message: 'Failed to invite member.'
    },
    [CHANGE_MEMBER_ROLE_CALL]: {
        message: 'Failed to change member role.'
    },
    [MEMBER_DELETE_CALL]: {
        message: 'Failed to delete member.'
    },
    [INVITATION_DELETE_CALL]: {
        message: 'Failed to delete invitation.'
    },
    [SET_ACTIVE_PARENT_CUSTOMER_CALL]: {
        message: 'Failed to get parent customer info.'
    },

    // PERMISSIONS
    [PERMISSIONS_CALL]: {
        message: 'Failed to load permissions.',
        btnText: 'Refresh',
        isFatal: true
    },

    // ROLES
    [ROLES_CALL]: {
        message: 'Failed to load roles.',
        btnText: 'Refresh',
        isFatal: true
    },
    [ROLE_CREATE_CALL]: {
        message: 'Failed to create role.'
    },
    [ROLE_EDIT_CALL]: {
        message: 'Failed to edit role.'
    },
    [ROLE_DELETE_CALL]: {
        8: {
            message: err => {
                const urlItems = err.request.responseURL.split('/');
                const name = urlItems[urlItems.length - 1];
                return `Role “${name}” cannot be deleted as it is assigned to one or more channel members.`;
            },
            btnText: 'Close',
            closeOnFail: true
        },
        message: 'Failed to delete role.'
    },

    // REGIONS
    [REGIONS_CALL]: {
        message: 'Failed to load regions.',
        btnText: 'Refresh',
        isFatal: true
    },

    // CHANNELS
    [GET_CHANNELS]: {
        message: 'Failed to load channels.',
        btnText: 'Refresh',
        isFatal: true
    },
    [GET_CHANNEL_TYPES]: {
        message: 'Failed to load channel types.',
        btnText: 'Refresh',
        isFatal: true
    },
    // TODO -> create it
    // [GET_CHANNELS_FILTER]: {
    //     message: 'Failed to filter channels.'
    // },
    [CREATE_CHANNEL]: {
        16: {
            message: 'Missing users or undefined roles.',
            btnText: 'Close'
        },
        message: 'Failed to create channel.'
    },
    [DELETE_CHANNEL]: {
        message: 'Failed to delete channel.'
    },
    [EDIT_CHANNEL]: {
        message: 'Failed to edit channel.'
    },

    // MEMBERS
    [GET_ACTIVE_MEMBERS]: {
        message: 'Failed to load active members.',
        btnText: 'Refresh',
        isFatal: true
    },
    [GET_BLOCKED_MEMBERS]: {
        message: 'Failed to load blocked members.',
        btnText: 'Refresh',
        isFatal: true
    },
    [ADD_MEMBERS]: {
        message: 'Failed to add members.'
    },
    [DELETE_MEMBERS]: {
        message: 'Failed to delete members.'
    },
    [CHANGE_ROLE]: {
        message: 'Failed to change member role.'
    },

    // API KEYS
    [GET_API_KEYS]: {
        message: 'Failed to load Api keys.',
        btnText: 'Refresh',
        isFatal: true
    },
    [CREATE_API_KEY]: {
        message: 'Failed to create Api key.'
    },
    [UPDATE_API_KEY]: {
        message: 'Failed to edits Api key.'
    },
    [DELETE_API_KEY]: {
        message: 'Failed to delete Api key.'
    },

    // WEBHOOKS
    [WEBHOOKS_CALL]: {
        message: 'Failed to load webhooks.',
        btnText: 'Refresh',
        isFatal: true
    },
    [WEBHOOK_CREATE_CALL]: {
        15: {
            message: 'You can\'t enable SSL verification with http, use https instead or disable ssl verification.',
            btnText: 'Close'
        },
        message: 'Failed to create webhook.'
    },
    [WEBHOOK_EDIT_CALL]: {
        message: 'Failed to edit webhook.'
    },
    [WEBHOOK_DELETE_CALL]: {
        message: 'Failed to delete webhook.'
    },

    // WEBHOOKS EVENTS
    [WEBHOOK_EVENTS_CALL]: {
        message: 'Failed to load webhook events.',
        btnText: 'Refresh',
        isFatal: true
    },

    // NOTIFICATIONS
    [NOTIFICATIONS_CALL]: {
        message: 'Failed to load notifications.',
        btnText: 'Refresh',
        isFatal: true
    },
    [NOTIFICATION_CREATE_CALL]: {
        13: {
            message: err => {
                const reqData = JSON.parse(err.config.data.get('data'));
                if (reqData.service_type === 'fcm') {
                    return 'API key is not functioning correctly.';
                }
                return 'Certificate is not functioning correctly.';
            },
            btnText: 'Close'
        },
        14: {
            message: 'Invalid certificate file',
            btnText: 'Close'
        },
        message: 'Failed to create notifications.'
    },
    [NOTIFICATION_EDIT_CALL]: {
        13: {
            message: err => {
                const reqData = JSON.parse(err.config.data.get('data'));
                if (reqData.service_type === 'fcm') {
                    return 'API key is not functioning correctly.';
                }
                return 'Certificate is not functioning correctly.';
            },
            btnText: 'Close'
        },
        14: {
            message: 'Invalid certificate file',
            btnText: 'Close'
        },
        message: 'Failed to edit notifications.'
    },
    [NOTIFICATION_DELETE_CALL]: {
        message: 'Failed to delete notifications.'
    },
    [NOTIFICATION_DELIVERY_SETTING]: {
        message: 'Failed to change notification settings',
        btnText: 'Close'
    },
    [NOTIFICATION_DELIVERY_SETTINGS_CALL]: {
        message: 'Failed to get notification settings',
        btnText: 'Close'
    },

    // USAGE
    [USAGE_CALL]: {
        message: 'Failed to load apps usage data.',
        btnText: 'Refresh',
        isFatal: true
    },
    [STATISTICS_USERS_CALL]: {
        message: 'Failed to load users statistics data.',
        btnText: 'Refresh',
        isFatal: true
    },
    [STATISTICS_MESSAGES_CALL]: {
        message: 'Failed to load messages statistics data.',
        btnText: 'Refresh',
        isFatal: true
    },

    // PLANS
    [PLANS_CALL]: {
        message: 'Failed to load subscription plans.',
        btnText: 'Refresh',
        isFatal: true
    },

    // MODERATION
    [REPORTED_CHANNELS_CALL]: {
        message: 'Failed to load pending reported channels.',
        btnText: 'Refresh'
    },
    [CHANNEL_REPORTS_CALL]: {
        message: 'Failed to load channel reports.'
    },
    [CHANNEL_REPORTS_MESSAGES_CALL]: {
        message: 'Failed to load channel report messages info.'
    },
    [CHANNEL_REPORT_RESOLVE]: {
        message: 'Failed to resolve channel report.'
    },
    [MESSAGE_REPORT_RESOLVE]: {
        message: 'Failed to resolve message report.'
    },
    [USER_REPORT_RESOLVE]: {
        message: 'Failed to resolve user report.'
    },
    [USER_REPORT_RESOLVE]: {
        message: 'Failed to resolve user report.'
    },
    [MESSAGE_REPORTS_CALL]: {
        message: 'Failed to load message reports.'
    },
    [USER_REPORTS_MESSAGES_CALL]: {
        message: 'Failed to load user reports messages.'
    },
    [USER_REPORTS_CALL]: {
        message: 'Failed to load user reports.'
    },
    [REPORTED_MESSAGES_CALL]: {
        message: 'Failed to load pending reported messages.',
        btnText: 'Refresh'
    },

    // MARKERS
    [MARKERS_CALL]: {
        message: 'Failed to load markers.',
        btnText: 'Refresh'
    },
    [MARKER_CREATE_CALL]: {
        message: 'Failed to create markers.',
        btnText: 'Refresh'
    },
    [MARKER_DELETE_CALL]: {
        message: 'Failed to delete markers.',
        btnText: 'Refresh'
    }
};

export const apiErrors = {
    0: 'Internal error',
    1: 'Resource not found',
    2: 'Bad request',
    3: 'Unexpected parameter type(s)',
    4: 'Resource already exists',
    5: 'Invalid parameter value',
    6: 'Not allowed operation',
    // 8: 'Resource is in use and can not to be deleted'

    // 404: 'Page not found',
    500: 'Internal server error',
    502: 'Bad gateway',
    503: 'Service unavailable',

    40101: 'Invalid token',
    40102: 'Expired token',
    40103: 'Token not active',
    40104: 'Unauthorized'
};