import { set } from 'lodash';
import moment from 'moment';
import { userAvatarColors } from '../components/UI/constants';
import { backendDateFormat, HH_MM, MMM_D_YYYY_HH_MM } from './constants-dates';


export const updateInput = handler => value => {
  handler(value);
};

export function updateInputs(handler, valueKey, value) {
  handler({
    [valueKey]: value
  });
}

export function updateInputsLodash(handler, valueKey, value) {
  handler(prevState => ({ ...set(prevState, valueKey, value) }));
}

export function formatDateAgo(date) {
  const dateInHoursAgo = moment().diff(moment(date), 'hours');
  let formattedDate = '';
  if (dateInHoursAgo === 0) {
    const dateInMinutes = moment().diff(moment(date), 'minutes');
    if (dateInMinutes === 0) {
      formattedDate = 'a few seconds';
    } else {
      formattedDate = `${dateInMinutes} ${dateInMinutes > 1 ? 'minutes' : 'minute'}`;
    }
  } else {
    formattedDate = `${dateInHoursAgo} ${dateInHoursAgo > 1 ? 'hours' : 'hour'}`;
  }
  return formattedDate;
}

export const errorHandler = handler => error => {
  if (typeof error === 'string') {
    handler(prevState => prevState.filter(err => err.inputName !== error));
  } else {
    handler(prevState => [...prevState, error]);
  }
};

export function generateUserAvatarColor(id) {

  if (id && id !== '') {
    const indicator = id.hashCode();
    let colorIndex = parseInt(Math.abs(indicator % 10), 10);

    if (colorIndex >= userAvatarColors.length) {
      colorIndex -= userAvatarColors.length;
    }
    return userAvatarColors[colorIndex];
  }

  return null;
}

export function copyToClipboard(copyMe) {
  try {
    const elem = document.createElement('textarea');
    elem.setAttribute('style', `
                position: absolute;
                z-index: -99999999;
                top: -10000px;
                left: -10000px;
                opacity: 0;
            `);
    document.body.appendChild(elem);
    elem.value = copyMe;
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
  } catch (err) {
    console.log('Failed to copy!', err);
  }
}

export function formatDate(d, inFormat = backendDateFormat, outFormat = MMM_D_YYYY_HH_MM) {
  return moment.utc(d, inFormat).local().format(outFormat);
}

export function formatDateToHours(d, inFormat = backendDateFormat, outFormat = HH_MM) {
  return moment.utc(d, inFormat).local().format(outFormat);
}

export function nFormatter(num, digits = 1) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'K' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'G' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

export function bytesToSize(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
}

export function numberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function toCurrency(value, symbol = '$', decimals = 2) {
  if (decimals === 0) {
    return `${symbol}${parseInt(value, 10).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  }
  return `${symbol}${(parseFloat(value)).toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
}

// TODO -> better option for this function
export function getParamFromUrl(url, paramName) {
  const urlObj = new URL(`http://www.example.com${url}`);
  const args = new URLSearchParams(urlObj.search);
  const param = args.get(paramName);

  if (param !== undefined) {
    return param;
  }
  return '';
}

export const getSearchFilterQuery = (searchMethod, searchValue, searchIn) => (searchValue === '' ? '' : [`${searchIn}:${searchMethod}:${searchValue}`]);

export const getFilterQuery = (filterMethod, filterValue, filterIn) => [`${filterIn}:${filterMethod}:${filterValue}`];

export const getFileExtension = filename => {
  const ext = filename.split('.').pop();
  if (ext === filename) return '';
  return ext;
};

export const getCombinedQuery = (filterOptions, searchOptions) => {
  const searchQuery = getSearchFilterQuery(searchOptions.method, searchOptions.value, searchOptions.searchIn);
  const filterQuery = getFilterQuery(filterOptions.method, filterOptions.value, filterOptions.filterIn);

  return [...filterQuery, ...searchQuery];
};

export const getDaysInMonth = (month, year) => new Date(year, month, 0).getDate();

export const formatNumber = num => {
  if (num >= 1e9) {
    return `${num % 1e9 === 0 ? (num / 1e9).toFixed(0) : (num / 1e9).toFixed(1)}B`;
  }
  if (num >= 1e6) {
    return `${num % 1e6 === 0 ? (num / 1e6).toFixed(0) : (num / 1e6).toFixed(1)}M`;
  }
  if (num >= 1e3) {
    return `${num % 1e3 === 0 ? (num / 1e3).toFixed(0) : (num / 1e3).toFixed(1)}K`;
  }
  return num.toString();
};

export const formatLargeText = (text, maxLength) => {
  if (text.length > maxLength) {
    const firstHalf = text.slice(0, maxLength / 2 - 1);
    const secondHalf = text.slice(-(maxLength / 2));
    if (firstHalf.length + secondHalf.length > maxLength) {
      return formatLargeText(firstHalf + secondHalf, maxLength);
    }
    return `${firstHalf}...${secondHalf}`;
  }
  return text;
};
export const formatAppName = (appName, appid) => `${formatLargeText(appName.trim().replace(/\s+/g, '-').toLowerCase(), 30)}-${appid}.pem`;