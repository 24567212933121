import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { setError } from '../error/actions';
import { rootApi } from '../../api';
import {
    WEBHOOKS_CALL,
    WEBHOOKS_CALL_SUCCESS,
    WEBHOOKS_CALL_FAIL,
    WEBHOOK_CREATE_CALL,
    WEBHOOK_EDIT_CALL,
    WEBHOOK_DELETE_CALL,
    webhookAdd,
    webhookEdit,
    webhookDelete,
    removeWebhooksLoading
} from './actions';

import { setRequestsLimit } from '../customer/actions';

function* webhooksCallGen(action) {
    try {
        const res = yield call(rootApi.get, '/webhooks/{APPID}');
        const webhooks = [...res.data];
        yield put({ type: WEBHOOKS_CALL_SUCCESS, payload: { webhooks } });
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put({ type: WEBHOOKS_CALL_FAIL });
    }
}

function* webhookCreateCallGen(action) {
    try {
        const { payload: { webhook } } = action;
        const res = yield call(rootApi.post, '/webhooks/{APPID}', webhook);
        yield put(webhookAdd(res.data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeWebhooksLoading());
    }
}

function* webhookEditCallGen(action) {
    try {
        const { payload: { id, webhook } } = action;
        const res = yield call(rootApi.put, `/webhooks/{APPID}/${id}`, webhook);
        yield put(webhookEdit(id, res.data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeWebhooksLoading());
    }
}

function* webhookDeleteCallGen(action) {
    try {
        const { payload: { id } } = action;
        yield call(rootApi.delete, `/webhooks/{APPID}/${id}`);
        yield put(webhookDelete(id));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeWebhooksLoading());
    }
}


export default function* webhooksWatcherSaga() {
    yield takeLatest(WEBHOOKS_CALL, webhooksCallGen);
    yield takeEvery(WEBHOOK_CREATE_CALL, webhookCreateCallGen);
    yield takeEvery(WEBHOOK_EDIT_CALL, webhookEditCallGen);
    yield takeEvery(WEBHOOK_DELETE_CALL, webhookDeleteCallGen);
}