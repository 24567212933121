import { call, put, takeLatest } from 'redux-saga/effects';
import { setError } from '../error/actions';
import { rootApi } from '../../api';
import {
    PERMISSIONS_CALL,
    PERMISSIONS_CALL_SUCCESS,
    PERMISSIONS_CALL_FAIL
} from './actions';

import { setRequestsLimit } from '../customer/actions';

function* permissionsCallGen(action) {

    try {
        const request = yield call(rootApi.get, '/permissions/{APPID}');
        const permissions = [...request.data];
        yield put({ type: PERMISSIONS_CALL_SUCCESS, payload: { permissions } });
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put({ type: PERMISSIONS_CALL_FAIL });
    }
}

export default function* permissionsWatcherSaga() {
    yield takeLatest(PERMISSIONS_CALL, permissionsCallGen);
}