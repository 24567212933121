import {
    WEBHOOKS_CALL,
    WEBHOOKS_CALL_SUCCESS,
    WEBHOOKS_CALL_FAIL,
    WEBHOOK_CREATE_CALL,
    WEBHOOK_DELETE_CALL,
    WEBHOOK_EDIT_CALL,
    WEBHOOK_ADD,
    WEBHOOK_EDIT,
    WEBHOOK_DELETE,
    WEBHOOK_LOADING_OFF
} from './actions';

const initialState = {
    webhooks: [],
    webhooksAppId: undefined,
    loading: false,
    isFetched: false
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };
    const newWebhooks = [...newState.webhooks];

    switch (type) {

        case WEBHOOKS_CALL: {
            newState.webhooks = [];
            newState.webhooksAppId = undefined;
            newState.isFetched = false;
            return newState;
        }

        case WEBHOOKS_CALL_SUCCESS: {
            if (payload.webhooks) {
                newState.webhooks = [...payload.webhooks];
                newState.webhooksAppId = payload.app_id;
                newState.isFetched = true;
                return newState;
            }
            return state;
        }

        case WEBHOOKS_CALL_FAIL: {
            newState.isFetched = true;
            return newState;
        }

        case WEBHOOK_CREATE_CALL:
        case WEBHOOK_EDIT_CALL:
        case WEBHOOK_DELETE_CALL: {
            newState.loading = true;
            return newState;
        }

        case WEBHOOK_LOADING_OFF: {
            newState.loading = false;
            return newState;
        }

        case WEBHOOK_ADD: {
            if (payload.webhook) {
                newState.webhooks = [...newWebhooks, payload.webhook];
                newState.loading = false;
                return newState;
            }
            return state;
        }

        case WEBHOOK_DELETE: {
            if (payload.id !== undefined) {
                newState.webhooks = newWebhooks.filter(webhook => webhook.id !== payload.id);
                newState.loading = false;
                return newState;
            }
            return state;
        }

        case WEBHOOK_EDIT: {
            if (payload.id !== undefined && payload.webhookNewValues) {
                newState.webhooks = newWebhooks.map(webhook => (webhook.id === payload.id ? { ...webhook, ...payload.webhookNewValues } : webhook));
                newState.loading = false;
                return newState;
            }
            return state;
        }

        default:
            return state;
    }
};