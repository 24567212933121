import { REMOVE_ERROR, REMOVE_ERROR_LIST, SET_ERROR, TOGGLE_ERROR } from './actions';

const initialState = {
    errorList: {}
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };
    const newErrorList = { ...newState.errorList };

    switch (type) {
        case SET_ERROR:
            if (payload && payload.error) {
                const error = {
                    error: payload.error,
                    failedAction: payload.failedAction,
                    cancelAction: payload.cancelAction,
                    isVisible: true
                };
                newState.errorList = {
                    ...newState.errorList,
                    [payload.failedAction.type]: error
                };
            }
            return newState;

        case REMOVE_ERROR:
            if (payload && payload.errorId) {
                delete newErrorList[payload.errorId];
                newState.errorList = newErrorList;
            }
            return newState;

        case TOGGLE_ERROR:
            if (payload && payload.errorId) {
                const newError = { ...newErrorList[payload.errorId] };
                newError.isVisible = !newError.isVisible;
                newErrorList[payload.errorId] = newError;
                newState.errorList = newErrorList;
            }
            return newState;

        case REMOVE_ERROR_LIST:
            newState.errorList = {};
            return newState;

        default:
            return state;
    }
};