import { takeEvery, put, call } from 'redux-saga/effects';
import {
    CREATE_API_KEY,
    DELETE_API_KEY,
    GET_API_KEYS,
    getApiKeys,
    setApiKeys,
    setAuthKey,
    apiKeyActionLoading, UPDATE_API_KEY
} from './actions';
import { setError } from '../error/actions';
import { rootApi } from '../../api';
import { setRequestsLimit } from '../customer/actions';


function* getApiKeysSaga(action) {
    try {
        const res = yield call(rootApi.get, '/api-keys/{APPID}');
        if (res.data) {
            yield put(setApiKeys(res.data));
        }
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(apiKeyActionLoading(false));
}

function* deleteApiKeySaga(action) {
    try {
        yield put(apiKeyActionLoading(true));
        const { payload: { prefix } } = action;
        const res = yield call(rootApi.delete, `/api-keys/{APPID}/${prefix}`);
        if (res) {
            yield put(getApiKeys(true));
        }
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(apiKeyActionLoading(false));
}

function* updateApiKeySaga(action) {
    try {
        yield put(apiKeyActionLoading(true));
        const { payload: { apiKeyData } } = action;
        const res = yield call(rootApi.put, `/api-keys/{APPID}/${apiKeyData.prefix}`, apiKeyData);
        if (res) {
            yield put(getApiKeys(true));
        }
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(apiKeyActionLoading(false));
}

function* createApiKeySaga(action) {
    try {
        yield put(apiKeyActionLoading(true));
        const { payload: { name, ip } } = action;
        const res = yield call(rootApi.post, '/api-keys/{APPID}', { name, white_list_ips: ip !== '' ? [ip] : [] });

        if (res.data) {
            yield put(getApiKeys(true));
            yield put(setAuthKey(res.data.auth_key));
        }
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(apiKeyActionLoading(false));
}

export default function* settingsSaga() {
    yield takeEvery(CREATE_API_KEY, createApiKeySaga);
    yield takeEvery(GET_API_KEYS, getApiKeysSaga);
    yield takeEvery(DELETE_API_KEY, deleteApiKeySaga);
    yield takeEvery(UPDATE_API_KEY, updateApiKeySaga);
}