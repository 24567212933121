import {
    NOTIFICATIONS_CALL,
    NOTIFICATIONS_CALL_SUCCESS,
    NOTIFICATIONS_CALL_FAIL,
    NOTIFICATION_CREATE_CALL,
    NOTIFICATION_DELETE_CALL,
    NOTIFICATION_EDIT_CALL,
    NOTIFICATION_ADD,
    NOTIFICATION_EDIT,
    NOTIFICATION_DELETE,
    NOTIFICATION_LOADING_OFF,
    NOTIFICATION_DELIVERY_SETTINGS_CALL_SUCCESS,
    NOTIFICATION_DELIVERY_SETTING,
    NOTIFICATION_DELIVERY_SETTINGS_CALL,
    SET_PREVIEWS_DATA
} from './actions';

const initialState = {
    notifications: [],
    notificationsSettings: {},
    notificationsAppId: undefined,
    loading: false,
    isFetched: false,
    previewsData: {}
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };
    const newWebhooks = [...newState.notifications];

    switch (type) {

        case NOTIFICATIONS_CALL: {
            newState.notifications = [];
            newState.notificationsAppId = undefined;
            newState.isFetched = false;
            return newState;
        }

        case NOTIFICATIONS_CALL_SUCCESS: {
            if (payload.notifications) {
                newState.notifications = [...payload.notifications];
                newState.notificationsAppId = payload.app_id;
                newState.isFetched = true;
                return newState;
            }
            return state;
        }

        case NOTIFICATIONS_CALL_FAIL: {
            newState.isFetched = true;
            return newState;
        }

        case NOTIFICATION_CREATE_CALL:
        case NOTIFICATION_EDIT_CALL:
        case NOTIFICATION_DELETE_CALL:
        case NOTIFICATION_DELIVERY_SETTING:
        case NOTIFICATION_DELIVERY_SETTINGS_CALL: {
            newState.loading = true;
            return newState;
        }

        case NOTIFICATION_LOADING_OFF: {
            newState.loading = false;
            return newState;
        }

        case NOTIFICATION_ADD: {
            if (payload.notification) {
                newState.notifications = [...newWebhooks, payload.notification];
                newState.loading = false;
                return newState;
            }
            return state;
        }

        case NOTIFICATION_DELETE: {
            if (payload.id !== undefined) {
                newState.notifications = newWebhooks.filter(notification => notification.id !== payload.id);
                newState.loading = false;
                return newState;
            }
            return state;
        }

        case NOTIFICATION_EDIT: {
            if (payload.id !== undefined && payload.notificationNewValues) {
                newState.notifications = newWebhooks.map(notification => (notification.id === payload.id
                    ? { ...notification, ...payload.notificationNewValues } : notification));
                newState.loading = false;
                return newState;
            }
            return state;
        }

        case NOTIFICATION_DELIVERY_SETTINGS_CALL_SUCCESS: {
            newState.loading = false;
            if (payload.settingsData) {
                newState.notificationsSettings = payload.settingsData;
                return newState;
            }
            return state;
        }

        case SET_PREVIEWS_DATA: {
            if (payload.previewsData) {
                newState.previewsData = payload.previewsData;
                return newState;
            }
            return state;
        }

        default:
            return state;
    }
};