export const REPORTED_CHANNELS_CALL = 'REPORTED_CHANNELS_CALL';
export const REPORTED_CHANNELS_CALL_SUCCESS = 'REPORTED_CHANNELS_CALL_SUCCESS';
export const CHANNEL_REPORTS_CALL = 'CHANNEL_REPORTS_CALL';
export const RESET_CHANNEL_REPORTS_CALL = 'RESET_CHANNEL_REPORTS_CALL';
export const CHANNEL_REPORTS_CALL_SUCCESS = 'CHANNEL_REPORTS_CALL_SUCCESS';
export const CHANNEL_REPORTS_MESSAGES_CALL = 'CHANNEL_REPORTS_MESSAGES_CALL';
export const CHANNEL_REPORTS_MESSAGES_CALL_SUCCESS = 'REPORTED_CHANNEL_INFO_CALL_SUCCESS';
export const CHANNEL_REPORT_RESOLVE = 'CHANNEL_REPORT_RESOLVE';

export const REPORTED_MESSAGES_CALL = 'REPORTED_MESSAGES_CALL';
export const MESSAGE_REPORTS_CALL = 'MESSAGE_REPORTS_CALL';
export const REPORTED_MESSAGES_CALL_SUCCESS = 'REPORTED_MESSAGES_CALL_SUCCESS';
export const MESSAGE_REPORT_RESOLVE = 'MESSAGE_REPORT_RESOLVE';
export const USER_REPORTS_MESSAGES_CALL = 'USER_REPORTS_MESSAGES_CALL';
export const USER_REPORTS_MESSAGES_CALL_SUCCESS = 'USER_REPORTS_MESSAGES_CALL_SUCCESS';

export const REPORTED_USERS_CALL = 'REPORTED_USERS_CALL';
export const USER_REPORTS_CALL = 'USER_REPORTS_CALL';
export const REPORTED_USERS_CALL_SUCCESS = 'REPORTED_USERS_CALL_SUCCESS';
export const USER_REPORT_RESOLVE = 'USER_REPORT_RESOLVE';

export const MODERATION_LOADING_OFF = 'MODERATION_LOADING_OFF';
export const MODERATION_CALL_FAIL = 'MODERATION_CALL_FAIL';

// ** Channels Reports

export function reportedChannelsCall(queryParams, reportsState) {
    return { type: REPORTED_CHANNELS_CALL, payload: { queryParams, reportsState } };
}

export function reportedChannelsCallSuccess(reportedChannelsData, reportsState) {
    return { type: REPORTED_CHANNELS_CALL_SUCCESS, payload: { reportedChannelsData, reportsState } };
}

export function channelReportsCall(queryParams) {
    return { type: CHANNEL_REPORTS_CALL, payload: { queryParams } };
}

export function resetChannelReportsStore() {
    return { type: RESET_CHANNEL_REPORTS_CALL };
}

export function channelReportsCallSuccess(reportInfo) {
    return { type: CHANNEL_REPORTS_CALL_SUCCESS, payload: { reportInfo } };
}

export function channelReportsMessagesCall(queryParams) {
    return { type: CHANNEL_REPORTS_MESSAGES_CALL, payload: { queryParams } };
}

export function channelReportsMessagesCallSuccess(messagesData) {
    return { type: CHANNEL_REPORTS_MESSAGES_CALL_SUCCESS, payload: { messagesData } };
}

export function channelReportResolve(params) {
    return { type: CHANNEL_REPORT_RESOLVE, payload: { params } };
}


// ** Messages Reports

export function reportedMessagesCall(queryParams, reportsState) {
    return { type: REPORTED_MESSAGES_CALL, payload: { queryParams, reportsState } };
}

export function messageReportsCall(queryParams) {
    return { type: MESSAGE_REPORTS_CALL, payload: { queryParams } };
}

export function reportedMessagesCallSuccess(reportedMessagesData, reportsState) {
    return { type: REPORTED_MESSAGES_CALL_SUCCESS, payload: { reportedMessagesData, reportsState } };
}

export function messageReportResolve(params) {
    return { type: MESSAGE_REPORT_RESOLVE, payload: { params } };
}


// ** Users Reports

export function reportedUsersCall(queryParams, reportsState) {
    return { type: REPORTED_USERS_CALL, payload: { queryParams, reportsState } };
}

export function userReportsCall(queryParams) {
    return { type: USER_REPORTS_CALL, payload: { queryParams } };
}

export function reportedUsersCallSuccess(reportedUsersData, reportsState) {
    return { type: REPORTED_USERS_CALL_SUCCESS, payload: { reportedUsersData, reportsState } };
}

export function userReportsMessagesCall(queryParams) {
    return { type: USER_REPORTS_MESSAGES_CALL, payload: { queryParams } };
}

export function userReportsMessagesCallSuccess(messagesData) {
    return { type: USER_REPORTS_MESSAGES_CALL_SUCCESS, payload: { messagesData } };
}

export function userReportResolve(params) {
    return { type: USER_REPORT_RESOLVE, payload: { params } };
}


export function removeModerationLoading() {
    return { type: MODERATION_LOADING_OFF };
}