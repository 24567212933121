export const getCookie = cookieName => {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

export const setCookie = (cookieName, cookieValue) => {
    console.info('setCookie: ', `${cookieName}=${cookieValue}; expires=Fri, 27 Dec 2030 23:59:59 GMT; domain=.sceyt.com; path=/;`);
    document.cookie = `${cookieName}=${cookieValue}; expires=Fri, 27 Dec 2030 23:59:59 GMT; domain=.sceyt.com; path=/;`;
};