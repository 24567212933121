import {
    USAGE_CALL,
    USAGE_CALL_SUCCESS,
    USAGE_CALL_FETCHED,
    STATISTICS_USERS_CALL_SUCCESS,
    TOGGLE_USER_STATISTICS_LOADING,
    STATISTICS_MESSAGES_CALL_SUCCESS,
    TOGGLE_MESSAGES_STATISTICS_LOADING
} from './actions';


const initialState = {
    data: [],
    totalActiveUsersCount: 0,
    totalFileStorage: 0,
    isFetched: false,

    statisticsUsersData: [],
    statisticsUsersDataLastUpdate: null,
    statisticsUsersLoading: true,

    statisticsMessagesData: [],
    statisticsMessagesDataLastUpdate: null,
    statisticsMessagesLoading: false
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };

    switch (type) {

        case USAGE_CALL: {
            newState.isFetched = false;
            return newState;
        }

        case USAGE_CALL_SUCCESS: {
            if (payload.data) {
                newState.data = payload.data;
                newState.totalActiveUsersCount = payload.data.reduce((sum, appUsage) => sum + appUsage.usersCount.active_count, 0);
                newState.totalFileStorage = payload.data.reduce((sum, appUsage) => sum + appUsage.storageUsage.total_bytes, 0);
                return newState;
            }
            return state;
        }

        case USAGE_CALL_FETCHED: {
            newState.isFetched = true;
            return newState;
        }

        case STATISTICS_USERS_CALL_SUCCESS: {
            if (payload.data) {
                newState.statisticsUsersData = payload.data;
                newState.statisticsUsersDataLastUpdate = payload.lastUpdateDate;
                return newState;
            }
            return state;
        }

        case TOGGLE_USER_STATISTICS_LOADING: {
            if (payload.state !== undefined) {
                newState.statisticsUsersLoading = payload.state;
                return newState;
            }
            return state;
        }

        case STATISTICS_MESSAGES_CALL_SUCCESS: {
            if (payload.data) {
                newState.statisticsMessagesData = payload.data;
                newState.statisticsMessagesDataLastUpdate = payload.lastUpdateDate;
                return newState;
            }
            return state;
        }

        case TOGGLE_MESSAGES_STATISTICS_LOADING: {
            if (payload.state !== undefined) {
                newState.statisticsMessagesLoading = payload.state;
                return newState;
            }
            return state;
        }

        default:
            return state;
    }
};