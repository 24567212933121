export const QUERY_PARAMS_UPDATE = 'QUERY_PARAMS_UPDATE';
export const QUERY_CHANNEL_PARAMS_UPDATE = 'QUERY_CHANNEL_PARAMS_UPDATE';

export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';

export function queryParamsUpdate(direction, params) {
    return { type: QUERY_PARAMS_UPDATE, payload: { direction, params } };
}

export function queryChannelsParamsUpdate(direction, params) {
    return { type: QUERY_CHANNEL_PARAMS_UPDATE, payload: { direction, params } };
}