export const appInputsStructure = {
    public_key: {
        label: 'Public key',
        labelNote: 'Generate Public and Private Keys or insert your own Public Key.<br><a href="/" target="_blank">Learn more.</a>',
        type: 'textarea',
        validation: {
            presence: true
        },
        spellCheck: false
    },
    name: {
        label: 'Name*',
        placeholder: 'Enter your application name',
        validation: {
            presence: true,
            length: {
                maximum: 128
            }
        }
    },
    description: {
        label: 'Description',
        placeholder: 'Enter short description'
    }
};

export default appInputsStructure;