import React from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { togglePopup } from '../../../../store/expired/actions';
import { expiredDataSelector } from '../../../../store/expired/selector';
import { routeNames, subRoutNames } from '../../../../helpers/constants';
import { Col, Popup, PopupContainer, StyledCloseSvg } from '../../../UI';
import { ReactComponent as ExpiredSvg } from '../../../../assets/svg/expired.svg';


export default function PopupExpired({ subscriptionExpired }) {

    const dispatch = useDispatch();
    const history = useHistory();
    const { isVisible } = useSelector(expiredDataSelector, isEqual);

    function closePopup() {
        dispatch(togglePopup(false));
    }

    function redirectToBilling() {
        closePopup();
        history.push(`/${routeNames.BILLING}/${subRoutNames.SUBSCRIPTION}/${subRoutNames.PLANS}`);
    }

    return (
        subscriptionExpired && isVisible && (
            <PopupContainer>
                <Popup width="460px" height="360px">
                    <StyledCloseSvg onClick={closePopup}/>
                    <Col align="center" padding="10px 50px">
                        <ExpiredImage/>
                        <Title>Your trial has expired</Title>
                        <Description marginTop="15px">Unlock all Sceyt’s features by upgrading your plan!</Description>
                        <button type="button" className="button blue filled big" onClick={redirectToBilling}>
                            Upgrade now!
                        </button>
                    </Col>
                </Popup>
            </PopupContainer>
        )
    );
}

PopupExpired.propTypes = {
    subscriptionExpired: PropTypes.bool.isRequired
};
const Title = styled.p`
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    text-align: center;
`;

const Description = styled.p`
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    margin: 0 0 30px 0;
    text-align: center;
`;

const ExpiredImage = styled(ExpiredSvg)`
    max-width: 100%;
`;