import {
    CHANNEL_REPORT_RESOLVE,
    CHANNEL_REPORTS_CALL,
    CHANNEL_REPORTS_CALL_SUCCESS,
    CHANNEL_REPORTS_MESSAGES_CALL,
    CHANNEL_REPORTS_MESSAGES_CALL_SUCCESS,
    MESSAGE_REPORT_RESOLVE,
    MESSAGE_REPORTS_CALL,
    MODERATION_LOADING_OFF,
    REPORTED_CHANNELS_CALL,
    REPORTED_CHANNELS_CALL_SUCCESS,
    REPORTED_MESSAGES_CALL,
    REPORTED_MESSAGES_CALL_SUCCESS,
    REPORTED_USERS_CALL, REPORTED_USERS_CALL_SUCCESS,
    RESET_CHANNEL_REPORTS_CALL,
    USER_REPORT_RESOLVE,
    USER_REPORTS_CALL,
    USER_REPORTS_MESSAGES_CALL,
    USER_REPORTS_MESSAGES_CALL_SUCCESS
} from './actions';
import { reportsDataState } from '../../helpers/constants';


const initialState = {
    count: 0,
    loading: false,
    isFetched: false,

    pendingChannelsReports: [],
    approvedChannelsReports: [],
    rejectedChannelsReports: [],

    pendingMessagesReports: [],
    approvedMessagesReports: [],
    rejectedMessagesReports: [],

    pendingUsersReports: [],
    approvedUsersReports: [],
    rejectedUsersReports: [],
    userReportsMessages: [],

    channelReports: [],
    channelReportsNext: '',
    channelReportsCount: [],
    channelReportsMessages: [],
    channelReportsMessagesNext: '',
    channelReportsIsFetched: false
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };

    switch (type) {

        case MODERATION_LOADING_OFF: {
            newState.loading = false;
            newState.isFetched = true;
            return newState;
        }

        case CHANNEL_REPORT_RESOLVE:
        case MESSAGE_REPORT_RESOLVE:
        case USER_REPORT_RESOLVE:
        case REPORTED_USERS_CALL:
        case REPORTED_MESSAGES_CALL:
        case REPORTED_CHANNELS_CALL: {
            newState.loading = true;
            return newState;
        }

        case REPORTED_CHANNELS_CALL_SUCCESS: {
            const { reportedChannelsData: { count, reportedChannels }, reportsState } = payload;
            if (reportsState === reportsDataState.PENDING) {
                newState.pendingChannelsReports = reportedChannels;
            } else if (reportsState === reportsDataState.APPROVED) {
                newState.approvedChannelsReports = reportedChannels;
            } else if (reportsState === reportsDataState.REJECTED) {
                newState.rejectedChannelsReports = reportedChannels;
            }
            newState.count = count;
            return newState;
        }

        case CHANNEL_REPORTS_CALL:
        case MESSAGE_REPORTS_CALL:
        case USER_REPORTS_CALL:
        case USER_REPORTS_MESSAGES_CALL:
        case CHANNEL_REPORTS_MESSAGES_CALL: {
            newState.channelReportsIsFetched = false;
            return newState;
        }

        case CHANNEL_REPORTS_CALL_SUCCESS: {
            const { reportInfo } = payload;
            newState.channelReportsCount = reportInfo.count;
            newState.channelReports = [...newState.channelReports, ...reportInfo.reports];
            newState.channelReportsNext = reportInfo.next;
            newState.channelReportsIsFetched = true;
            return newState;
        }

        case RESET_CHANNEL_REPORTS_CALL: {
            newState.channelReportsCount = 0;
            newState.channelReports = [];
            newState.channelReportsNext = '';
            newState.channelReportsMessagesNext = '';

            newState.channelReportsMessages = [];
            newState.userReportsMessages = [];
            return newState;
        }

        case CHANNEL_REPORTS_MESSAGES_CALL_SUCCESS: {
            const { messagesData } = payload;
            newState.channelReportsMessages = [...newState.channelReportsMessages, ...messagesData.messages];
            newState.channelReportsMessagesNext = messagesData.next;
            newState.channelReportsIsFetched = true;
            return newState;
        }

        case REPORTED_MESSAGES_CALL_SUCCESS: {
            const { reportedMessagesData: { reportedMessages }, reportsState } = payload;

            if (reportsState === reportsDataState.PENDING) {
                newState.pendingMessagesReports = reportedMessages;
            } else if (reportsState === reportsDataState.APPROVED) {
                newState.approvedMessagesReports = reportedMessages;
            } else if (reportsState === reportsDataState.REJECTED) {
                newState.rejectedMessagesReports = reportedMessages;
            }
            return newState;
        }

        case REPORTED_USERS_CALL_SUCCESS: {
            const { reportedUsersData: { reportedUsers }, reportsState } = payload;

            if (reportsState === reportsDataState.PENDING) {
                newState.pendingUsersReports = reportedUsers;
            } else if (reportsState === reportsDataState.APPROVED) {
                newState.approvedUsersReports = reportedUsers;
            } else if (reportsState === reportsDataState.REJECTED) {
                newState.rejectedUsersReports = reportedUsers;
            }
            return newState;
        }

        case USER_REPORTS_MESSAGES_CALL_SUCCESS: {
            const { messagesData } = payload;
            newState.userReportsMessages = [...newState.userReportsMessages, ...messagesData.messages];
            newState.channelReportsMessagesNext = messagesData.next;
            newState.channelReportsIsFetched = true;
            return newState;
        }
        default:
            return state;
    }
};