import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import { APP_CREATE_CALL, appCreateCall } from '../../../../store/apps/actions';
import { regionsDataSelector } from '../../../../store/regions/selectors';
import { useDidUpdate, useStateComplex } from '../../../../hooks';
import { generateRSAKeyPair } from '../../../../helpers/RSA-keygen';
import { errorHandler, updateInputs } from '../../../../helpers/utils';
import { appsDataSelector } from '../../../../store/apps/selectors';
import { errorSelectorByIds } from '../../../../store/error/selector';
import Field from '../../../common/field';
import Loader from '../../../complex/loader';
import ErrorWrapper from '../../../complex/error-wrapper';
import { REGIONS_CALL } from '../../../../store/regions/actions';
import { appInputsStructure } from '../../../../helpers/fields';
import App from '../../../../helpers/create-models/App';
import {
  Col,
  ButtonBlock,
  Popup,
  PopupContainer,
  PopupName,
  StyledCloseSvg
} from '../../../UI';
import CreateAppSuccess from './success';


export default function CreateApp({ togglePopupShow }) {

  const dispatch = useDispatch();
  const { regions, isFetched } = useSelector(regionsDataSelector, isEqual);
  const cleanApp = new App();
  const resError = useSelector(store => errorSelectorByIds(store, [APP_CREATE_CALL]), isEqual);
  const { createLoading, apps } = useSelector(appsDataSelector, isEqual);

  const [errors, setErrors] = useState([]);
  const [appValues, setAppValues] = useStateComplex(cleanApp);
  const [appPrivateKey, setAppPrivateKey] = useState();
  const [lastStep, setLastStep] = useState(false);
  const [waitForPBKey, setWaitForPBKey] = useState(false);

  async function generateKeys() {
    setWaitForPBKey(true);
    const keys = await generateRSAKeyPair();
    updateInputs(setAppValues, 'public_key', keys.publicKey);
    setAppPrivateKey(keys.privateKey);
  }

  function handleAppCreate() {
    if (errors.length === 0) {
      dispatch(appCreateCall(appValues, regions[0]));
    }
  }

  const FiledBlock = useCallback(inputName => {
    const input = appInputsStructure[inputName];

    if (input && appValues[inputName] !== undefined) {
      return (
        <Field
          label={input.label}
          labelNote={input.labelNote}
          validation={input.validation}
          onChange={newValue => updateInputs(setAppValues, inputName, newValue)}
          onError={errorHandler(setErrors)}
          input={{
            name: inputName,
            value: appValues[inputName],
            type: input.type,
            placeholder: input.placeholder,
            // className: input.type === 'textarea' ? 'monospace f-size-13' : '',
            ...input.spellCheck !== undefined && { spellCheck: input.spellCheck }
          }}
        />
      );
    }
    return null;
  }, [appValues]);

  useDidUpdate(() => {
    if (!createLoading) {
      if (resError) {
        if (!resError.isVisible) {
          togglePopupShow();
        }
      } else if (apps[0].name === appValues.name) setLastStep(true);
    }
  }, [createLoading, apps]);

  useDidUpdate(() => {
    if (waitForPBKey && appValues.public_key) {
      setWaitForPBKey(false);
      handleAppCreate();
    }
  }, [waitForPBKey, appValues]);

  return (
    <PopupContainer>
      <Popup minWidth="585px" isLoading={createLoading}>
        {!isFetched ? (
          <Loader/>
        ) : (
          <ErrorWrapper listenToErrors={[REGIONS_CALL]}>
            {lastStep ? (
             <CreateAppSuccess togglePopupShow={togglePopupShow} privateKey={appPrivateKey} createdApp={apps[0]} />
            ) : (
              <>
                <StyledCloseSvg onClick={togglePopupShow}/>
                <PopupName marginBottom="20px">New Application</PopupName>
                <CreateAppDesc>Enter a name and description to set up your application.</CreateAppDesc>
                <FieldsWrapper>
               {/*   {FiledBlock('public_key')}

                  <Row justify="flex-end">
                    <button type="button" className="button blue-dark filled" onClick={generateKeys}>
                      Generate Keys
                    </button>
                  </Row>*/}

                  {FiledBlock('name')}
                  {FiledBlock('description')}

                  {/*   <Field
                                label="Region"
                                labelNote="Choose a region close to your end-users. After creating the application, there is no way to change the region."
                                validation={{ presence: true }}
                                onChange={val => setAppRegion(regions.find(reg => reg.name === val))}
                                onError={errorHandler(setErrors)}
                                input={{
                                    type: 'select-custom',
                                    placeholder: 'Select region',
                                    name: 'app-region',
                                    value: appRegion.name,
                                    options: regions.map(reg => reg.name)
                                }}
                            />*/}

                </FieldsWrapper>

                <ButtonBlock>
                  <button type="button" className="button gray" onClick={togglePopupShow}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`button blue filled ${createLoading ? 'loading' : ''}`}
                    onClick={generateKeys}
                    disabled={errors.length > 0}
                  >
                    Create
                  </button>
                </ButtonBlock>
              </>
            )}

          </ErrorWrapper>
        )}
      </Popup>
    </PopupContainer>
  );
}

CreateApp.propTypes = {
  togglePopupShow: PropTypes.func.isRequired
};

const FieldsWrapper = styled(Col)`
    textarea {
        font-family: monospace !important;
        font-size: 13px;
        height: 130px;
    }
`;

const CreateAppDesc = styled.p`
    margin: 0 0 20px;
    font-size: 14px;
    line-height: 22px;
`;