export const WEBHOOKS_CALL = 'WEBHOOKS_CALL';
export const WEBHOOKS_CALL_SUCCESS = 'WEBHOOKS_CALL_SUCCESS';
export const WEBHOOKS_CALL_FAIL = 'WEBHOOKS_CALL_FAIL';

export const WEBHOOK_CREATE_CALL = 'WEBHOOK_CREATE_CALL';
export const WEBHOOK_DELETE_CALL = 'WEBHOOK_DELETE_CALL';
export const WEBHOOK_EDIT_CALL = 'WEBHOOK_EDIT_CALL';

export const WEBHOOK_ADD = 'WEBHOOK_ADD';
export const WEBHOOK_DELETE = 'WEBHOOK_DELETE';
export const WEBHOOK_EDIT = 'WEBHOOK_EDIT';

export const WEBHOOK_LOADING_OFF = 'WEBHOOK_LOADING_OFF';


export function webhooksCall() {
    return { type: WEBHOOKS_CALL };
}

export function webhookCreateCall(webhook) {
    return { type: WEBHOOK_CREATE_CALL, payload: { webhook } };
}

export function webhookEditCall(id, webhook) {
    return { type: WEBHOOK_EDIT_CALL, payload: { id, webhook } };
}

export function webhookDeleteCall(id) {
    return { type: WEBHOOK_DELETE_CALL, payload: { id } };
}


export function webhookAdd(webhook) {
    return { type: WEBHOOK_ADD, payload: { webhook } };
}

export function webhookEdit(id, webhookNewValues) {
    return { type: WEBHOOK_EDIT, payload: { id, webhookNewValues } };
}

export function webhookDelete(id) {
    return { type: WEBHOOK_DELETE, payload: { id } };
}

export function removeWebhooksLoading() {
    return { type: WEBHOOK_LOADING_OFF };
}