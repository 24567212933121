const livechatInputsStructure = {
    email: {
        label: 'Email',
        type: 'text',
        placeholder: 'Enter your email',
        validation: {
            presence: true,
            format: {
                pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: 'Please enter a valid email address'
            }
        }
    }
};

export default livechatInputsStructure;