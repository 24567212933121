
export const CREATE_CHANNEL = 'CREATE_CHANNEL';
export const DELETE_CHANNEL = 'DELETE_CHANNEL';
export const EDIT_CHANNEL = 'EDIT_CHANNEL';

export const GET_CHANNEL_TYPES = 'GET_CHANNEL_TYPES';
export const SET_CHANNEL_TYPES = 'SET_CHANNEL_TYPES';

export const GET_CHANNELS = 'GET_CHANNELS';
export const SET_CHANNELS = 'SET_CHANNELS';

export const RESET_CHANNELS = 'RESET_CHANNELS';
export const RESET_CHANNELS_STORE = 'RESET_CHANNELS_STORE';

export const CHANNELS_CHANGE_LOADING = 'CHANNELS_CHANGE_LOADING';
export const CHANNELS_CHANGE_ACTION_LOADING = 'CHANNELS_CHANGE_ACTION_LOADING';

export function createChannel(channel, isFetch) {
    return { type: CREATE_CHANNEL, payload: { channel, isFetch } };
}

export function getChannelTypes() {
    return { type: GET_CHANNEL_TYPES, payload: {} };
}


export function setChannelTypes(channelTypes) {
    return { type: SET_CHANNEL_TYPES, payload: { channelTypes } };
}

export function getChannels(queries) {
    return { type: GET_CHANNELS, payload: { queries } };
}

export function setChannels(channels) {
    return { type: SET_CHANNELS, payload: { channels } };
}

export function editChannel(channelId, channelToEdit, avatarFile, isFetch) {
    return { type: EDIT_CHANNEL, payload: { channelId, channelToEdit, avatarFile, isFetch } };
}

export function deleteChannel(channelId, filter) {
    return { type: DELETE_CHANNEL, payload: { channelId, filter } };
}

export function channelsChangeLoading(status) {
    return { type: CHANNELS_CHANGE_LOADING, payload: { status } };
}

export function channelsChangeActionLoading(status) {
    return { type: CHANNELS_CHANGE_ACTION_LOADING, payload: { status } };
}

export function resetChannels(nextGetQueries) {
    return { type: RESET_CHANNELS, payload: { queries: nextGetQueries } };
}

export function resetChannelsStore() {
    return { type: RESET_CHANNELS_STORE };
}