import axios from 'axios';
import store from '../store';


const rootApi = axios.create({
    baseURL: '/api',
    timeout: 30000,
    xsrfCookieName: 'csrfToken', // default: XSRF-TOKEN
    xsrfHeaderName: 'X-XSRF-TOKEN', // default: X-XSRF-TOKEN
    headers: {
        // 'Content-Type': 'application/json'
        // 'Content-Type': 'multipart/form-data'
        // 'Authorization': '',
    }
});

rootApi.interceptors.request.use(request => {
    const { selectedAppId } = store.getState().appsData;

    // Replace {APPID} with selected app id
    if (request.url.includes('{APPID}')) {
        const modifiedRequest = { ...request };
        modifiedRequest.url = modifiedRequest.url.replace('{APPID}', selectedAppId);
        return modifiedRequest;
    }
    return request;
}, error => Promise.reject(error));

rootApi.interceptors.response.use(response => response, error => {
    if (!axios.isCancel(error) && error.response) {
        // some error happend in call

        // TODO -> expired listener
        if (error && error.response && error.response.data) {
            if (error.response.status === 401 || error.response.data.c === 40104 || error.response.data.c === 40301) {
                window.location.href = '/logout';
            }
        }

    }
    return Promise.reject(error);
});

export default rootApi;