import countryList from '../constants-countries';


export const customerInputsStructure = {
    first_name: {
        label: 'First Name',
        type: 'text',
        validation: {
            presence: true,
            length: {
                maximum: 50
            }
        }
    },
    last_name: {
        label: 'Last Name',
        type: 'text',
        validation: {
            presence: true,
            length: {
                maximum: 50
            }
        }
    },
    country: {
        label: 'Country',
        type: 'select-custom',
        options: countryList,
        validation: {
            length: {
                maximum: 50
            }
        }
    },
    company: {
        label: 'Company name',
        type: 'text',
        validation: {
            length: {
                maximum: 50
            }
        }
    },
    oldPassword: {
        label: 'Current password',
        type: 'password',
        validation: {
            presence: true,
            length: {
                maximum: 50
            }
        }
    },
    newPassword: {
        label: 'New password',
        type: 'password',
        validation: {
            presence: true,
            length: {
                minimum: 8,
                maximum: 50
            },
            format: {
                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[-!$@#%^&*()_+|~=`{}\[\]:";'<>?,.\/\\]).{8,}$/,
                message: '^Password must include a-z, A-Z, 0-9, and symbol'
            }
        }
    }
};

export default customerInputsStructure;