import { call, put, takeLatest } from 'redux-saga/effects';

import { setError } from '../error/actions';
import { rootApi } from '../../api';
import {
    CUSTOMER_CALL,
    CUSTOMER_CALL_SUCCESS,
    CUSTOMER_CALL_FAIL,
    CUSTOMER_EDIT_CALL,
    CUSTOMER_CHANGE_PASSWORD_CALL,
    customerEdit,
    removeCustomerLoading,
    setCustomerCode,
    CUSTOMER_PAYMENT_INFO_CALL,
    customerPaymentInfoResponse,
    SUBSCRIPTION_CANCELED,
    customerPaymentInvoicesResponse,
    CUSTOMER_PAYMENT_INVOICES_CALL,
    CUSTOMER_CHANGE_SUBSCRIPTION_CALL,
    changeSubscriptionResponse,
    CUSTOMER_DELETE,
    CUSTOMER_CHANGE_TRIAL_SUBSCRIPTION_CALL,
    CUSTOMER_CANCEL_SUBSCRIPTION,
    CUSTOMER_ADD_SUBSCRIPTION,
    CUSTOMER_SUBSCRIPTION_HISTORY_CALL,
    customerSubscriptionHistoryResponse,
    CUSTOMER_USAGES_IN_PERIOD_CALL,
    customerUsagesForPeriodSuccess,
    setRequestsLimit
} from './actions';
import { resetAffiliationMemberStore } from '../members/actions';

function* customerCallGen(action) {
    try {
        const { data } = yield call(rootApi.get, '/customer');
        yield put({ type: CUSTOMER_CALL_SUCCESS, payload: { customer: data } });
      /*  if (!data.company) {

        }*/
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put({ type: CUSTOMER_CALL_FAIL });
    }
}

function* customerDeleteGen(action) {
    try {
        yield call(rootApi.delete, '/customer');
        yield put(removeCustomerLoading());
        yield put(resetAffiliationMemberStore(true));
        window.location.href = '/logout';
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put({ type: CUSTOMER_CALL_FAIL });
    }
}

function* customerEditGen(action) {
    try {
        const { payload: { customerNewValues } } = action;
        const { data } = yield call(rootApi.put, '/customer', customerNewValues);

        yield put(customerEdit(data));
        yield put(setCustomerCode('info-changed-success'));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeCustomerLoading());
    }
}

function* customerChangePasswordCallGen(action) {
    try {
        const { payload: { oldPassword, newPassword } } = action;
        yield call(rootApi.put, '/customer/new-password', { oldPassword, newPassword });
        yield put(setCustomerCode('password-changed-success'));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(removeCustomerLoading());
}

function* customerPaymentInfoCallGen(action) {
    try {
        const { data } = yield call(rootApi.get, '/payment/customer/info');
        yield put(customerPaymentInfoResponse(data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(customerPaymentInfoResponse());
    }
}

function* customerCancelSubscriptionCallGen(action) {
    try {
        const { data: customerData } = yield call(rootApi.post, '/payment/subscription/cancel');
        yield put(changeSubscriptionResponse(customerData));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(changeSubscriptionResponse());
    }
}

function* customerAddSubscription(action) {
    try {
        const { payload: { subscriptionId } } = action;
        const { data: customerData } = yield call(rootApi.post, '/payment/subscription/create', { subscriptionId });
        yield put(changeSubscriptionResponse(customerData));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeCustomerLoading());
    }
}

function* customerPaymentInvoicesCallGen(action) {
    try {
        // const { payload: { subscriptionId } } = action;
        const { data } = yield call(rootApi.get, '/payment/customer/invoices');
        yield put(customerPaymentInvoicesResponse(data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(customerPaymentInvoicesResponse());
    }
}

function* subscriptionCanceledGen(action) {
    try {
        const { payload: { message } } = action;
        yield call(rootApi.post, '/payment/subscription/canceled', {
            message
        });
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        console.log('fail on cancel subscription');
    }
}

function* subscriptionChangeCallGen(action) {
    try {
        const { payload: { updatePlanData } } = action;
        const { data: customerData } = yield call(rootApi.post, '/payment/subscription/change', updatePlanData);
        yield put(changeSubscriptionResponse(customerData));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(changeSubscriptionResponse());
    }
}

function* subscriptionTrialChangeCallGen(action) {
    try {
        const { payload: { planId } } = action;

        const { data: customerData } = yield call(rootApi.put, '/payment/subscription/change-trial', { planId });
        yield put(changeSubscriptionResponse(customerData));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(changeSubscriptionResponse());
    }
}

function* subscriptionHistoryCallGen(action) {
    try {
        const { data: subscriptionHistory } = yield call(rootApi.get, '/customer/subscription-history');
        yield put(customerSubscriptionHistoryResponse(subscriptionHistory));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(customerSubscriptionHistoryResponse());
    }
}

function* usagesForPeriodCallGen(action) {
    try {
        const { payload: { currentPeriodId } } = action;
        const { data: { activeUsers, storageUsage } } = yield call(rootApi.get, '/customer/usages-in-period', { params: { currentPeriodId } });
        yield put(customerUsagesForPeriodSuccess(activeUsers, storageUsage));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(customerSubscriptionHistoryResponse());
    }
}

export default function* customerWatcherSaga() {
    yield takeLatest(CUSTOMER_CALL, customerCallGen);
    yield takeLatest(CUSTOMER_ADD_SUBSCRIPTION, customerAddSubscription);
    yield takeLatest(CUSTOMER_EDIT_CALL, customerEditGen);
    yield takeLatest(CUSTOMER_DELETE, customerDeleteGen);
    yield takeLatest(CUSTOMER_CHANGE_PASSWORD_CALL, customerChangePasswordCallGen);
    yield takeLatest(CUSTOMER_PAYMENT_INFO_CALL, customerPaymentInfoCallGen);
    yield takeLatest(CUSTOMER_CANCEL_SUBSCRIPTION, customerCancelSubscriptionCallGen);
    yield takeLatest(CUSTOMER_PAYMENT_INVOICES_CALL, customerPaymentInvoicesCallGen);
    yield takeLatest(SUBSCRIPTION_CANCELED, subscriptionCanceledGen);
    yield takeLatest(CUSTOMER_CHANGE_SUBSCRIPTION_CALL, subscriptionChangeCallGen);
    yield takeLatest(CUSTOMER_CHANGE_TRIAL_SUBSCRIPTION_CALL, subscriptionTrialChangeCallGen);
    yield takeLatest(CUSTOMER_SUBSCRIPTION_HISTORY_CALL, subscriptionHistoryCallGen);
    yield takeLatest(CUSTOMER_USAGES_IN_PERIOD_CALL, usagesForPeriodCallGen);
}