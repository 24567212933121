const notificationInputsStructure = {
    service_type: {
        label: 'Type',
        type: 'select-custom',
        placeholder: 'Select type',
        validation: {
            presence: true
        },
        options: [
            'APNs',
            'FCM',
            'HMS'
        ]
    },

    name: {
        label: 'Credential name',
        type: 'text',
        placeholder: 'Enter credential name',
        validation: {
            presence: true,
            length: {
                maximum: 250
            }
        }
    },

    fcm: {
        api_key: {
            label: 'Server Key',
            type: 'textarea',
            placeholder: 'Please provide your Android push notification Server Key',
            validation: {
                presence: true
            },
            spellCheck: false
        },

        notification_type: {
            label: 'Message Type',
            type: 'select-custom',
            placeholder: 'Select type',
            validation: {
                presence: true
            },
            options: [
                'Notification message',
                'Data message'
            ]
        },

        push_template: {
            title: {
                label: 'Title',
                type: 'text',
                placeholder: 'Enter title'
            },
            body: {
                label: 'Message',
                type: 'text',
                placeholder: 'Enter message'
            },
            sound: {
                label: 'Sound',
                type: 'text',
                placeholder: 'Enter file name'
            }
        }
    },

    hms: {
        client_id: {
            label: 'Client ID',
            type: 'textarea',
            placeholder: 'Please provide your HMS push notification Client ID',
            validation: {
                presence: true
            },
            spellCheck: false
        },
        client_secret: {
            label: 'Client Secret',
            type: 'textarea',
            placeholder: 'Please provide your HMS push notification Client Secret',
            validation: {
                presence: true
            },
            spellCheck: false
        },
        notification_type: {
            label: 'Message Type',
            type: 'select-custom',
            placeholder: 'Select type',
            validation: {
                presence: true
            },
            options: [
                'Notification message',
                'Data message'
            ]
        },

        push_template: {
            title: {
                label: 'Title',
                type: 'text',
                placeholder: 'Enter title'
            },
            body: {
                label: 'Message',
                type: 'text',
                placeholder: 'Enter message'
            },
            sound: {
                label: 'Sound',
                type: 'text',
                placeholder: 'Enter file name'
            }
        }
    },

    apn: {
        type: {
            label: 'Type',
            type: 'select-custom',
            placeholder: 'Select type',
            validation: {
                presence: true
            },
            options: [
                'certificate',
                'token'
            ]
        },
        push_template: {
            title: {
                label: 'Title',
                type: 'text',
                placeholder: 'Enter title'
            },
            subtitle: {
                label: 'Subtitle',
                type: 'text',
                placeholder: 'Enter subtitle'
            },
            body: {
                label: 'Message',
                type: 'text',
                placeholder: 'Enter message'
            },
            sound: {
                label: 'Sound',
                type: 'text',
                placeholder: 'Enter file name'
            }
        },
        development: {
            label: 'APNs push mode',
            type: 'select-custom',
            options: [
                'Development',
                'Production'
            ]
        },
        notification_type: {
            label: 'Content type',
            type: 'select-custom',
            options: [
                'Default',
                'Content Available',
                'Mutable Content'
            ]
        },
        token_data: {
            key_id: {
                label: 'Key ID',
                type: 'text',
                placeholder: 'Enter key id',
                validation: {
                    presence: true,
                    length: {
                        maximum: 250
                    }
                }
            },
            bundle_id: {
                label: 'Bundle ID',
                type: 'text',
                placeholder: 'Enter bundle id',
                validation: {
                    presence: true,
                    length: {
                        maximum: 250
                    }
                }
            },
            team_id: {
                label: 'Team ID',
                type: 'text',
                placeholder: 'Enter team id',
                validation: {
                    presence: true,
                    length: {
                        maximum: 250
                    }
                }
            }
        }
    }
};

export default notificationInputsStructure;