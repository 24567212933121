import {
    APPS_CALL,
    APPS_CALL_SUCCESS,
    APPS_CALL_FETCHED,
    APP_CREATE_CALL,
    APP_EDIT_CALL,
    APP_DELETE_CALL,
    APP_ADD,
    APP_EDIT,
    APP_DELETE,
    SET_SELECTED_APP,
    APP_LOADING_TOGGLE,
    SET_SELECTED_APP_PREVIOUS
} from './actions';

const initialState = {
    apps: [],
    selectedAppId: '',
    isFetched: false,
    createLoading: false,
    editLoading: false,
    deleteLoading: false
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };
    const newApps = [...newState.apps];

    switch (type) {

        case APPS_CALL: {
            newState.isFetched = false;
            return newState;
        }

        case APPS_CALL_SUCCESS: {
            if (payload.apps) {
                newState.apps = [...payload.apps];
                return newState;
            }
            return state;
        }

        case APPS_CALL_FETCHED: {
            newState.isFetched = true;
            return newState;
        }

        case APP_CREATE_CALL: {
            newState.createLoading = true;
            return newState;
        }

        case APP_EDIT_CALL: {
            newState.editLoading = true;
            return newState;
        }

        case APP_DELETE_CALL: {
            newState.deleteLoading = true;
            return newState;
        }

        case APP_LOADING_TOGGLE: {
            const lState = payload.loadingState;
            // eslint-disable-next-line default-case
            switch (payload.loadingType) {
                case 'create': {
                    newState.createLoading = lState;
                    break;
                }
                case 'edit': {
                    newState.editLoading = lState;
                    break;
                }
                case 'delete': {
                    newState.deleteLoading = lState;
                    break;
                }
            }
            return newState;
        }

        case APP_ADD: {
            if (payload.app) {
                newState.apps = [payload.app, ...newApps];
                return newState;
            }
            return state;
        }

        case APP_DELETE: {
            if (payload.appId !== undefined) {
                newState.apps = newApps.filter(app => app.app_id !== payload.appId);
                return newState;
            }
            return state;
        }

        case APP_EDIT: {
            if (payload.appId !== undefined && payload.appNewValues) {
                newState.apps = newApps.map(app => (app.app_id === payload.appId ? { ...app, ...payload.appNewValues } : app));
                return newState;
            }
            return state;
        }

        case SET_SELECTED_APP: {
            if (payload.appId) {
                let id = '';

                const findApp = newApps.find(app => app.app_id === payload.appId);
                if (findApp) {
                    id = payload.appId;
                } else if (newApps.length > 0) {
                    id = newApps[0].app_id;
                }

                newState.selectedAppId = id;
                localStorage.setItem('selectedAppId', id);
                return newState;
            }
            return state;
        }

        case SET_SELECTED_APP_PREVIOUS: {
            if (payload.appId) {
                let id = '';

                const currentAppIndex = newApps.findIndex(app => app.app_id === payload.appId);

                if (currentAppIndex > 0) {
                    const previousApp = newApps[currentAppIndex - 1];
                    id = previousApp.app_id;
                } else if (currentAppIndex === 0 && newApps.length > 1) {
                    id = newApps[1].app_id;
                }

                newState.selectedAppId = id;
                localStorage.setItem('selectedAppId', id);
                return newState;
            }
            return state;
        }

        default:
            return state;
    }
};