import React, { useEffect, Suspense, lazy, useMemo, useCallback } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { appsDataSelector } from '../store/apps/selectors';
import Loader from '../components/complex/loader';
import ConditionRoute from './condition-route';
import { customerDataSelector } from '../store/customer/selectors';
import { routeNames, subRoutNames } from '../helpers/constants';

/** Page components **/
    // import CustomerAccount from '../components/pages/customer/account';
    // import AppsPage from '../components/pages/apps';
    // import HelpPage from '../components/pages/help';
    // import RolesPage from '../components/pages/apps/roles';
    // import UsersPage from '../components/pages/users';
    // import UsagePage from '../components/pages/usage';
    // import BillingPage from '../components/pages/billing';
    // import SecurityPage from '../components/pages/security';
    // import OverviewPage from '../components/pages/overview';
    // import ChannelsPage from '../components/pages/channels';
    // import ChannelMembersPage from '../components/pages/channels/members';
    //
    // import AppSettings from '../components/pages/apps/app-settings';
    // import NotificationsPage from '../components/pages/notifications';
    // import ApiKeys from '../components/pages/api-keys';
    // import WebHooks from '../components/pages/webhooks';
    //
    // import NotFound from '../components/pages/not-found';

const CustomerAccount = lazy(() => import('../components/pages/customer/account'));
const HelpPage = lazy(() => import('../components/pages/help'));

const AppsPage = lazy(() => import('../components/pages/apps'));
const RolesPage = lazy(() => import('../components/pages/apps/roles'));
const MarkersPage = lazy(() => import('../components/pages/markers'));
const UsersPage = lazy(() => import('../components/pages/users'));
const UsagePage = lazy(() => import('../components/pages/usage'));
const ModerationPage = lazy(() => import('../components/pages/moderation'));
const BillingPage = lazy(() => import('../components/pages/billing'));
const SecurityPage = lazy(() => import('../components/pages/security'));
const MembersPage = lazy(() => import('../components/pages/members'));
const OverviewPage = lazy(() => import('../components/pages/overview'));
const ChannelsPage = lazy(() => import('../components/pages/channels'));
const ChannelMembersPage = lazy(() => import('../components/pages/channels/members'));

const AppSettings = lazy(() => import('../components/pages/apps/app-settings'));
const NotificationsPage = lazy(() => import('../components/pages/notifications'));
const ApiKeys = lazy(() => import('../components/pages/api-keys'));
const WebHooks = lazy(() => import('../components/pages/webhooks'));

const NotFound = lazy(() => import('../components/pages/not-found'));


export default function AppRoutes({ fullWidth, showSidebar }) {
    // const history = useHistory();
    // const location = useLocation();
    // const [routeLoaded, setRouteLoaded] = useState(false);
    const { isFetched: customerIsFetched, customer } = useSelector(customerDataSelector, isEqual);
    const { isFetched: isFetchedApps, apps } = useSelector(appsDataSelector, isEqual);

    useEffect(() => {
        // Fade effect
        // setTimeout(() => {
        //     setRouteLoaded(true);
        // }, 300);
        //
        // let oldPathname = location.pathname;
        // history.listen((nextLocation, action) => {
        //     // console.log(oldPathname, nextLocation.pathname);
        //     if (nextLocation.pathname !== oldPathname) {
        //         oldPathname = nextLocation.pathname;
        //         setRouteLoaded(false);
        //         setTimeout(() => {
        //             setRouteLoaded(true);
        //         }, 200);
        //     } else {
        //         // console.log('Same route again');
        //         // console.log(action);
        //     }
        // });


        // history.block(nextLocation => {
        //     if (nextLocation.pathname !== oldPathname) {
        //         oldPathname = nextLocation.pathname;
        //     } else {
        //         return '';
        //     }
        // });

    }, []);


    const toApps = `/${routeNames.APPS}`;

    const appsPresent = useMemo(() => apps.length > 0, [isFetchedApps, apps]);

    const redirectTo = useCallback(path => <Redirect to={path}/>, []);

    const channelsPage = useCallback(() => (
        <>
            <ChannelsPage/>
            <ChannelMembersPage/>
        </>
    ), []);

    return (
        <RouterWrapper fullWidth={fullWidth} showSidebar={showSidebar}>
            <Suspense fallback={<></>}>

                {!(isFetchedApps && customerIsFetched) ? (
                    <Loader/>
                ) : (
                    // <ErrorWrapper listenToErrors={[CUSTOMER_CALL, APPS_CALL]}>
                        <Switch>
                            <ConditionRoute path="/"
                                            component={() => redirectTo(`/${routeNames.OVERVIEW}`)}
                                            condition={appsPresent}
                                            redirectLink={`/${routeNames.APPS}`}
                                            exact
                            />
                            <Route path={`/${routeNames.APPS}`}>
                                <AppsPage/>
                            </Route>
                            <ConditionRoute path={`/${routeNames.USER_ACCOUNT}`} component={CustomerAccount} condition={!!customer}/>
                            <ConditionRoute path={`/${routeNames.GET_HELP}`} component={HelpPage} condition={!!customer}/>
                            <ConditionRoute path={`/${routeNames.SECURITY}`} component={SecurityPage} condition={!!customer}/>
                            <ConditionRoute path={`/${routeNames.MEMBERS}`} component={MembersPage} condition={!!customer}/>

                            <ConditionRoute path={`/${routeNames.OVERVIEW}`} component={OverviewPage} condition={appsPresent} redirectLink={toApps}/>
                            <ConditionRoute path={`/${routeNames.USERS}`} component={UsersPage} condition={appsPresent} redirectLink={toApps}/>
                            {/* <ConditionRoute path={`/${routeNames.ROLES}`} component={RolesPage} condition={appsPresent} redirectLink={toApps}/>*/}
                             <ConditionRoute path={`/${routeNames.USAGE}`} component={UsagePage} condition={!!customer} redirectLink={toApps}/>
                             <ConditionRoute path={`/${routeNames.MODERATION}`} component={ModerationPage} condition={appsPresent} redirectLink={toApps}/>

                            <ConditionRoute path={`/${routeNames.CHANNELS}/:channelId?/:name?`}
                                            component={channelsPage}
                                            condition={appsPresent}
                                            redirectLink={toApps}
                            />

                            <ConditionRoute path={`/${routeNames.BILLING}`}
                                            component={() => redirectTo(`/${routeNames.BILLING}/${subRoutNames.SUBSCRIPTION}`)}
                                            condition={!!customer}
                                            exact
                            />
                            <ConditionRoute path={`/${routeNames.BILLING}/${subRoutNames.SUBSCRIPTION}`}
                                            component={BillingPage}
                                            condition={!!customer}
                            />

                            <ConditionRoute path={`/${routeNames.SETTINGS}/${subRoutNames.APPLICATION}`}
                                            component={AppSettings}
                                            condition={appsPresent}
                                            redirectLink={toApps}
                            />
                            <ConditionRoute path={`/${routeNames.SETTINGS}/${subRoutNames.ROLES}`}
                                            component={RolesPage}
                                            condition={appsPresent}
                                            redirectLink={toApps}
                            />
                            <ConditionRoute path={`/${routeNames.SETTINGS}/${subRoutNames.MARKERS}`}
                                            component={MarkersPage}
                                            condition={appsPresent}
                                            redirectLink={toApps}
                            />
                            <ConditionRoute path={`/${routeNames.SETTINGS}/${subRoutNames.NOTIFICATIONS}`}
                                            component={NotificationsPage}
                                            condition={appsPresent}
                                            redirectLink={toApps}
                            />
                            <ConditionRoute path={`/${routeNames.SETTINGS}/${subRoutNames.API_KEYS}`}
                                            component={ApiKeys}
                                            condition={appsPresent}
                                            redirectLink={toApps}
                            />
                            <ConditionRoute path={`/${routeNames.SETTINGS}/${subRoutNames.WEB_HOOKS}`}
                                            component={WebHooks}
                                            condition={appsPresent}
                                            redirectLink={toApps}
                            />

                            <Route path="/not-found">
                                <NotFound/>
                            </Route>
                            <Redirect to="/not-found"/>
                        </Switch>
                    // </ErrorWrapper>
                )}
            </Suspense>
        </RouterWrapper>
    );
}

AppRoutes.propTypes = {
    showSidebar: PropTypes.bool,
    fullWidth: PropTypes.bool
};

AppRoutes.defaultProps = {
    showSidebar: true,
    fullWidth: false
};

const RouterWrapper = styled.div`
  height: calc(100vh - 60px);

    //width: ${props => (props.fullWidth ? '100%' : props.showSidebar ? 'calc(100% - 250px)' : 'calc(100% - 60px)')};
    // min-width: ${props => (props.fullWidth ? '100%' : props.showSidebar ? 'calc(100% - 250px)' : 'calc(100% - 60px)')};
    width: 100%;
`;