export const SET_ERROR = 'SET_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const REMOVE_ERROR_LIST = 'REMOVE_ERROR_LIST';
export const TOGGLE_ERROR = 'TOGGLE_ERROR';

export function setError(error, failedAction, cancelAction) {
    return { type: SET_ERROR, payload: { error, failedAction, cancelAction } };
}

export function removeError(errorId) {
    return { type: REMOVE_ERROR, payload: { errorId } };
}

export function toggleError(errorId) {
    return { type: TOGGLE_ERROR, payload: { errorId } };
}

export function removeErrorList() {
    return { type: REMOVE_ERROR_LIST, payload: {} };
}