import {
    CUSTOMER_ADD_SUBSCRIPTION,
    CUSTOMER_CALL,
    CUSTOMER_CALL_FAIL,
    CUSTOMER_CALL_SUCCESS,
    CUSTOMER_CANCEL_SUBSCRIPTION,
    CUSTOMER_CHANGE_PASSWORD_CALL,
    CUSTOMER_CHANGE_SUBSCRIPTION_CALL,
    CUSTOMER_CHANGE_SUBSCRIPTION_RESPONSE,
    CUSTOMER_CHANGE_TRIAL_SUBSCRIPTION_CALL,
    CUSTOMER_CODE,
    CUSTOMER_DELETE,
    CUSTOMER_EDIT,
    CUSTOMER_EDIT_CALL,
    CUSTOMER_LOADING_OFF,
    CUSTOMER_PAYMENT_INFO_CALL,
    CUSTOMER_PAYMENT_INFO_RESPONSE,
    CUSTOMER_PAYMENT_INVOICES_CALL,
    CUSTOMER_PAYMENT_INVOICES_RESPONSE,
    CUSTOMER_SUBSCRIPTION_HISTORY_CALL,
    CUSTOMER_SUBSCRIPTION_HISTORY_RESPONSE,
    CUSTOMER_USAGES_IN_PERIOD_CALL,
    CUSTOMER_USAGES_IN_PERIOD_SUCCESS,
    SET_REQUESTS_LIMIT
} from './actions';

const initialState = {
    customer: {},
    loading: false,
    deleteLoading: false,
    isFetched: false,
    status: '',

    paymentInfo: {},
    paymentInfoIsFetched: false,

    paymentInvoices: [],
    paymentInvoicesIsFetched: false,

    subscriptionChangeLoading: false,

    subscriptionHistory: [],
    subscriptionHistoryLoading: false,

    activeUsersForCurrentPeriod: 0,
    storageUsageForCurrentPeriod: 0,
    usageInPeriodLoading: false,

    requestsLimit: undefined
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };

    switch (type) {
        case CUSTOMER_CALL: {
            newState.isFetched = false;
            return newState;
        }

        case CUSTOMER_CALL_SUCCESS: {
            if (payload.customer) {
                newState.customer = { ...payload.customer };
                newState.isFetched = true;
                return newState;
            }
            return state;
        }

        case CUSTOMER_CALL_FAIL: {
            newState.isFetched = true;
            newState.deleteLoading = false;
            newState.loading = false;
            return newState;
        }

        case CUSTOMER_EDIT_CALL:
        case CUSTOMER_CHANGE_PASSWORD_CALL: {
            newState.loading = true;
            return newState;
        }

        case CUSTOMER_DELETE: {
            newState.deleteLoading = true;
            return newState;
        }

        case CUSTOMER_EDIT: {
            if (payload.customerNewValues) {
                newState.customer = { ...newState.customer, ...payload.customerNewValues };
                newState.loading = false;
                return newState;
            }
            return state;
        }

        case CUSTOMER_LOADING_OFF: {
            newState.loading = false;
            newState.deleteLoading = false;
            newState.subscriptionChangeLoading = false;
            return newState;
        }

        case CUSTOMER_CODE: {
            if (typeof payload.code === 'string' || payload.code instanceof String) {
                newState.code = payload.code;
                return newState;
            }
            return state;
        }

        case CUSTOMER_SUBSCRIPTION_HISTORY_CALL: {
            newState.subscriptionHistoryLoading = true;
            return newState;
        }

        case CUSTOMER_SUBSCRIPTION_HISTORY_RESPONSE: {
            newState.subscriptionHistoryLoading = false;
            newState.subscriptionHistory = payload.subscriptionHistory;
            return newState;
        }

        case CUSTOMER_USAGES_IN_PERIOD_CALL: {
            newState.usageInPeriodLoading = true;
            return newState;
        }

        case CUSTOMER_USAGES_IN_PERIOD_SUCCESS: {
            newState.activeUsersForCurrentPeriod = payload.activeUsers;
            newState.storageUsageForCurrentPeriod = payload.storageUsage;
            newState.usageInPeriodLoading = false;
            return newState;
        }

        /** Payment **/
        case CUSTOMER_PAYMENT_INFO_CALL: {
            newState.paymentInfoIsFetched = false;
            return newState;
        }

        case CUSTOMER_PAYMENT_INFO_RESPONSE: {
            newState.paymentInfoIsFetched = true;

            if (payload.data) {
                newState.paymentInfo = { ...payload.data };
            }
            return newState;
        }

        case CUSTOMER_PAYMENT_INVOICES_CALL: {
            newState.paymentInvoicesIsFetched = false;
            return newState;
        }

        case CUSTOMER_PAYMENT_INVOICES_RESPONSE: {
            newState.paymentInvoicesIsFetched = true;
            if (payload.data && payload.data.length > 0) {
                newState.paymentInvoices = [...payload.data];
            }
            return newState;
        }

        case CUSTOMER_CHANGE_SUBSCRIPTION_CALL:
        case CUSTOMER_CHANGE_TRIAL_SUBSCRIPTION_CALL:
        case CUSTOMER_CANCEL_SUBSCRIPTION:
        case CUSTOMER_ADD_SUBSCRIPTION: {
            newState.subscriptionChangeLoading = true;
            return newState;
        }

        case CUSTOMER_CHANGE_SUBSCRIPTION_RESPONSE: {
            if (payload.data !== undefined) {
                // newState.customer.subscription.plan.id = payload.planId;
                newState.customer = { ...payload.data };
            }
            newState.subscriptionChangeLoading = false;
            return newState;
        }

        case SET_REQUESTS_LIMIT: {
            newState.requestsLimit = payload.limitData;
            return newState;
        }

        default:
            return state;
    }
};