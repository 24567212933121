import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as SuccessIcon } from '../../../../../assets/svg/success.svg';
import { ReactComponent as DownloadIcon } from '../../../../../assets/svg/downloadNew.svg';
import {
  Button,
  ButtonBlock,
  PopupName
} from '../../../../UI';
import { downloadFile } from '../../../../../helpers/RSA-keygen';
import { formatAppName } from '../../../../../helpers/utils';

export default function CreateAppSuccess({ togglePopupShow, privateKey, createdApp }) {
  const [popupReadyToClose, setPopupReadyToClose] = useState(false);

  const formattedFileName = formatAppName(createdApp.name, createdApp.app_id);

  async function downloadPrivateKey() {
    downloadFile(privateKey, formattedFileName, 'text/plain');
    setPopupReadyToClose(true);
  }

  return (
    <>
      <SuccessPopupName marginBottom="20px"><SuccessIcon/>Application created</SuccessPopupName>
      <CreateAppDesc>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <b>Important:</b> Download your application's private key now and store it securely. This key is necessary
        for signing access tokens that will enable your users to connect to this application.
      </CreateAppDesc>
      <DownloadPrivateKey>
        <span>Private Key: <b>{formattedFileName}</b></span>
        <DownloadButton className="button blue inline transparent" onClick={downloadPrivateKey}><DownloadIcon/> Download</DownloadButton>
      </DownloadPrivateKey>
      <ButtonBlock>
        <button
          type="button"
          className="button blue filled "
          onClick={togglePopupShow}
          disabled={!popupReadyToClose}
        >
          Done
        </button>
      </ButtonBlock>
    </>
  );
}

CreateAppSuccess.propTypes = {
  togglePopupShow: PropTypes.func.isRequired,
  privateKey: PropTypes.string.isRequired,
  createdApp: PropTypes.objectOf(PropTypes.any.isRequired).isRequired
};

const SuccessPopupName = styled(PopupName)`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const CreateAppDesc = styled.p`
    margin: 0 0 20px;
    font-size: 14px;
    line-height: 22px;
`;

const DownloadPrivateKey = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px;
    padding: 14px 16px;
    font-size: 14px;
    line-height: 22px;
    background-color: #F4F5FF;
    border-radius: 4px;
`;

const DownloadButton = styled(Button)`
    display: flex;
    align-items: center;
    padding: 0;
    gap: 4px
`;