import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { rootApi } from '../../api';
import { setError } from '../error/actions';
import { queryHandler, uploadFile } from '../../helpers/sagas';
import {
    USERS_CALL,
    USERS_CALL_FILTER,
    USERS_CALL_SUCCESS,
    USERS_CALL_FAIL,
    USER_CREATE_CALL,
    USER_EDIT_CALL,
    USER_DELETE_CALL,
    usersCallFilter,
    removeUsersLoading,
    userEdit,
    USER_DEACTIVATE_CALL,
    USER_ACTIVATE_CALL
} from './actions';
import { queryParamsUpdate } from '../pagination/actions';
import { paginationDataSelector } from '../pagination/selectors';
import { setRequestsLimit } from '../customer/actions';

function* usersCallGen(action) {
    try {
        const { payload: { queryParams } } = action;
        const { params } = queryParams;
        const current = !(queryParams.direction === 'current');
        const usersCallParams = current ? yield queryHandler(queryParams.direction, {
            limit: params.limit,
            ...params && params.filter && { filter: params.filter }
        }) : params;

        const { data } = yield call(rootApi.get, '/users/{APPID}', { params: { ...usersCallParams, order_by: 'created_at', order: 'DESC' } });
        if (current) {
            yield put(queryParamsUpdate(queryParams.direction, { nextParams: { ...usersCallParams, next: data.next }, currentParams: { ...usersCallParams } }));
        }
        yield put({
            type: USERS_CALL_SUCCESS,
            payload: {
                // total: data.total_count,
                count: data.count,
                users: [...data.users]
            }
        });
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put({ type: USERS_CALL_FAIL });
    }
    yield put(removeUsersLoading());
}

function* userCreateCallGen(action) {
    try {
        const { payload: { user, avatarFile } } = action;

        let avatarUrl = '';
        if (avatarFile.file) {
            avatarUrl = yield uploadFile(avatarFile.file, avatarFile.name);
        }
        const userData = { ...user, ...{ avatar_url: avatarUrl } };
        yield call(rootApi.post, '/users/{APPID}', userData);
        const { currentPageParams } = yield select(paginationDataSelector);

        yield put(usersCallFilter({ params: { ...currentPageParams, filterBy: 'all', filter: '', search: '', next: '' }, direction: null }));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeUsersLoading());
    }
}

function* userEditCallGen(action) {
    try {
        const { payload: { id, user, avatarFile } } = action;

        let avatarUrl = '';
        if (avatarFile.file) {
            avatarUrl = yield uploadFile(avatarFile.file, avatarFile.name);
        }
        const userData = { ...user, ...avatarFile.file && { avatar_url: avatarUrl } };
        const { data } = yield call(rootApi.put, '/user/{APPID}', userData, { params: { id } });
        yield put(userEdit(id, data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeUsersLoading());
    }
}

function* userDeleteCallGen(action) {
    try {
        const { payload: { id } } = action;
        yield call(rootApi.delete, '/user/{APPID}', { params: { id } });
        const { currentPageParams } = yield select(paginationDataSelector);
        yield put(usersCallFilter({ params: currentPageParams, direction: 'current' }));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeUsersLoading());
    }
}

function* userDeactivateCallGen(action) {
    try {
        const { payload: { id } } = action;
        const { data } = yield call(rootApi.put, '/user/{APPID}/deactivate', { id });
        yield put(userEdit(id, data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeUsersLoading());
    }
}

function* userActivateCallGen(action) {
    try {
        const { payload: { id } } = action;
        const { data } = yield call(rootApi.put, '/user/{APPID}/activate', { id });
        yield put(userEdit(id, data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(removeUsersLoading());
    }
}

export default function* usersWatcherSaga() {
    yield takeLatest(USERS_CALL, usersCallGen);
    yield takeLatest(USERS_CALL_FILTER, usersCallGen);
    yield takeEvery(USER_CREATE_CALL, userCreateCallGen);
    yield takeEvery(USER_EDIT_CALL, userEditCallGen);
    yield takeEvery(USER_DELETE_CALL, userDeleteCallGen);
    yield takeEvery(USER_DEACTIVATE_CALL, userDeactivateCallGen);
    yield takeEvery(USER_ACTIVATE_CALL, userActivateCallGen);
}