const markersInputsStructure = {
    name: {
        label: 'Name',
        type: 'text',
        validation: {
            presence: true,
            format: {
                pattern: /^[a-z0-9]*$/,
                message: '^Allowed characters are a-z, 0-9'
            },
            length: {
                maximum: 10
            }
        }
    },
    description: {
        label: 'Description',
        type: 'text',
        validation: {
            length: {
                maximum: 250
            }
        }
    }
};

export default markersInputsStructure;