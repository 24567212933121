import { call, put, delay, select, takeEvery } from 'redux-saga/effects';
import { rootApi } from '../../api';
import {
    CREATE_CHANNEL,
    DELETE_CHANNEL,
    EDIT_CHANNEL,
    GET_CHANNELS,
    RESET_CHANNELS,
    setChannels,
    getChannels,
    resetChannelsStore,
    channelsChangeLoading,
    channelsChangeActionLoading,
    GET_CHANNEL_TYPES,
    setChannelTypes
} from './actions';
import { queryHandler, uploadFile } from '../../helpers/sagas';
import { setError } from '../error/actions';
import {
    queryChannelsParamsUpdate
} from '../pagination/actions';
import { paginationDataSelector } from '../pagination/selectors';
import { setRequestsLimit } from '../customer/actions';

function* getChannelTypesSaga(action) {
    try {
        const res = yield call(rootApi.get, '/channelTypes/{APPID}');
        const { data } = res;
        yield put(setChannelTypes(data));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(channelsChangeActionLoading(false));
}

function* getChannelsSaga(action) {
    try {
        const { payload: { queries } } = action;
        yield put(channelsChangeLoading(true));
        const { params } = queries;
        const pagination = queries.direction !== 'current';
        const channelsCallParams = pagination ? yield queryHandler(queries.direction, { ...params, order_by: 'created_at', order: 'DESC' }, true) : params;
        const res = yield call(rootApi.get, '/channels/{APPID}', { params: channelsCallParams });
        const { channels, next } = res.data;
        if (pagination) {
            yield put(queryChannelsParamsUpdate(queries.direction, { nextParams: { ...channelsCallParams, next }, currentParams: { ...channelsCallParams } }));
        }
        yield put(setChannels(channels));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(channelsChangeLoading(false));
    yield put(channelsChangeActionLoading(false));
}

function* createChannelSaga(action) {
    try {
        yield put(channelsChangeActionLoading(true));
        const { payload } = action;
        const { channel, isFetch } = payload;
        const newChannel = { ...channel };
       /* const newMembers = [...channel.members];
        if (channel.owner) {
            const ownerIndex = newMembers.findIndex(member => member.id === channel.owner);
            if (ownerIndex >= 0) {
                newMembers.splice(ownerIndex, 1);
            }
            newChannel.members = newMembers;
        }
*/
        let avatarUrl = '';
        if (newChannel.avatar) {
            avatarUrl = yield uploadFile(newChannel.avatar.file, newChannel.avatar.name);
            delete newChannel.avatar;
        }
        const channelsData = { ...newChannel, ...avatarUrl && { avatar_url: avatarUrl } };
        yield call(rootApi.post, '/channels/{APPID}', { ...channelsData });

        if (isFetch) {
            yield delay(750);
            const { channelsPagination: { currentPageParams } } = yield select(paginationDataSelector);
            yield put(getChannels({ params: currentPageParams, direction: 'current' }));
        }
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(channelsChangeActionLoading(false));
}

function* editChannelSaga(action) {
    try {
        yield put(channelsChangeActionLoading(true));

        const { payload } = action;
        const { channelId, channelToEdit, avatarFile, isFetch } = payload;
        const channelToEditCopy = { ...channelToEdit };
        delete channelToEditCopy.id;
        let avatarUrl = '';
        if (avatarFile.file) {
            avatarUrl = yield uploadFile(avatarFile.file, avatarFile.name);
        }
        const channelsData = { ...channelToEditCopy, ...avatarFile.file && { avatar_url: avatarUrl } };
        yield call(rootApi.put, `/channels/{APPID}/${channelId}`, channelsData);

        if (isFetch) {
            const { channelsPagination: { currentPageParams } } = yield select(paginationDataSelector);
            yield put(getChannels({ params: currentPageParams, direction: 'current' }));
        }
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
    yield put(channelsChangeActionLoading(false));
}

function* deleteChannelSaga(action) {
    try {
        yield put(channelsChangeActionLoading(true));
        const { payload: { channelId } } = action;
        yield call(rootApi.delete, `/channels/{APPID}/${channelId}`);

        const { channelsPagination: { currentPageParams } } = yield select(paginationDataSelector);
        yield put(getChannels({ params: currentPageParams, direction: 'current' }));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put(channelsChangeActionLoading(false));
    }
}

function* resetChannelsSaga(action) {
    try {
        const { payload: { queries } } = action;
        yield put(resetChannelsStore());
        yield put(getChannels(queries));
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
    }
}

export default function* channelsWatcherSaga() {
    yield takeEvery(GET_CHANNELS, getChannelsSaga);
    yield takeEvery(GET_CHANNEL_TYPES, getChannelTypesSaga);
    yield takeEvery(CREATE_CHANNEL, createChannelSaga);
    yield takeEvery(EDIT_CHANNEL, editChannelSaga);
    yield takeEvery(DELETE_CHANNEL, deleteChannelSaga);
    yield takeEvery(RESET_CHANNELS, resetChannelsSaga);
}