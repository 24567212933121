import { call, select } from 'redux-saga/effects';
import { rootApi } from '../api';
import { paginationDataSelector } from '../store/pagination/selectors';


export function* uploadFile(file, fileName) {
    const formData = new FormData();
    formData.append('files', file, file.name || fileName);
    const res = yield call(rootApi.post, '/files/{APPID}', formData);
    return res.data.url;
}

export function* queryHandler(direction, params, channels) {
    let nextPageQueryParams;
    let prevPagesQueryParams;
    if (channels) {
        const { channelsPagination } = yield select(paginationDataSelector);
        [nextPageQueryParams, prevPagesQueryParams] = [channelsPagination.nextPageParams, channelsPagination.prevPagesParams];
    } else {
        const { nextPageParams, prevPagesParams } = yield select(paginationDataSelector);
        [nextPageQueryParams, prevPagesQueryParams] = [nextPageParams, prevPagesParams];
    }

    const queryCallParams = { ...params };
    if (direction === 'prev') {
        return prevPagesQueryParams[prevPagesQueryParams.length - 1];
    }
    if (direction === 'next') {
        return nextPageQueryParams;
    }
    return queryCallParams;
}