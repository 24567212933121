import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '../../UI/constants';

const Loader = backgroundColor => useMemo(() => (
        <>
            <SpinnerBackground backgroundColor={backgroundColor}/>
            <CubesWrapper>
                <CubeWrapper>
                    <Cube>
                        <div className="side1"/>
                        <div className="side2"/>
                        <div className="side3"/>
                    </Cube>
                </CubeWrapper>
                <CubeWrapper>
                    <Cube>
                        <div className="side1"/>
                        <div className="side2"/>
                        <div className="side3"/>
                    </Cube>
                </CubeWrapper>
            </CubesWrapper>
        </>
    ), []);

export default Loader;

const SpinnerBackground = styled.div`
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${props => props.backgroundColor || 'black'};
    opacity: 0.1;
`;

const Cube = styled.div`
    position: absolute;
    z-index: 101;
    top: 50%;
    left: 50%;
    width: 112px;
    height: 112px;
    transform-style: preserve-3d;
    transform: translate(-50%, -50%) scale(0.2) rotateX(-35deg) rotateY(-45deg) rotateZ(0deg);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    
    
    & > div {
    position: absolute;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    width: 112px;
    height: 112px;
    overflow: hidden;
    }
    
    .side1 {
        transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ(56px);
        -moz-transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ(56px);
        -webkit-transform: rotatex(90deg) translateX(0px) translateY(0px) translateZ(56px);
        background-color: #fece63;
    }
    .side2 {
        transform: translateX(0px) translateY(0px) translateZ(56px);
        -moz-transform: translateX(0px) translateY(0px) translateZ(56px);
        -webkit-transform: translateX(0px) translateY(0px) translateZ(56px);
        background-color: ${colors.blue1};
    }
    .side3 {
        transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(56px);
        -moz-transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(56px);
        -webkit-transform: rotateY(90deg) translateX(0px) translateY(0px) translateZ(56px);
        background-color: #8a9cfc;
    }
`;

const CubeWrapper = styled.div`
    width: 40px;
    height: 40px;
    position: absolute;
    top: -5px;
    left: -35px;
`;

const CubesWrapper = styled.div`
   position: absolute;
   z-index: 101;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%) scale(0.8);
    
    ${CubeWrapper}:first-of-type {
        top: -25px;
        animation: cubeMove 1.5s cubic-bezier(0.22, 0.61, 0.36, 1) infinite;
    }
    
    ${CubeWrapper}:last-of-type {
      ${Cube} {
        top: 23px;
      }
      animation: cubeMove2 1.5s cubic-bezier(0.22, 0.61, 0.36, 1) infinite;
    }
    
  
    @keyframes cubeMove {
      0%   {transform: translate(45%, 0);}
      25%  {transform: translate(0%, 25%);}
      50%  {transform: translate(45%, 45%);}
      75%  {transform: translate(90%, 24%);}
      100% {transform: translate(45%, 0);}
    }
    
     @keyframes cubeMove2 {
      0%   {transform: translate(45%, 0%);}
      25%  {transform: translate(90%, -31%);}
      50%  {transform: translate(45%, -68%);}
      75%  {transform: translate(0%, -33%);}
      100% {transform: translate(45%, 0%);}
    }
`;