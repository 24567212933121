import { call, put, takeLatest } from 'redux-saga/effects';
import { rootApi } from '../../api';
import { removeErrorList, setError } from '../error/actions';
import {
    GET_MEMBER_ROLES,
    INVITE_MEMBER_CALL,
    removeMemberLoading,
    addMemberRoles,
    MEMBERS_CALL_FAIL,
    membersCallSuccess,
    CHANGE_MEMBER_ROLE_CALL,
    updateMember,
    MEMBER_DELETE_CALL,
    INVITATION_DELETE_CALL,
    PARENT_CUSTOMERS_CALL_FAIL,
    SET_ACTIVE_PARENT_CUSTOMER_CALL, MEMBERS_DATA_CALL, membersDataCall
} from './actions';
import { CUSTOMER_CALL_SUCCESS } from '../customer/actions';

function* membersCallGen(action) {
    try {
        const { data } = yield call(rootApi.get, '/members');
        const rolesData = yield call(rootApi.get, '/members/roles');
        const invitationsData = yield call(rootApi.get, '/members/invitations');
        yield put(addMemberRoles(rolesData.data));
        yield put(membersCallSuccess(data, invitationsData.data));
    } catch (error) {
        yield put({
            type: MEMBERS_CALL_FAIL,
            payload: {}
        });
        yield put(setError(error, action));
    }
    yield put(removeMemberLoading());
}

function* setActiveParentCustomerCallGen(action) {
    try {
        const { payload: { customerId } } = action;
        const { data } = yield call(rootApi.put, `/customer/switch/${customerId}`);
        yield put({ type: CUSTOMER_CALL_SUCCESS, payload: { customer: data } });
        yield put(removeErrorList());
    } catch (error) {
        yield put({
            type: PARENT_CUSTOMERS_CALL_FAIL,
            payload: {}
        });
        yield put(setError(error, action));
    }
}

/* function* invitationsCallGen(action) {
    try {
        const invitationsData = yield call(rootApi.get, '/members/invitations');
        yield put(invitationsCallSuccess(invitationsData.data));
    } catch (error) {
        yield put({
            type: MEMBERS_CALL_FAIL,
            payload: {}
        });
        yield put(setError(error, action));
    }
    yield put(removeMemberLoading());
}*/

function* inviteMemberCallGen(action) {
    try {
        const { payload: { invitationData } } = action;
        yield call(rootApi.post, '/members/invitations', { ...invitationData });
        yield put(membersDataCall());
    } catch (error) {
        yield put(setError(error, action));
    }
    yield put(removeMemberLoading());
}

function* getMembersRolesCallGen(action) {
    try {
        const rolesData = yield call(rootApi.get, '/members/roles');
        yield put(addMemberRoles(rolesData.data));
    } catch (error) {
        console.log('error get member roles', error);
        yield put(setError(error, action));
        yield put(removeMemberLoading());
    }
}

function* changeMemberRoleCallGen(action) {
    try {
        const { payload: { memberId, roleId } } = action;
        const memberData = yield call(rootApi.put, '/members', { memberId, roleId });
        yield put(updateMember(memberData.data));
    } catch (error) {
        console.log('error get member roles', error);
        yield put(setError(error, action));
    }
    yield put(removeMemberLoading());
}

function* deleteMemberCallGen(action) {
    try {
        const { payload: { memberId } } = action;
        yield call(rootApi.delete, `/members/${memberId}`);
        yield put(membersDataCall());
    } catch (error) {
        console.log('error delete member', error);
        yield put(setError(error, action));
    }
    yield put(removeMemberLoading());
}

function* deleteInvitationCallGen(action) {
    try {
        const { payload: { email } } = action;
        yield call(rootApi.delete, `/members/invitations/${email}`);
        yield put(membersDataCall());
    } catch (error) {
        console.log('error delete member', error);
        yield put(setError(error, action));
    }
    yield put(removeMemberLoading());
}


export default function* rolesWatcherSaga() {
    yield takeLatest(MEMBERS_DATA_CALL, membersCallGen);
    yield takeLatest(CHANGE_MEMBER_ROLE_CALL, changeMemberRoleCallGen);
    yield takeLatest(INVITE_MEMBER_CALL, inviteMemberCallGen);
    yield takeLatest(GET_MEMBER_ROLES, getMembersRolesCallGen);
    yield takeLatest(MEMBER_DELETE_CALL, deleteMemberCallGen);
    yield takeLatest(INVITATION_DELETE_CALL, deleteInvitationCallGen);
    yield takeLatest(SET_ACTIVE_PARENT_CUSTOMER_CALL, setActiveParentCustomerCallGen);
}