import { useState } from 'react';


const useSearchFilter = (
    {
        limit,
        // page,
        filterBy,
        method,
        search,
        next
    },
    filterTypes = []
) => {
    function generateFilterString(props) {
        let newFilterString = '';
        if (filterTypes.length > 0 && props.filterBy && props.method && props.search !== '') {
            if (props.filterBy === 'all') {
                const filterList = [];

                filterTypes.forEach(fil => {
                    if (fil.option !== 'all') {
                        filterList.push(fil.option);
                    }
                });

                newFilterString = `${filterList.join(',')}:${props.method}:${props.search}`;
            } else {
                newFilterString = `${props.filterBy}:${props.method}:${props.search}`;
            }
        }
        return newFilterString;
    }

    const [params, setParams] = useState({
        limit,
        // page,
        filterBy,
        method,
        search,
        next,
        filter: generateFilterString({
            limit,
            // page,
            filterBy,
            method,
            search,
            next
        })
    });

    function updateParams(newProps) {
        const newParams = { ...params, ...newProps };
        const newFilterString = generateFilterString(newParams);
        return { ...newParams, filter: newFilterString };
    }

    return [
        params,
        newValues => {
            setParams(updateParams(newValues));
        }
    ];
};

export default useSearchFilter;