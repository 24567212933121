import {
    CHANGE_ROLE_STORE,
    RESET_MEMBERS_STORE,
    SET_ACTIVE_MEMBERS,
    SET_BLOCKED_MEMBERS,
    TOGGLE_ACTIVE_MEMBERS_LOADING,
    TOGGLE_BLOCKED_MEMBERS_LOADING,
    MEMBERS_ACTION_LOADING_CHANGE, UNBLOCK_MEMBER_LOADING
} from './actions';


const initialState = {
    activeMembers: {
        list: [],
        isLoading: false
    },
    blockedMembers: {
        list: [],
        isLoading: false
    },
    isFetched: false,
    actionLoading: false,
    unblockMemberLoading: {},

    roleChangeLoading: false
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };
    switch (type) {
        case RESET_MEMBERS_STORE:
            newState.activeMembers = { ...newState.activeMembers };
            newState.blockedMembers = { ...newState.blockedMembers };
            newState.activeMembers.list = [];
            newState.blockedMembers.list = [];
            if (payload.isReset) {
                newState.isFetched = false;
            }
            return newState;

        case SET_ACTIVE_MEMBERS:
            newState.activeMembers.list = payload.members;
            newState.isFetched = true;
            newState.actionLoading = false;
            return newState;

        case SET_BLOCKED_MEMBERS:
            newState.blockedMembers.list = payload.members;
            newState.isFetched = true;
            return newState;

        case CHANGE_ROLE_STORE: {
            if (payload.channelId !== undefined && payload.member) {
                newState.activeMembers.list = newState.activeMembers.list.map(m => (m.id === payload.member.id ? { ...m, role: payload.member.role } : m));
                return newState;
            }
            return state;
        }

        case TOGGLE_ACTIVE_MEMBERS_LOADING:
            newState.activeMembers.isLoading = !newState.activeMembers.isLoading;
            return newState;

        case TOGGLE_BLOCKED_MEMBERS_LOADING:
            newState.blockedMembers.isLoading = !newState.blockedMembers.isLoading;
            return newState;

        case MEMBERS_ACTION_LOADING_CHANGE:
            newState.actionLoading = payload.status;
            return newState;

        case UNBLOCK_MEMBER_LOADING:
            newState.unblockMemberLoading = payload.data;
            return newState;

        default:
            return state;
    }
};