import {
    PERMISSIONS_CALL,
    PERMISSIONS_CALL_SUCCESS,
    PERMISSIONS_CALL_FAIL
} from './actions';

const initialState = {
    permissions: [],
    isFetched: false
    // error: false
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };

    switch (type) {

        case PERMISSIONS_CALL: {
            newState.isFetched = false;
            // newState.error = false;
            return newState;
        }

        case PERMISSIONS_CALL_SUCCESS: {
            if (payload.permissions) {
                newState.permissions = [...payload.permissions];
                newState.isFetched = true;
                // newState.error = false;
                return newState;
            }
            return state;
        }

        case PERMISSIONS_CALL_FAIL: {
            newState.isFetched = true;
            // newState.error = true;
            return newState;
        }

        default:
            return state;
    }
};