import { call, put, select, takeLatest } from 'redux-saga/effects';
import { rootApi } from '../../api';
import { setError } from '../error/actions';
import {
    CHANNEL_REPORT_RESOLVE,
    CHANNEL_REPORTS_CALL,
    CHANNEL_REPORTS_MESSAGES_CALL,
    channelReportsCallSuccess,
    channelReportsMessagesCallSuccess,
    MESSAGE_REPORT_RESOLVE,
    MESSAGE_REPORTS_CALL,
    MODERATION_CALL_FAIL,
    removeModerationLoading,
    REPORTED_CHANNELS_CALL,
    REPORTED_MESSAGES_CALL,
    REPORTED_USERS_CALL,
    reportedChannelsCall,
    reportedChannelsCallSuccess,
    reportedMessagesCall,
    reportedMessagesCallSuccess,
    reportedUsersCall,
    reportedUsersCallSuccess,
    USER_REPORT_RESOLVE,
    USER_REPORTS_CALL,
    USER_REPORTS_MESSAGES_CALL,
    userReportsMessagesCallSuccess
} from './actions';
import { queryParamsUpdate } from '../pagination/actions';
import { queryHandler } from '../../helpers/sagas';
import { paginationDataSelector } from '../pagination/selectors';
import { reportsDataState } from '../../helpers/constants';

function* reportedChannelsCallGen(action) {
    try {
        const { payload: { queryParams, reportsState } } = action;

        const pagination = queryParams.direction !== 'current';
        const reportedChannelsCallParams = pagination ? yield queryHandler(queryParams.direction, queryParams) : queryParams;
        const { data } = yield call(rootApi.get, '/moderation/{APPID}/channels/', { params: reportedChannelsCallParams });
        yield put(reportedChannelsCallSuccess({ count: data.count, reportedChannels: data.reports }, reportsState));

        yield put(queryParamsUpdate(queryParams.direction, {
            nextParams: { limit: queryParams.limit, next: data.next },
            currentParams: { limit: queryParams.limit }
        }));
    } catch (error) {
        yield put({
            type: MODERATION_CALL_FAIL,
            payload: {}
        });
        yield put(setError(error, action));
    }
    yield put(removeModerationLoading());
}

function* channelReportsCallGen(action) {
    try {
        const { payload: { queryParams } } = action;
        const { data } = yield call(rootApi.get, `/moderation/{APPID}/channels/${queryParams.reportId}`, {
            params: {
                limit: queryParams.limit,
                next: queryParams.next
            }
        });
        yield put(channelReportsCallSuccess(data));
    } catch (error) {
        yield put({
            type: MODERATION_CALL_FAIL,
            payload: {}
        });
        yield put(setError(error, action));
    }
    yield put(removeModerationLoading());
}

function* channelReportsMessagesCallGen(action) {
    try {
        const { payload: { queryParams } } = action;
        const { data } = yield call(
            rootApi.get,
            `/moderation/{APPID}/channels/${queryParams.channelId}/messages`,
            { params: { limit: queryParams.limit, next: queryParams.next } }
        );
        yield put(channelReportsMessagesCallSuccess(data));
    } catch (error) {
        yield put({
            type: MODERATION_CALL_FAIL,
            payload: {}
        });
        yield put(setError(error, action));
    }
    yield put(removeModerationLoading());
}

function* channelReportResolveCallGen(action) {
    try {
        const { payload: { params } } = action;
        yield call(rootApi.post, `/moderation/{APPID}/channels/${params.reportId}/resolve`, { action: params.action });

        const { currentPageParams } = yield select(paginationDataSelector);
        yield put(reportedChannelsCall({ ...currentPageParams, direction: 'current' }, reportsDataState.PENDING));
    } catch (error) {
        yield put({
            type: MODERATION_CALL_FAIL,
            payload: {}
        });
        yield put(setError(error, action));
    }
    yield put(removeModerationLoading());
}

function* reportedMessagesCallGen(action) {
    try {
        const { payload: { queryParams, reportsState } } = action;

        const pagination = queryParams.direction !== 'current';
        const reportedMessagesCallParams = pagination ? yield queryHandler(queryParams.direction, queryParams) : queryParams;
        const { data } = yield call(rootApi.get, '/moderation/{APPID}/messages/', { params: reportedMessagesCallParams });
        yield put(reportedMessagesCallSuccess({ count: data.count, reportedMessages: data.reports }, reportsState));

        yield put(queryParamsUpdate(queryParams.direction, {
            nextParams: { limit: queryParams.limit, next: data.next },
            currentParams: { limit: queryParams.limit }
        }));
    } catch (error) {
        yield put({
            type: MODERATION_CALL_FAIL,
            payload: {}
        });
        yield put(setError(error, action));
    }
    yield put(removeModerationLoading());
}

function* messageReportsCallGen(action) {
    try {
        const { payload: { queryParams } } = action;
        const { data } = yield call(rootApi.get, `/moderation/{APPID}/messages/${queryParams.reportId}`, {
            params: {
                limit: queryParams.limit,
                next: queryParams.next
            }
        });
        yield put(channelReportsCallSuccess(data));
    } catch (error) {
        yield put({
            type: MODERATION_CALL_FAIL,
            payload: {}
        });
        yield put(setError(error, action));
    }
    yield put(removeModerationLoading());
}

function* messageReportResolveCallGen(action) {
    try {
        const { payload: { params } } = action;
        console.log('resolve message,,', params.reportId);
        yield call(rootApi.post, `/moderation/{APPID}/messages/${params.reportId}/resolve`, { action: params.action });
        const { currentPageParams } = yield select(paginationDataSelector);
        yield put(reportedMessagesCall({ ...currentPageParams, direction: 'current' }, reportsDataState.PENDING));
    } catch (error) {
        yield put({
            type: MODERATION_CALL_FAIL,
            payload: {}
        });
        yield put(setError(error, action));
    }
    yield put(removeModerationLoading());
}

function* reportedUsersCallGen(action) {
    try {
        const { payload: { queryParams, reportsState } } = action;

        const pagination = queryParams.direction !== 'current';
        const reportedMessagesCallParams = pagination ? yield queryHandler(queryParams.direction, queryParams) : queryParams;
        const { data } = yield call(rootApi.get, '/moderation/{APPID}/users/', { params: reportedMessagesCallParams });
        yield put(reportedUsersCallSuccess({ count: data.count, reportedUsers: data.reports }, reportsState));

        yield put(queryParamsUpdate(queryParams.direction, {
            nextParams: { limit: queryParams.limit, next: data.next },
            currentParams: { limit: queryParams.limit }
        }));
    } catch (error) {
        yield put({
            type: MODERATION_CALL_FAIL,
            payload: {}
        });
        yield put(setError(error, action));
    }
    yield put(removeModerationLoading());
}

function* userReportsCallGen(action) {
    try {
        const { payload: { queryParams } } = action;
        const { data } = yield call(rootApi.get, `/moderation/{APPID}/users/${queryParams.reportId}`, {
            params: {
                limit: queryParams.limit,
                next: queryParams.next
            }
        });
        yield put(channelReportsCallSuccess(data));
    } catch (error) {
        yield put({
            type: MODERATION_CALL_FAIL,
            payload: {}
        });
        yield put(setError(error, action));
    }
    yield put(removeModerationLoading());
}

function* userReportsMessagesCallGen(action) {
    try {
        const { payload: { queryParams } } = action;
        const { data } = yield call(rootApi.get, `/moderation/{APPID}/users/${queryParams.userId}/messages`, {
            params: {
                limit: queryParams.limit,
                next: queryParams.next
            }
        });
        yield put(userReportsMessagesCallSuccess(data));
    } catch (error) {
        yield put({
            type: MODERATION_CALL_FAIL,
            payload: {}
        });
        yield put(setError(error, action));
    }
    yield put(removeModerationLoading());
}

function* userReportResolveCallGen(action) {
    try {
        const { payload: { params } } = action;
        yield call(rootApi.post, `/moderation/{APPID}/users/${params.reportId}/resolve`, { action: params.action });
        const { currentPageParams } = yield select(paginationDataSelector);
        yield put(reportedUsersCall({ ...currentPageParams, direction: 'current' }, reportsDataState.PENDING));
    } catch (error) {
        yield put({
            type: MODERATION_CALL_FAIL,
            payload: {}
        });
        yield put(setError(error, action));
    }
    yield put(removeModerationLoading());
}

export default function* rolesWatcherSaga() {
    yield takeLatest(REPORTED_CHANNELS_CALL, reportedChannelsCallGen);
    yield takeLatest(CHANNEL_REPORTS_CALL, channelReportsCallGen);
    yield takeLatest(CHANNEL_REPORTS_MESSAGES_CALL, channelReportsMessagesCallGen);
    yield takeLatest(CHANNEL_REPORT_RESOLVE, channelReportResolveCallGen);
    yield takeLatest(REPORTED_MESSAGES_CALL, reportedMessagesCallGen);
    yield takeLatest(MESSAGE_REPORTS_CALL, messageReportsCallGen);
    yield takeLatest(REPORTED_USERS_CALL, reportedUsersCallGen);
    yield takeLatest(USER_REPORTS_CALL, userReportsCallGen);
    yield takeLatest(USER_REPORTS_MESSAGES_CALL, userReportsMessagesCallGen);
    yield takeLatest(USER_REPORT_RESOLVE, userReportResolveCallGen);
    yield takeLatest(MESSAGE_REPORT_RESOLVE, messageReportResolveCallGen);
}