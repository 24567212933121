import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, ButtonBlock, Popup, PopupContainer, PopupDescription, PopupName, StyledCloseSvg } from '../../../UI';
import { ReactComponent as FailSvg } from '../../../../assets/svg/fail.svg';

export default function PopupFail({ popupName, message, buttonName, buttonAction, handleClosePopup }) {
    return (
        <PopupContainer notSmooth>
            <Popup maxWidth="480px">
                {handleClosePopup && <StyledCloseSvg onClick={handleClosePopup}/>}
                <Row align="center">
                    <FailIcon/>
                    <PopupName>{popupName}</PopupName>
                </Row>
                <PopupDescription>{message}</PopupDescription>
                <ButtonBlockStyled>
                    {buttonAction && (
                        <button type="button" className="button blue filled" onClick={buttonAction}>
                            {buttonName}
                        </button>
                    )}
                </ButtonBlockStyled>
            </Popup>
        </PopupContainer>
    );
}

PopupFail.propTypes = {
    popupName: PropTypes.string,
    message: PropTypes.string,
    buttonName: PropTypes.string,
    buttonAction: PropTypes.func,
    handleClosePopup: PropTypes.func
};

PopupFail.defaultProps = {
    popupName: 'Failed',
    message: '',
    buttonName: 'Ok',
    buttonAction: undefined,
    handleClosePopup: undefined
};

const FailIcon = styled(FailSvg)`
    margin-right: 15px;
`;

const ButtonBlockStyled = styled(ButtonBlock)`
    button {
        width: 150px;
    }
`;