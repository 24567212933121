import React, { useLayoutEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { errorFirstSelector } from '../../../../store/error/selector';
import { removeError, toggleError } from '../../../../store/error/actions';
import PopupFail from '../fail';
import NetworkError from '../../../../helpers/network-error';


export default function ErrorPopup() {

    const dispatch = useDispatch();
    const errorData = useSelector(errorFirstSelector, isEqual);
    const [popupData, setPopupData] = useState({});

    useLayoutEffect(() => {
        if (errorData) {
            const processedError = new NetworkError(errorData);
            setPopupData(processedError);
        }
    }, [errorData]);

    function handleButtonAction() {
        dispatch(removeError(errorData.failedAction.type));

        if (errorData.failedAction) {
            if (typeof errorData.failedAction === 'function') {
                dispatch(errorData.failedAction());
            } else {
                dispatch(errorData.failedAction);
            }
        }

    }

    function handleErrorCancel() {

        if (popupData.isFatal) {
            dispatch(toggleError(errorData.failedAction.type));
        } else {
            dispatch(removeError(errorData.failedAction.type));
        }

        if (errorData.cancelAction) {
            if (typeof errorData.cancelAction === 'function') {
                dispatch(errorData.cancelAction());
            } else {
                dispatch(errorData.cancelAction);
            }
        }
    }

    return (
        errorData && errorData.isVisible && !popupData.isFatal && (
            <PopupFail
                popupName={popupData.name}
                message={popupData.message}
                buttonName={popupData.buttonName}
                buttonAction={popupData.buttonName === 'Retry' ? handleButtonAction : handleErrorCancel}
                handleClosePopup={handleErrorCancel}
            />
        )
    );
}