export const USAGE_CALL = 'USAGE_CALL';
export const USAGE_CALL_SUCCESS = 'USAGE_CALL_SUCCESS';
export const USAGE_CALL_FETCHED = 'USAGE_CALL_FETCHED';

export const STATISTICS_USERS_CALL = 'STATISTICS_USERS_CALL';
export const STATISTICS_USERS_CALL_SUCCESS = 'STATISTICS_USERS_CALL_SUCCESS';
export const TOGGLE_USER_STATISTICS_LOADING = 'TOGGLE_USER_STATISTICS_LOADING';

export const STATISTICS_MESSAGES_CALL = 'STATISTICS_MESSAGES_CALL';
export const STATISTICS_MESSAGES_CALL_SUCCESS = 'STATISTICS_MESSAGES_CALL_SUCCESS';
export const TOGGLE_MESSAGES_STATISTICS_LOADING = 'TOGGLE_MESSAGES_STATISTICS_LOADING';

export function usageCall() {
    return { type: USAGE_CALL };
}

export function usageCallSuccess(data) {
    return { type: USAGE_CALL_SUCCESS, payload: { data } };
}

export function usageFetched() {
    return { type: USAGE_CALL_FETCHED };
}

// Users

export function statisticsUsersCall(period, startDate, endDate) {
    return { type: STATISTICS_USERS_CALL, payload: { period, startDate, endDate } };
}

export function statisticsUsersCallSuccess(data, lastUpdateDate) {
    return { type: STATISTICS_USERS_CALL_SUCCESS, payload: { data, lastUpdateDate } };
}

export function statisticsUsersLoadingToggle(state) {
    return { type: TOGGLE_USER_STATISTICS_LOADING, payload: { state } };
}

// Messages

export function statisticsMessagesCall(period, startDate, endDate) {
    return { type: STATISTICS_MESSAGES_CALL, payload: { period, startDate, endDate } };
}

export function statisticsMessagesCallSuccess(data, lastUpdateDate) {
    return { type: STATISTICS_MESSAGES_CALL_SUCCESS, payload: { data, lastUpdateDate } };
}

export function statisticsMessagesLoadingToggle(state) {
    return { type: TOGGLE_MESSAGES_STATISTICS_LOADING, payload: { state } };
}