import { call, put, takeLatest } from 'redux-saga/effects';
import { setError } from '../error/actions';
import { rootApi } from '../../api';
import {
    REGIONS_CALL,
    REGIONS_CALL_SUCCESS,
    REGIONS_CALL_FAIL
} from './actions';
import { setRequestsLimit } from '../customer/actions';

function* regionsCallGen(action) {
    try {
        const request = yield call(rootApi.get, '/regions');
        const regions = [...request.data];

        yield put({ type: REGIONS_CALL_SUCCESS, payload: { regions } });
    } catch (error) {
        if (error.response && error.response.status === 429) {
            yield put(setRequestsLimit(error.response));
        }
        yield put(setError(error, action));
        yield put({ type: REGIONS_CALL_FAIL });
    }
}

export default function* regionsWatcherSaga() {
    yield takeLatest(REGIONS_CALL, regionsCallGen);
}