import { TOGGLE_EXPIRED_POPUP } from './actions';

const initialState = {
    isVisible: true
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };

    switch (type) {
        case TOGGLE_EXPIRED_POPUP:
            if (payload && payload.state !== undefined) {
                newState.isVisible = payload.state;
                return newState;
            }
            return state;

        default:
            return state;
    }
};