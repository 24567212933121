export const ROLES_CALL = 'ROLES_CALL';
export const ROLES_CALL_SUCCESS = 'ROLES_CALL_SUCCESS';
export const ROLES_CALL_FAIL = 'ROLES_CALL_FAIL';

export const ROLE_CREATE_CALL = 'ROLE_CREATE_CALL';
export const ROLE_DELETE_CALL = 'ROLE_DELETE_CALL';
export const ROLE_EDIT_CALL = 'ROLE_EDIT_CALL';

export const ROLE_ADD = 'ROLE_ADD';
export const ROLE_DELETE = 'ROLE_DELETE';
export const ROLE_EDIT = 'ROLE_EDIT';

export const ROLE_LOADING_OFF = 'ROLE_LOADING_OFF';


export function rolesCall() {
    return { type: ROLES_CALL };
}

export function roleCreateCall(role) {
    return { type: ROLE_CREATE_CALL, payload: { role } };
}

export function roleEditCall(roleName, role) {
    return { type: ROLE_EDIT_CALL, payload: { roleName, role } };
}

export function roleDeleteCall(roleName) {
    return { type: ROLE_DELETE_CALL, payload: { roleName } };
}


export function roleAdd(role) {
    return { type: ROLE_ADD, payload: { role } };
}

export function roleEdit(roleName, roleNewValues) {
    return { type: ROLE_EDIT, payload: { roleName, roleNewValues } };
}

export function roleDelete(roleName) {
    return { type: ROLE_DELETE, payload: { roleName } };
}

export function removeRolesLoading() {
    return { type: ROLE_LOADING_OFF };
}