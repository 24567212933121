export const paginationSelector = (store, paginationId) => store.paginationData[paginationId];

export const paginationDataSelector = store => {
    if (store.paginationData) {
        return store.paginationData;
    }
    return null;
};

// export const previousSelector = store => {
//     const pData = store.paginationData;
//     if (pData && pData.prevQueryParams) {
//         const { length } = pData.prevQueryParams;
//         if (length) {
//             return pData.prevQueryParams[length - 1];
//         }
//     }
//     return null;
// };
// export const totalCountSelector = (store, routeName) => {
//     return store.paginationData[routeName].total;
// };
//
// export const pageCountSelector = (store, routeName) => {
//     return store.paginationData[routeName].count;
// };
//
// export const lastPageSelector = (store, routeName) => {
//     return store.paginationData[routeName].lastPage;
// };
//
// export const currentPageSelector = (store, routeName) => {
//     return store.paginationData[routeName].currentPage;
// };