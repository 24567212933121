export const MARKERS_CALL = 'MARKERS_CALL';
export const MARKERS_CALL_SUCCESS = 'MARKERS_CALL_SUCCESS';
export const MARKERS_CALL_FAIL = 'MARKERS_CALL_FAIL';

export const MARKER_CREATE_CALL = 'MARKER_CREATE_CALL';
export const MARKER_DELETE_CALL = 'MARKER_DELETE_CALL';

export const MARKER_ADD = 'MARKER_ADD';
export const MARKER_DELETE = 'MARKER_DELETE';

export const MARKER_LOADING_OFF = 'MARKER_LOADING_OFF';


export function markersCall() {
    return { type: MARKERS_CALL };
}

export function markerCreateCall(marker) {
    return { type: MARKER_CREATE_CALL, payload: { marker } };
}

export function markerDeleteCall(markerName) {
    return { type: MARKER_DELETE_CALL, payload: { markerName } };
}

export function markerAdd(marker) {
    return { type: MARKER_ADD, payload: { marker } };
}

export function markerDelete(markerName) {
    return { type: MARKER_DELETE, payload: { markerName } };
}

export function removeMarkersLoading() {
    return { type: MARKER_LOADING_OFF };
}