export const CUSTOMER_CALL = 'CUSTOMER_CALL';
export const CUSTOMER_CALL_SUCCESS = 'CUSTOMER_CALL_SUCCESS';
export const CUSTOMER_CALL_FAIL = 'CUSTOMER_CALL_FAIL';

export const CUSTOMER_EDIT_CALL = 'CUSTOMER_EDIT_CALL';
export const CUSTOMER_EDIT = 'CUSTOMER_EDIT';

export const CUSTOMER_CHANGE_PASSWORD_CALL = 'CUSTOMER_CHANGE_PASSWORD_CALL';
export const CUSTOMER_CODE = 'CUSTOMER_CODE';

export const CUSTOMER_LOADING_OFF = 'CUSTOMER_LOADING_OFF';

export const CUSTOMER_DELETE = 'CUSTOMER_DELETE';

export const CUSTOMER_DELETE_SUCCESS = 'CUSTOMER_DELETE_SUCCESS';

export const CUSTOMER_ADD_SUBSCRIPTION = 'CUSTOMER_ADD_SUBSCRIPTION';
export const COMPANY_NOT_DEFINED = 'COMPANY_NOT_DEFINED';

export function customerCall() {
    return { type: CUSTOMER_CALL };
}

export function addCustomerSubscription(subscriptionId) {
    return { type: CUSTOMER_ADD_SUBSCRIPTION, payload: { subscriptionId } };
}

export function customerEditCall(customerNewValues) {
    return { type: CUSTOMER_EDIT_CALL, payload: { customerNewValues } };
}

export function customerChangePasswordCall(oldPassword, newPassword) {
    return { type: CUSTOMER_CHANGE_PASSWORD_CALL, payload: { oldPassword, newPassword } };
}

export function customerEdit(customerNewValues) {
    return { type: CUSTOMER_EDIT, payload: { customerNewValues } };
}

export function setCustomerCode(code) {
    return { type: CUSTOMER_CODE, payload: { code } };
}

export function customerDelete() {
    return { type: CUSTOMER_DELETE };
}

export function removeCustomerLoading() {
    return { type: CUSTOMER_LOADING_OFF };
}

/** Payment **/

export const CUSTOMER_PAYMENT_INFO_CALL = 'CUSTOMER_PAYMENT_INFO_CALL';
export const CUSTOMER_PAYMENT_INFO_RESPONSE = 'CUSTOMER_PAYMENT_INFO_RESPONSE';
export const CUSTOMER_PAYMENT_INVOICES_CALL = 'CUSTOMER_PAYMENT_INVOICES_CALL';
export const CUSTOMER_CANCEL_SUBSCRIPTION = 'CUSTOMER_CANCEL_SUBSCRIPTION';
export const CUSTOMER_PAYMENT_INVOICES_RESPONSE = 'CUSTOMER_PAYMENT_INVOICES_RESPONSE';
export const CUSTOMER_CHANGE_SUBSCRIPTION_CALL = 'CUSTOMER_CHANGE_SUBSCRIPTION_CALL';
export const CUSTOMER_CHANGE_TRIAL_SUBSCRIPTION_CALL = 'CUSTOMER_CHANGE_TRIAL_SUBSCRIPTION_CALL';
export const CUSTOMER_SUBSCRIPTION_HISTORY_CALL = 'CUSTOMER_SUBSCRIPTION_HISTORY_CALL';
export const CUSTOMER_SUBSCRIPTION_HISTORY_RESPONSE = 'CUSTOMER_SUBSCRIPTION_HISTORY_RESPONSE';
export const CUSTOMER_USAGES_IN_PERIOD_CALL = 'CUSTOMER_USAGES_IN_PERIOD_CALL';
export const CUSTOMER_USAGES_IN_PERIOD_SUCCESS = 'CUSTOMER_USAGES_IN_PERIOD_SUCCESS';
export const CUSTOMER_CHANGE_SUBSCRIPTION_RESPONSE = 'CUSTOMER_CHANGE_SUBSCRIPTION_RESPONSE';
export const SUBSCRIPTION_CANCELED = 'SUBSCRIPTION_CANCELED';
export const SET_REQUESTS_LIMIT = 'SET_REQUESTS_LIMIT';

export function customerPaymentInfoCall() {
    return { type: CUSTOMER_PAYMENT_INFO_CALL };
}

export function customerCancelSubscription() {
    return { type: CUSTOMER_CANCEL_SUBSCRIPTION };
}

export function customerPaymentInfoResponse(data) {
    return { type: CUSTOMER_PAYMENT_INFO_RESPONSE, payload: { data } };
}

export function customerPaymentInvoicesCall() {
    return { type: CUSTOMER_PAYMENT_INVOICES_CALL };
}

export function customerPaymentInvoicesResponse(data) {
    return { type: CUSTOMER_PAYMENT_INVOICES_RESPONSE, payload: { data } };
}

export function subscriptionCanceled(message) {
    return { type: SUBSCRIPTION_CANCELED, payload: { message } };
}

export function changeSubscriptionCall(updatePlanData) {
    return { type: CUSTOMER_CHANGE_SUBSCRIPTION_CALL, payload: { updatePlanData } };
}

export function changeSubscriptionResponse(data) {
    return { type: CUSTOMER_CHANGE_SUBSCRIPTION_RESPONSE, payload: { data } };
}

export function changeTrialSubscription(planId) {
    return { type: CUSTOMER_CHANGE_TRIAL_SUBSCRIPTION_CALL, payload: { planId } };
}

export function customerSubscriptionHistoryCall() {
    return { type: CUSTOMER_SUBSCRIPTION_HISTORY_CALL };
}

export function customerSubscriptionHistoryResponse(subscriptionHistory) {
    return { type: CUSTOMER_SUBSCRIPTION_HISTORY_RESPONSE, payload: { subscriptionHistory } };
}

export function customerUsagesForPeriodCall(currentPeriodId) {
    return { type: CUSTOMER_USAGES_IN_PERIOD_CALL, payload: { currentPeriodId } };
}

export function customerUsagesForPeriodSuccess(activeUsers, storageUsage) {
    return { type: CUSTOMER_USAGES_IN_PERIOD_SUCCESS, payload: { activeUsers, storageUsage } };
}

export function setRequestsLimit(requestsLimitRes) {
    const limitData = {
        timeout: Math.floor(requestsLimitRes)
    };
    if (requestsLimitRes.headers) {
        localStorage.setItem('requestsLimitTime', requestsLimitRes.headers['x-ratelimit-reset']);
        limitData.timeout = Math.floor((requestsLimitRes.headers['x-ratelimit-reset'] - Date.now() / 1000));
        limitData.rateLimit = requestsLimitRes.headers['x-ratelimit-limit'];
        limitData.rateLimitRemaining = requestsLimitRes.headers['x-ratelimit-remaining'];
    }
    return { type: SET_REQUESTS_LIMIT, payload: { limitData } };
}