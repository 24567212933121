import {
    ROLES_CALL,
    ROLES_CALL_SUCCESS,
    ROLES_CALL_FAIL,
    ROLE_CREATE_CALL,
    ROLE_DELETE_CALL,
    ROLE_EDIT_CALL,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_DELETE,
    ROLE_LOADING_OFF
} from './actions';

const initialState = {
    roles: [],
    loading: false,
    isFetched: false
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
    const newState = { ...state };
    const newRoles = [...newState.roles];

    switch (type) {

        case ROLES_CALL: {
            newState.roles = [];
            newState.isFetched = false;
            return newState;
        }

        case ROLES_CALL_SUCCESS: {
            if (payload.roles) {
                newState.roles = [...payload.roles];
                newState.isFetched = true;
                return newState;
            }
            return state;
        }

        case ROLES_CALL_FAIL: {
            newState.isFetched = true;
            return newState;
        }

        case ROLE_CREATE_CALL:
        case ROLE_EDIT_CALL:
        case ROLE_DELETE_CALL: {
            newState.loading = true;
            return newState;
        }

        case ROLE_LOADING_OFF: {
            newState.loading = false;
            return newState;
        }

        case ROLE_ADD: {
            if (payload.role) {
                newState.roles = [...newRoles, payload.role].sort((a, b) => b.weight - a.weight);
                newState.loading = false;
                return newState;
            }
            return state;
        }

        case ROLE_DELETE: {
            if (payload.roleName !== undefined) {
                newState.roles = newRoles.filter(role => role.name !== payload.roleName);
                newState.loading = false;
                return newState;
            }
            return state;
        }

        case ROLE_EDIT: {
            if (payload.roleName !== undefined && payload.roleNewValues) {
                newState.roles = newRoles.map(role => (role.name === payload.roleName ? { ...role, ...payload.roleNewValues } : role))
                    .sort((a, b) => b.weight - a.weight);
                newState.loading = false;
                return newState;
            }
            return state;
        }

        default:
            return state;
    }
};