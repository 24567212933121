export const USERS_CALL = 'USERS_CALL';
export const USERS_CALL_FILTER = 'USERS_CALL_FILTER';
export const USERS_CALL_SUCCESS = 'USERS_CALL_SUCCESS';
export const USERS_CALL_FAIL = 'USERS_CALL_FAIL';

export const USER_CREATE_CALL = 'USER_CREATE_CALL';
export const USER_DELETE_CALL = 'USER_DELETE_CALL';
export const USER_DEACTIVATE_CALL = 'USER_DEACTIVATE_CALL';
export const USER_ACTIVATE_CALL = 'USER_ACTIVATE_CALL';
export const USER_EDIT_CALL = 'USER_EDIT_CALL';
export const INVITE_MEMBER_CALL = 'INVITE_MEMBER_CALL';

// export const USER_ADD = 'USER_ADD';
// export const USER_DELETE = 'USER_DELETE';
export const USER_EDIT = 'USER_EDIT';

export const USER_LOADING_OFF = 'USER_LOADING_OFF';


export function usersCall(queryParams) {
    return { type: USERS_CALL, payload: { queryParams } };
}

export function usersCallFilter(queryParams) {
    return { type: USERS_CALL_FILTER, payload: { queryParams } };
}

export function userCreateCall(user, avatarFile) {
    return { type: USER_CREATE_CALL, payload: { user, avatarFile } };
}

export function userEditCall(id, user, avatarFile) {
    return { type: USER_EDIT_CALL, payload: { id, user, avatarFile } };
}

export function userDeleteCall(id) {
    return { type: USER_DELETE_CALL, payload: { id } };
}

export function userDeactivateCall(id) {
    return { type: USER_DEACTIVATE_CALL, payload: { id } };
}

export function userActivateCall(id) {
    return { type: USER_ACTIVATE_CALL, payload: { id } };
}

export function userEdit(id, userNewValues) {
    return { type: USER_EDIT, payload: { id, userNewValues } };
}

export function removeUsersLoading() {
    return { type: USER_LOADING_OFF };
}