export const GET_API_KEYS = 'GET_API_KEYS';
export const SET_API_KEYS = 'SET_API_KEYS';

export const CREATE_API_KEY = 'CREATE_API_KEY';

export const DELETE_API_KEY = 'DELETE_API_KEY';
export const UPDATE_API_KEY = 'UPDATE_API_KEY';
export const SET_AUTH_KEY = 'SET_AUTH_KEY';
export const DELETE_AUTH_KEY = 'DELETE_AUTH_KEY';

export const API_KEY_ACTION_LOADING_ON = 'API_KEY_ACTION_LOADING_ON';
export const API_KEY_ACTION_LOADING_OFF = 'API_KEY_ACTION_LOADING_OFF';

export function getApiKeys(isSecondCall) {
    return { type: GET_API_KEYS, payload: { isSecondCall } };
}

export function setApiKeys(apiKeys) {
    return { type: SET_API_KEYS, payload: { apiKeys } };
}

export function deleteApiKey(prefix) {
    return { type: DELETE_API_KEY, payload: { prefix } };
}

export function updateApiKey(apiKeyData) {
    return { type: UPDATE_API_KEY, payload: { apiKeyData } };
}

export function createApiKey(name, ip) {
    return { type: CREATE_API_KEY, payload: { name, ip } };
}

export function apiKeyActionLoading(status) {
    return { type: status ? API_KEY_ACTION_LOADING_ON : API_KEY_ACTION_LOADING_OFF };
}

export function setAuthKey(authKey) {
    return { type: SET_AUTH_KEY, payload: { authKey } };
}

export function deleteAuthKey() {
    return { type: DELETE_AUTH_KEY };
}