
function addNewLines(str) {
    let newStr = str;
    let finalString = '\n';
    while (newStr.length > 0) {
        finalString += `${newStr.substring(0, 64)}\n`;
        newStr = newStr.substring(64);
    }

    return finalString;
}

async function exportCryptoKey(key, keyType) {
    const exported = await window.crypto.subtle.exportKey(
        keyType === 'private' ? 'pkcs8' : 'spki',
        key
    );
    const exportedAsString = String.fromCharCode.apply(null, new Uint8Array(exported));
    const exportedAsBase64 = window.btoa(exportedAsString);
    const formatKey = addNewLines(exportedAsBase64);

    if (keyType === 'private') {
        return `-----BEGIN PRIVATE KEY-----${formatKey}-----END PRIVATE KEY-----`;
    }
    return `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`;
}

export function downloadFile(data, filename, type) {
    const file = new Blob([data], { type });
    if (window.navigator.msSaveOrOpenBlob) {
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    } else {
        // Others
        const a = document.createElement('a');
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
}

export function generateRSAKeyPair() {

    return window.crypto.subtle.generateKey(
        {
            name: 'RSA-OAEP',
            modulusLength: 2048, // can be 1024, 2048 or 4096
            publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
            hash: { name: 'SHA-256' } // or SHA-512
        },
        true,
        ['encrypt', 'decrypt']
    ).then(async keyPair => {

        const privateKey = await exportCryptoKey(keyPair.privateKey, 'private');
        // .then(privateKey => {
        //     // console.log(privateKey);
        //     return privateKey;
        // });

        const publicKey = await exportCryptoKey(keyPair.publicKey, 'public');
        // .then(publicKey => {
        //     // console.log(key);
        //     return publicKey
        // });

        return {
            privateKey,
            publicKey
        };
    });
}